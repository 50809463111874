body 
  .MuiInputBase-root
    font-size: 16px;
  .MuiTextField-root:not(.refari-textarea-no-rows)
    .MuiInputBase-inputMultiline
      height: auto;

*
  outline none !important

.app
  min-height 100vh
  display flex
  flex-direction column
  .header + div
    flex 1 0 auto
    display flex
    justify-content flex-start

.refari-update-buttons
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  button
    margin-left: 15px

.social-btn-wrap
  display flex
  justify-content space-between
  margin-bottom 25px
  .social-btn
    flex 0 0 28%

.hide
  display none

.back-btn
  margin-bottom -4px
  margin-right 5px

.btn-wrap
  margin-bottom 15px

.modal-back-btn
  margin-top 10px

.or
  margin 15px 0
  text-align center
  position relative
  .before, .after
    position absolute
    top 50%
    width 43%
    height 2px
  .before
    left 0
  .after
    right 0

.vertical-divider
  position absolute
  left 50%
  width 2px
  top 0
  bottom 0
  background-color #e0e0e0

.long-text
  white-space pre-wrap
  word-break break-word
  font-family inherit

.modal
  h3
    font-weight 700 !important
    padding 50px 0 15px 0
  h4
    padding-bottom 40px
  h3, h4
    max-width 500px
    margin 0 auto
    text-align center
    padding-left 10px
    padding-right 10px
  .report-modal-content
    max-width 400px
    width 100%
    margin 0 auto

.modal-content
  position relative
  .modal-close-btn
    position absolute
    top -24px
    right -24px
    padding 10px

.modal-content-edm
  padding 0 24px
  &--step
    margin-bottom 0
    font-style: normal
    font-weight: 500
    font-size: 18px
    line-height: 36px
    color: #757575
  &--icon
    width 90px
    height 90px
    margin-right: 30px
    margin-bottom: 30px
    background-size cover
    cursor pointer
  &__icon-list
    display flex
    flex-wrap wrap
    padding-bottom 30px
    padding-top 30px
    justify-content space-between
  &__apply
    z-index: 1
    position: fixed
    bottom: 0
    width: 100%
    right: 0
    background: #fff
    padding: 14px

.modal-footer
  text-align center
  margin 0 auto
  padding-bottom 40px

.flex
  display flex

.text-center
    text-align center

.text-right
  text-align right

.copied-wrap
  position relative
  .copied-msg
    position absolute
    right -60px
    top 15px

.s-alert-box
  z-index 10000 !important

.header-filter
  flex 1 0 0
  margin 0 0 0 15px

.full-height
  height 100%

.full-width
  min-width 100%

.warn-block
  position relative
  padding 22px 50px 22px 20px
  background #fdf4f4
  margin-bottom 10px
  .modal-close-btn
    position absolute
    top 10px
    right 10px
  h6
    margin-left 15px
    color #626262

.table-row-col-item
  display block
  overflow hidden
  padding 15px 12px 15px 24px;
  text-overflow ellipsis
  &.poiner
    cursor pointer

.table-row-col-action
  padding 0 24px

.flat
  margin-top 50px

.loader
  position relative
  margin-bottom 15px
.loader-wrap
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  z-index 2000
  cursor wait

.transparent
  opacity 0

.list-loader
  padding 10px 0 5px


.page-preload-spinner
  display flex
  flex-direction column
  padding 30px 0

.hidden-xs
  @media screen and (max-width 768px)
    display none !important

.visible-xs
  @media screen and (min-width 768px)
    display none !important

.start-md
  @media only screen and (min-width: 64em)
    text-align left


[class^="IN-"],
.IN-shadow-enabled,
.IN-noshadow
  position fixed
  left -100000px
  top -100000px

.tel-input
  .refari-tel-input-section
    display: flex
    .col-xs-9
      width 72%
    .col-xs-3
      width 25%
      margin-right 3%
    .react-phone-number-input
      width: 100%
      position: relative
      input, textarea
        padding-right: 35px !important;
      .react-phone-number-input-right-block
        position: absolute;
        right: 0px;
        top: 21px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        width: 30px;
        &:before
          position: absolute;
          content: "";
          height: 23px;
          width: 1px;
          background-color: rgba(0, 191, 97, 0.25);
          left: 0px;
          top: 0px;
          bottom: 0px;
          transform-origin: center center;
          margin: auto 0px;
          animation: 0.2s ease 0s 1 normal none running eqFady;
        .react-phone-number-input-right-icon
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 6px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: 8s ease 0s 1 normal forwards running ffGLem;
      .react-phone-number-input-right-block-error
        &:before
          background-color: rgba(255, 0, 0, 0.25);
      .PhoneInputInput
        border none
        border-bottom 1px solid rgba(0, 0, 0, 0.8)
        border-radius none
        background transparent
        box-shadow unset
        padding: 8px 0
      &__input
        border none
        border-bottom 1px solid rgba(0, 0, 0, 0.8)
        border-radius none
        background transparent
        box-shadow unset
      &__icon
        width 1.5em
        height 1.1em
        &--international
          width 1.5em
      &__icon-image
        max-height unset
        vertical-align: unset
      .PhoneInputCountryIcon--border
        background transparent
        box-shadow unset
        width 0
      .PhoneInputCountrySelectArrow
        margin-bottom 8px
        display: block;
        content: '';
        width: var(--PhoneInputCountrySelectArrow-width);
        height: var(--PhoneInputCountrySelectArrow-width);
        margin-top: var(--PhoneInputCountrySelectArrow-marginTop);
        margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
        border-style: solid;
        border-color: var(--PhoneInputCountrySelectArrow-color);
        border-top-width: 0;
        border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
        border-left-width: 0;
        border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
        transform: var(--PhoneInputCountrySelectArrow-transform);
        opacity: var(--PhoneInputCountrySelectArrow-opacity);
      .PhoneInputCountry
        width: 65px
        border-bottom: 1px solid
        margin: 0
        padding-left 2px
        color #000
        .flag-image
          max-width 24px
          max-height 16px
          margin-left 45px
          transform: translate(0px, -1px)
        .tel-code-wrap
          display flex
          align-items: center
          justify-content center
          transform: translate(22px, -3px)
        .country-code
          width inherit
          margin: 0 0 0 30px
          transform: translate(0px, -1px)
      &__country-select
        display none
      &__country-select-arrow
        display none
  .error-message, .refari-error-text
    font-size 14px
    padding-top 5px
    color red

.custom-text-field
  textarea
    margin-bottom 0 !important
    margin-top 0 !important
    color #757575 !important
  hr
    display none !important

.refari-phone-code-wrapper
  .modal-header
    border-bottom: none !important
  .modal-title 
    padding-top: 30px;
  .refari-phone-code-banner
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    font-size: 13px;
    svg
      margin-left: 3px
      cursor: pointer
  .refari-phone-code-subheading
    text-align: center
    color: #9EA4AB
    font-size: 14px
    margin-bottom: 5px;
  .refari-phone-code-qrcode
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 216px;
    height: 216px;
    background: 
      linear-gradient(to right, #000 4px, transparent 4px) 0 0,
      linear-gradient(to right, #000 4px, transparent 4px) 0 100%,
      linear-gradient(to left, #000 4px, transparent 4px) 100% 0,
      linear-gradient(to left, #000 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, #000 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, #000 4px, transparent 4px) 100% 0,
      linear-gradient(to top, #000 4px, transparent 4px) 0 100%,
      linear-gradient(to top, #000 4px, transparent 4px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 40px 40px;
  .refari-phone-code-input
    width: fit-content !important;
    margin: auto;
    input
      border-right: none !important;
      border-left: none !important;
      border-top: none !important;
      border-radius: 0 !important;
      margin-right: 5px;
      margin-left: 5px;
  .refari-phone-code-subheading-bottom
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
    &-text
      color: #9ea4ab;
      font-size: 13px;
      margin-right: 5px;
    &-link
      font-size: 13px;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;

.refari-modal
  h3
    font-weight 700 !important
    padding 50px 0 15px 0
  h4
    padding-bottom 40px
  h3, h4
    max-width 500px
    margin 0 auto
    text-align center

.refari-modal-content
  position relative
  .refari-long-text
    padding-top 30px !important
  &__linkedin-apply-form
    display flex
    flex-direction column
    align-items center
    max-width 560px
    width 100%
    margin 0 auto
  &__linkedin-apply-form-content
    width 100%
  .refari-modal-close-btn
    position absolute
    top -24px
    right -24px
    padding 10px
    z-index 50
  &__social-network
    width 100%
    height 48px
    display flex
    justify-content center
    align-items center
  &__testimonial-image
    height 300px
  &--testimonial-image
    width 100%
    height 100%
  &__center
    display flex
    align-items center
    justify-content center
  &--social-network
    margin 0
    padding 0
    text-transform uppercase
    color: #fff
    font-size 14px
    font-weight bold
  &__main
    .refari-modal-close-btn
      top 0
      svg
        color #fff !important
  &__header-section
    background-color $blue
    margin -24px -24px 0 -24px
    padding 24px
    color #fff
  &__social-tabs
    box-shadow: 0 0 3px #757575;
  &--apply-content
    text-align center
  &--apply-button
    display flex
    align-items center
    justify-content center
  &--header-content
    text-align: center
    font-size: 16px
    font-weight 600
  &--white
    color #fff !important
  &--header-createJobAlert
    max-width: 460px !important
    margin: 24px auto 0 !important
    text-align: center !important
    padding unset !important
  &--content
    padding-bottom: 10px !important
    font-size: 14px
  &__refer-popup
    display flex
    align-items center
    justify-content center
    margin-bottom 30px
    margin-left -30px
  &__content-wraper
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width auto
    margin: 0 -24px 0 -24px;
    padding 24px
    color: #fff
    .refari-modal-content--header-content
      max-width 330px
      margin 0
      padding 0
      text-align center
      font-size 14px
    .refari-modal-content--header
      font-size 16px
      font-weight: 500
    .refari-modal-content--content
      padding 0 !important
  &__body
    width: auto
    margin 0 -24px
    .refari-social-btn-wrap
      max-width 440px
      margin: 0 auto
      padding 0 15px
  &--social-content
    margin 35px 0 30px
    color: #757575
    text-align: center
    font-size: 16px
    line-height: 24px
  .refari-icon-wrap-section
    width 19px !important
    height 19px !important
    background-image: none
    svg
      width 11px !important
      height 11px !important
  &__footer
    width: auto
    margin 24px -24px -24px
    padding 30px 24px
    .refari-icon-wrap-section
      width 29px !important
      height 28px !important
      background-image: none
      svg
        width 14px !important
        height 21px !important
        margin-top  -5px !important
  &--how-it-works
    margin-bottom 20px
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;

  &__icon-section
    display flex
    justify-content space-around
  &__footer-content
    position relative
    display flex
    align-items center
    flex-direction column
    &:nth-child(1):after
      content ''
      position absolute
      top: 14px
      left: 120px
      width 100%
      border-bottom 1px dashed #fff
    &:nth-child(2)
      .refari-icon-wrap-section
        background-image: none
        svg
          margin-left 3px !important
      &:after
        content ''
        position absolute
        top: 14px
        left: 120px
        width 100%
        border-bottom 1px dashed #fff
    &:nth-child(3)
      .refari-icon-wrap-section
        background-image: none
        svg
          margin-left 3px !important

.refari-hidden
  display: none !important;
.refari-w-row
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: initial;
  margin: 0;
.refari-justify-start
  display: flex;
  justify-content: flex-start;
.refari-justify-center
  display: flex;
  justify-content: center;
.refari-justify-end
  display: flex;
  justify-content: flex-end;
.refari-align-items-center
  display: flex;
  align-items: center;
.refari-flex
  display: flex
.refari-copied-wrap
  position relative
  .refari-copied-msg
    position absolute
    right -60px
    top 10px
  .refari-copied-click-msg
    cursor: pointer;
  .refari-copied-click-box
    display: flex;
    align-items: center;
  @media screen and (max-width 625px)
    .refari-copied-msg
      display: none

.refari-additional-info
  display none
  &__show
    display: flex
    flex-wrap: wrap
    margin: 10px 25px 0
    border-left: 1px solid #bdbdbd
    padding: 0px 10px 0
  &--item
    margin 0

.MuiListItemIcon-root
  min-width: auto !important

.refari-slider__wrapper
  margin-bottom 15px
  .refari-slider
    padding: 5px 0;
    > div > div > div:first-child
      background-color rgb(189, 189, 189) !important

  .refari-slider__line-indicator
    display flex
    line-height: 10px;
    span 
      position relative
      flex 1
      font-size 11px

    .refari-line-indicator--min
      text-align left
    .refari-line-indicator--zero
      text-align center
    .refari-line-indicator--max
      text-align right

/* Editor */
.container__editor
  font-size: 14px;
  font-variant-ligatures: common-ligatures;
  background-color: #ffffff;
  border: 1px solid rgba(0,0,0,.42) !important;
  border-radius: 3px;
  min-height: 100px;
  textarea
    outline: 0;
  .button
    display: inline-block;
    padding: 0 6px;
    text-decoration: none;
    background: #000;
    color: #fff;
  .button:hover
    background: linear-gradient(45deg, #E42B66, #E2433F);

  /* Syntax highlighting */
  .token.tag
    color: #d65900;

.m_top_1x
  margin-top 12px !important
.m_top_2x
  margin-top 24px !important
.m_top_3x
  margin-top 36px !important
.m_bottom_1x
  margin-bottom 12px !important
.m_bottom_2x
  margin-bottom 24px !important
.m_bottom_3x
  margin-bottom 36px !important
.pad_top_3x
  padding-top 36px
.pad_bottom_3x
  padding-bottom 36px

@media screen and (max-width 600px)
  .modal
    width 100% !important


.refari-refer-modal-wrap
  .refari-or
    margin 40px 0
  .refari-modal-body
    max-width 440px
    margin 0 auto
    padding-bottom 25px
  .refari-create-account
    margin 10px auto
  .refari-left-inline-field
    margin-right 15px

.let-us-help-you-modal
  display flex
  align-items center
  flex-direction column
  &--header
    line-height: 21px
    letter-spacing: 0.642857px
    color: $silver
    font-style: normal
    font-weight: 500
    font-size: 18px
    margin: 0;
  &--optional
    margin-left 5px
    color lighten(#757575, 20)
    font-weight 300
    font-size: 16px
  &__form-item
    width 100%
  &--content
    max-width 460px
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    text-align: center
    color: $silver

.refari-w-modal-container
  font-family inherit
  background-color: transparent !important
  .MuiDialog-paper
    padding: 24px
    color: rgba(60, 72, 86, 0.6)
    font-size: 16px
    max-width: 768px
    background-color: #fff !important
  &-small
    .MuiDialog-paper
      max-width: 550px
  button
    text-transform: none
  input,
  button,
  select,
  optgroup,
  textarea
    margin 0
    font-family inherit
    font-size inherit
    line-height inherit
  label
    display: inline-block;
    margin-bottom: 0.5rem;

.refari-long-text
  white-space pre-wrap
  word-break break-word
  font-family inherit

.cursor-text
  cursor text

.modal-override
  h3, h4
    max-width: 520px

@keyframes bullet-bounce {
  from {
    top:0px;
  }
  50% {
    top: -10px;
  }
  to {
    top:0px;
  }
}

.bullet-animation-container
  padding:1.25rem;
  padding-top: 0;
  padding-bottom: 0;
  position:relative;
  .bullet
    padding:4px;
    background-color:#25B4D5;
    display: inline-block;
    border-radius:50%;
    position:absolute;
    top:0px;
  .bullet[data-delay="100"]
    left:0px;
    animation: bullet-bounce 1.5s cubic-bezier(.44,.61,0,1.03) infinite;
  .bullet[data-delay="200"]
    left: 10px;
    animation: bullet-bounce 1.5s cubic-bezier(.44,.61,0,1.03) 0.125s infinite;
  .bullet[data-delay="300"]
    left: 20px;
    animation: bullet-bounce 1.5s cubic-bezier(.44,.61,0,1.03) 0.25s infinite;
  .bullet[data-delay="400"]
    left: 30px;
    animation: bullet-bounce 1.5s cubic-bezier(.44,.61,0,1.03) 0.5s infinite;
