h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
}
h1 {
  font-size: 45px;
  line-height: 48px;
}
h2 {
  font-size: 36px;
  line-height: 54px;
}
h3 {
  font-size: 24px;
  line-height: 36px;
}
h4 {
  font-size: 18px;
  line-height: 27px;
}
h5 {
  font-size: 16px;
  line-height: 24px;
}
h6 {
  font-size: 14px;
  line-height: 21px;
}
.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.full-container {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.container-fluid {
  max-width: 100%;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.center {
  display: flex;
  justify-content: center;
}
.space-around {
  display: flex;
  justify-content: space-around;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.align-items-center,
.backgrounds-group-container .backgrounds-list li,
.color-picker-wrap {
  display: flex;
  align-items: center;
}
.align-items-flex-start {
  display: flex;
  align-items: flex-start;
}
.align-items-flex-end {
  display: flex;
  align-items: flex-end;
}
.align-items-baseline {
  display: flex;
  align-items: baseline;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.align-block-right {
  margin-left: auto !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.position-absolute-center {
  position: fixed;
  top: 33%;
  left: 50%;
}
.position-absolute-center-top {
  position: absolute;
  top: 22%;
  left: 50%;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.flex-center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.m-r-1 {
  margin-right: 14px !important;
}
.m-r-2 {
  margin-right: 24px !important;
}
.m-r-2 {
  margin-right: 34px !important;
}
.m-l-1 {
  margin-left: 14px !important;
}
.m-l-2 {
  margin-left: 24px !important;
}
.m-l-2 {
  margin-left: 34px !important;
}
.m-t-1 {
  margin-top: 14px !important;
}
.m-t-2 {
  margin-top: 24px !important;
}
.m-t-3 {
  margin-top: 34px !important;
}
.m-b-1 {
  margin-bottom: 14px !important;
}
.m-b-2 {
  margin-bottom: 24px !important;
}
.m-b-3 {
  margin-bottom: 34px !important;
}
.m-b-4x {
  margin-bottom: 40px !important;
}
.fl-1-3 {
  flex: 1 0 33.333333%;
  max-width: 100%;
}
@supports (flex-basis: 33.333333%) {
  .fl-1-3 {
    flex-basis: 33.333333%;
    max-width: 100%;
  }
}
.fl-2-3 {
  flex: 1 0 66.666667%;
  max-width: 100%;
}
@supports (flex-basis: 33.333333%) {
  .fl-2-3 {
    flex-basis: 66.666667%;
    max-width: 100%;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: "Roboto", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  color: #2b2b2b;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
::-ms-clear,
::-ms-reveal {
  width: 0;
  height: 0;
}
.inline-input-group .inline-input-text {
  margin-bottom: 0;
  font-size: 12px;
  color: #9e9e9e;
}
.field-counter {
  margin: 0;
  color: #9e9e9e;
  font-size: 14px;
}
.field-counter-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.space-null {
  margin: 0;
  padding: 0;
}
.field-counter-error {
  color: #f00;
}
.m_top_2x {
  margin-top: 24px;
}
.m_top_3x {
  margin-top: 36px;
}
.react-phone-number-input__country-select {
  display: none !important;
}
.react-phone-number-input__country-select-arrow {
  display: none !important;
}
.refari-video-wrapper {
  position: relative;
  padding-bottom: 53.25% /* 16:9 */;
  padding-top: 25px;
  height: 0;
}
.consultant-add-button-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.consultant-add-button-heading h3 {
  margin-right: 10px;
}
.refari-video-wrapper .refari-video-dimmer,
.refari-video-wrapper iframe,
.refari-video-wrapper object,
.refari-video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.refari-video-wrapper .refari-video-dimmer {
  z-index: 2;
  background-position: center;
  background-size: cover;
}
.refari-video-wrapper .refari-play-button {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.react-tagsinput {
  max-width: 100%;
}
.react-tagsinput > span {
  flex-wrap: wrap;
  display: flex;
  align-items: baseline;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap {
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 100%;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap .refari-react-tagsinput-tag {
  max-width: 100%;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap .refari-react-tagsinput-tag > span {
  text-overflow: ellipsis;
  overflow: hidden;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap .refari-react-tagsinput-tag > svg {
  flex: 1 0 24px;
}
.react-tagsinput .refari-react-tagsinput-input {
  flex: 1 0 0;
  min-width: 200px;
}
.react-tagsinput .refari-react-tags-select-input {
  position: relative;
  flex: 1 0 0;
  min-width: 100%;
  width: 100%;
}
.react-tagsinput .refari-react-tags-select-input__modal {
  position: absolute;
  left: 0;
  top: 45px;
  width: 100%;
  height: auto;
  max-height: 130px;
  overflow-y: scroll !important;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.react-tagsinput .refari-react-tags-select-input__modal .refari-filter-item {
  padding: 8px 56px 8px 50px !important;
}
.react-tagsinput .refari-react-tags-select-input__modal .refari-nested {
  padding-left: 60px !important;
}
.react-tagsinput .refari-react-tags-select-input--empty-modal {
  padding: 10px;
  text-align: 10px;
  font-size: 14px;
}
.job-alert-modal label {
  display: unset;
  margin-bottom: 0.5rem;
  color: #2b2b2b !important;
}
.job-alert-modal--save-button {
  margin-top: 45px;
  margin-bottom: 30px;
}
.left-space {
  padding-left: 222px !important;
}
.modal .MuiDialog-paper {
  padding: 24px;
  color: rgba(60,72,86,0.6);
  font-size: 16px;
  max-width: 900px;
}
.modal-small .MuiDialog-paper {
  max-width: 550px;
}
.MuiDialog-paper {
  overflow-x: hidden;
}
.leaderboard-badges-icon {
  display: flex !important;
  flex-wrap: wrap;
  max-width: 180px;
}
.app .MuiSelect-select:focus {
  background-color: transparent;
}
.app .MuiChip-deleteIconColorPrimary {
  color: #fff;
}
.app .MuiChip-deleteIconColorPrimary:hover,
.app .MuiChip-deleteIconColorPrimary:active {
  color: rgba(255,255,255,0.5);
}
.app .MuiPaper-rounded {
  border-radius: 2px;
}
.app .MuiPaper-elevation1 {
  box-shadow: rgba(0,0,0,0.12) 0px 1px 6px, rgba(0,0,0,0.12) 0px 1px 4px;
}
.app .MuiTableCell-head {
  padding: 0 24px;
  height: 56px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
}
.app .MuiTableCell-root {
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.app .MuiTable-root {
  table-layout: fixed;
}
.MuiButton-containedPrimary:focus,
.MuiButton-containedPrimary:hover {
  color: #fff;
}
.widgets-wrapper-main .MuiButton-contained {
  background-color: #fff;
}
.refari-bottom-screen-ratings {
  box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.2);
  left: 0;
  width: 100%;
  bottom: 0;
  font-size: 14px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: #fff;
}
.refari-bottom-screen-ratings-stars {
  padding: 3px 10px 0;
}
.refari-bottom-screen-ratings-stars .widget-ratings {
  padding-top: 1px;
}
.refari-bottom-screen-ratings-verified {
  display: flex;
  align-items: center;
  justify-content: center;
}
.refari-bottom-screen-ratings-verified a {
  padding-left: 8px;
}
.MuiFormControl-root.MuiFormControl-fullWidth {
  margin-bottom: 5px;
  margin-top: 15px;
}
.Toastify__toast--success {
  background-color: #27ae60 !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  color: #b16454;
}
.blur-mask-rating {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0px;
  background-color: rgba(56,134,209,0.2);
  backdrop-filter: blur(8px);
}
.blur-mask-modal-container,
.blur-mask-modal-container-overlay {
  margin-left: 224px !important;
  width: calc(100% - 224px) !important;
}
.modal-container-without-left,
.modal-container-without-left-overlay {
  margin-left: 224px !important;
  width: calc(100% - 224px) !important;
}
.black-color {
  color: #2b2b2b;
}
@media screen and (max-width: 991px) {
  .left-space {
    padding-left: 0 !important;
  }
  .blur-mask-modal-container,
  .blur-mask-modal-container-overlay {
    margin-left: auto !important;
    width: 100% !important;
    margin-top: 105px !important;
    height: calc(100% - 105px) !important;
  }
  .modal-container-without-left,
  .modal-container-without-left-overlay {
    margin-left: auto !important;
    width: 100% !important;
  }
}
.ml-0 {
  margin-left: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.line-height-1 {
  line-height: 1;
}
.text-transform-none {
  text-transform: none !important;
}
.display-inline-flex {
  display: inline-flex !important;
}
.iframe-dialog {
  align-items: start !important;
  margin-top: min(200px, 40vh);
}
.iframe-dialog-center {
  align-items: center !important;
}
.iframe-dialog-end {
  align-items: end !important;
  margin-top: max(-200px, -40vh);
}
.MuiMenu-paper {
  max-height: min(800px, calc(100% - 96px)) !important;
}
.refari-async-select__single-value,
.refari-async-select__placeholder {
  position: static;
  transform: none;
  max-width: none;
}
.consultant-add-multiselect,
.MuiMenu-paper {
  max-height: 370px !important;
}
.checked-multiselect,
.MuiMenu-paper {
  max-height: 370px !important;
}
.table-sticky-column::after {
  content: "";
  border-right: 1px solid rgba(0,0,0,0.25);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
tr:hover .table-sticky-column {
  background-color: #f0f0f0 !important;
  opacity: 1;
}
.refari-filter-item-container:hover .bounce {
  animation: bounce 1.5s ease infinite;
}
.loading_dots::after {
  content: '.';
  animation: dots 1.5s infinite steps(1);
}
.d-flex {
  display: flex !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-space-between {
  justify-content: space-between !important;
}
@-moz-keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
@-o-keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '...';
  }
}
@-webkit-keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '...';
  }
}
@-o-keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '...';
  }
}
@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '...';
  }
}
body .MuiInputBase-root {
  font-size: 16px;
}
body .MuiTextField-root:not(.refari-textarea-no-rows) .MuiInputBase-inputMultiline {
  height: auto;
}
* {
  outline: none !important;
}
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.app .header + div {
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-start;
}
.refari-update-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.refari-update-buttons button {
  margin-left: 15px;
}
.social-btn-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.social-btn-wrap .social-btn {
  flex: 0 0 28%;
}
.hide {
  display: none;
}
.back-btn {
  margin-bottom: -4px;
  margin-right: 5px;
}
.btn-wrap {
  margin-bottom: 15px;
}
.modal-back-btn {
  margin-top: 10px;
}
.or {
  margin: 15px 0;
  text-align: center;
  position: relative;
}
.or .before,
.or .after {
  position: absolute;
  top: 50%;
  width: 43%;
  height: 2px;
}
.or .before {
  left: 0;
}
.or .after {
  right: 0;
}
.vertical-divider {
  position: absolute;
  left: 50%;
  width: 2px;
  top: 0;
  bottom: 0;
  background-color: #e0e0e0;
}
.long-text {
  white-space: pre-wrap;
  word-break: break-word;
  font-family: inherit;
}
.modal h3 {
  font-weight: 700 !important;
  padding: 50px 0 15px 0;
}
.modal h4 {
  padding-bottom: 40px;
}
.modal h3,
.modal h4 {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.modal .report-modal-content {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
.modal-content {
  position: relative;
}
.modal-content .modal-close-btn {
  position: absolute;
  top: -24px;
  right: -24px;
  padding: 10px;
}
.modal-content-edm {
  padding: 0 24px;
}
.modal-content-edm--step {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #757575;
}
.modal-content-edm--icon {
  width: 90px;
  height: 90px;
  margin-right: 30px;
  margin-bottom: 30px;
  background-size: cover;
  cursor: pointer;
}
.modal-content-edm__icon-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  padding-top: 30px;
  justify-content: space-between;
}
.modal-content-edm__apply {
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  background: #fff;
  padding: 14px;
}
.modal-footer {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 40px;
}
.flex {
  display: flex;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.copied-wrap {
  position: relative;
}
.copied-wrap .copied-msg {
  position: absolute;
  right: -60px;
  top: 15px;
}
.s-alert-box {
  z-index: 10000 !important;
}
.header-filter {
  flex: 1 0 0;
  margin: 0 0 0 15px;
}
.full-height {
  height: 100%;
}
.full-width {
  min-width: 100%;
}
.warn-block {
  position: relative;
  padding: 22px 50px 22px 20px;
  background: #fdf4f4;
  margin-bottom: 10px;
}
.warn-block .modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.warn-block h6 {
  margin-left: 15px;
  color: #626262;
}
.table-row-col-item {
  display: block;
  overflow: hidden;
  padding: 15px 12px 15px 24px;
  text-overflow: ellipsis;
}
.table-row-col-item.poiner {
  cursor: pointer;
}
.table-row-col-action {
  padding: 0 24px;
}
.flat {
  margin-top: 50px;
}
.loader {
  position: relative;
  margin-bottom: 15px;
}
.loader-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  cursor: wait;
}
.transparent {
  opacity: 0;
}
.list-loader {
  padding: 10px 0 5px;
}
.page-preload-spinner {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}
@media screen and (max-width: 768px) {
  .hidden-xs {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .visible-xs {
    display: none !important;
  }
}
@media only screen and (min-width: 64em) {
  .start-md {
    text-align: left;
  }
}
[class^="IN-"],
.IN-shadow-enabled,
.IN-noshadow {
  position: fixed;
  left: -100000px;
  top: -100000px;
}
.tel-input .refari-tel-input-section {
  display: flex;
}
.tel-input .refari-tel-input-section .col-xs-9 {
  width: 72%;
}
.tel-input .refari-tel-input-section .col-xs-3 {
  width: 25%;
  margin-right: 3%;
}
.tel-input .refari-tel-input-section .react-phone-number-input {
  width: 100%;
  position: relative;
}
.tel-input .refari-tel-input-section .react-phone-number-input input,
.tel-input .refari-tel-input-section .react-phone-number-input textarea {
  padding-right: 35px !important;
}
.tel-input .refari-tel-input-section .react-phone-number-input .react-phone-number-input-right-block {
  position: absolute;
  right: 0px;
  top: 21px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
}
.tel-input .refari-tel-input-section .react-phone-number-input .react-phone-number-input-right-block:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 1px;
  background-color: rgba(0,191,97,0.25);
  left: 0px;
  top: 0px;
  bottom: 0px;
  transform-origin: center center;
  margin: auto 0px;
  animation: 0.2s ease 0s 1 normal none running eqFady;
}
.tel-input .refari-tel-input-section .react-phone-number-input .react-phone-number-input-right-block .react-phone-number-input-right-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: 8s ease 0s 1 normal forwards running ffGLem;
}
.tel-input .refari-tel-input-section .react-phone-number-input .react-phone-number-input-right-block-error:before {
  background-color: rgba(255,0,0,0.25);
}
.tel-input .refari-tel-input-section .react-phone-number-input .PhoneInputInput {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.8);
  border-radius: none;
  background: transparent;
  box-shadow: unset;
  padding: 8px 0;
}
.tel-input .refari-tel-input-section .react-phone-number-input__input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.8);
  border-radius: none;
  background: transparent;
  box-shadow: unset;
}
.tel-input .refari-tel-input-section .react-phone-number-input__icon {
  width: 1.5em;
  height: 1.1em;
}
.tel-input .refari-tel-input-section .react-phone-number-input__icon--international {
  width: 1.5em;
}
.tel-input .refari-tel-input-section .react-phone-number-input__icon-image {
  max-height: unset;
  vertical-align: unset;
}
.tel-input .refari-tel-input-section .react-phone-number-input .PhoneInputCountryIcon--border {
  background: transparent;
  box-shadow: unset;
  width: 0;
}
.tel-input .refari-tel-input-section .react-phone-number-input .PhoneInputCountrySelectArrow {
  margin-bottom: 8px;
  display: block;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-top: var(--PhoneInputCountrySelectArrow-marginTop);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}
.tel-input .refari-tel-input-section .react-phone-number-input .PhoneInputCountry {
  width: 65px;
  border-bottom: 1px solid;
  margin: 0;
  padding-left: 2px;
  color: #000;
}
.tel-input .refari-tel-input-section .react-phone-number-input .PhoneInputCountry .flag-image {
  max-width: 24px;
  max-height: 16px;
  margin-left: 45px;
  transform: translate(0px, -1px);
}
.tel-input .refari-tel-input-section .react-phone-number-input .PhoneInputCountry .tel-code-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(22px, -3px);
}
.tel-input .refari-tel-input-section .react-phone-number-input .PhoneInputCountry .country-code {
  width: inherit;
  margin: 0 0 0 30px;
  transform: translate(0px, -1px);
}
.tel-input .refari-tel-input-section .react-phone-number-input__country-select {
  display: none;
}
.tel-input .refari-tel-input-section .react-phone-number-input__country-select-arrow {
  display: none;
}
.tel-input .error-message,
.tel-input .refari-error-text {
  font-size: 14px;
  padding-top: 5px;
  color: #f00;
}
.custom-text-field textarea {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  color: #757575 !important;
}
.custom-text-field hr {
  display: none !important;
}
.refari-phone-code-wrapper .modal-header {
  border-bottom: none !important;
}
.refari-phone-code-wrapper .modal-title {
  padding-top: 30px;
}
.refari-phone-code-wrapper .refari-phone-code-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: 13px;
}
.refari-phone-code-wrapper .refari-phone-code-banner svg {
  margin-left: 3px;
  cursor: pointer;
}
.refari-phone-code-wrapper .refari-phone-code-subheading {
  text-align: center;
  color: #9ea4ab;
  font-size: 14px;
  margin-bottom: 5px;
}
.refari-phone-code-wrapper .refari-phone-code-qrcode {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 216px;
  height: 216px;
  background: linear-gradient(to right, #000 4px, transparent 4px) 0 0, linear-gradient(to right, #000 4px, transparent 4px) 0 100%, linear-gradient(to left, #000 4px, transparent 4px) 100% 0, linear-gradient(to left, #000 4px, transparent 4px) 100% 100%, linear-gradient(to bottom, #000 4px, transparent 4px) 0 0, linear-gradient(to bottom, #000 4px, transparent 4px) 100% 0, linear-gradient(to top, #000 4px, transparent 4px) 0 100%, linear-gradient(to top, #000 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.refari-phone-code-wrapper .refari-phone-code-input {
  width: fit-content !important;
  margin: auto;
}
.refari-phone-code-wrapper .refari-phone-code-input input {
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-radius: 0 !important;
  margin-right: 5px;
  margin-left: 5px;
}
.refari-phone-code-wrapper .refari-phone-code-subheading-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;
}
.refari-phone-code-wrapper .refari-phone-code-subheading-bottom-text {
  color: #9ea4ab;
  font-size: 13px;
  margin-right: 5px;
}
.refari-phone-code-wrapper .refari-phone-code-subheading-bottom-link {
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
}
.refari-modal h3 {
  font-weight: 700 !important;
  padding: 50px 0 15px 0;
}
.refari-modal h4 {
  padding-bottom: 40px;
}
.refari-modal h3,
.refari-modal h4 {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}
.refari-modal-content {
  position: relative;
}
.refari-modal-content .refari-long-text {
  padding-top: 30px !important;
}
.refari-modal-content__linkedin-apply-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
}
.refari-modal-content__linkedin-apply-form-content {
  width: 100%;
}
.refari-modal-content .refari-modal-close-btn {
  position: absolute;
  top: -24px;
  right: -24px;
  padding: 10px;
  z-index: 50;
}
.refari-modal-content__social-network {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refari-modal-content__testimonial-image {
  height: 300px;
}
.refari-modal-content--testimonial-image {
  width: 100%;
  height: 100%;
}
.refari-modal-content__center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.refari-modal-content--social-network {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
.refari-modal-content__main .refari-modal-close-btn {
  top: 0;
}
.refari-modal-content__main .refari-modal-close-btn svg {
  color: #fff !important;
}
.refari-modal-content__header-section {
  background-color: #0074bc;
  margin: -24px -24px 0 -24px;
  padding: 24px;
  color: #fff;
}
.refari-modal-content__social-tabs {
  box-shadow: 0 0 3px #757575;
}
.refari-modal-content--apply-content {
  text-align: center;
}
.refari-modal-content--apply-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.refari-modal-content--header-content {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.refari-modal-content--white {
  color: #fff !important;
}
.refari-modal-content--header-createJobAlert {
  max-width: 460px !important;
  margin: 24px auto 0 !important;
  text-align: center !important;
  padding: unset !important;
}
.refari-modal-content--content {
  padding-bottom: 10px !important;
  font-size: 14px;
}
.refari-modal-content__refer-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-left: -30px;
}
.refari-modal-content__content-wraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0 -24px 0 -24px;
  padding: 24px;
  color: #fff;
}
.refari-modal-content__content-wraper .refari-modal-content--header-content {
  max-width: 330px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 14px;
}
.refari-modal-content__content-wraper .refari-modal-content--header {
  font-size: 16px;
  font-weight: 500;
}
.refari-modal-content__content-wraper .refari-modal-content--content {
  padding: 0 !important;
}
.refari-modal-content__body {
  width: auto;
  margin: 0 -24px;
}
.refari-modal-content__body .refari-social-btn-wrap {
  max-width: 440px;
  margin: 0 auto;
  padding: 0 15px;
}
.refari-modal-content--social-content {
  margin: 35px 0 30px;
  color: #757575;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
.refari-modal-content .refari-icon-wrap-section {
  width: 19px !important;
  height: 19px !important;
  background-image: none;
}
.refari-modal-content .refari-icon-wrap-section svg {
  width: 11px !important;
  height: 11px !important;
}
.refari-modal-content__footer {
  width: auto;
  margin: 24px -24px -24px;
  padding: 30px 24px;
}
.refari-modal-content__footer .refari-icon-wrap-section {
  width: 29px !important;
  height: 28px !important;
  background-image: none;
}
.refari-modal-content__footer .refari-icon-wrap-section svg {
  width: 14px !important;
  height: 21px !important;
  margin-top: -5px !important;
}
.refari-modal-content--how-it-works {
  margin-bottom: 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
.refari-modal-content__icon-section {
  display: flex;
  justify-content: space-around;
}
.refari-modal-content__footer-content {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.refari-modal-content__footer-content:nth-child(1):after {
  content: '';
  position: absolute;
  top: 14px;
  left: 120px;
  width: 100%;
  border-bottom: 1px dashed #fff;
}
.refari-modal-content__footer-content:nth-child(2) .refari-icon-wrap-section {
  background-image: none;
}
.refari-modal-content__footer-content:nth-child(2) .refari-icon-wrap-section svg {
  margin-left: 3px !important;
}
.refari-modal-content__footer-content:nth-child(2):after {
  content: '';
  position: absolute;
  top: 14px;
  left: 120px;
  width: 100%;
  border-bottom: 1px dashed #fff;
}
.refari-modal-content__footer-content:nth-child(3) .refari-icon-wrap-section {
  background-image: none;
}
.refari-modal-content__footer-content:nth-child(3) .refari-icon-wrap-section svg {
  margin-left: 3px !important;
}
.refari-hidden {
  display: none !important;
}
.refari-w-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: initial;
  margin: 0;
}
.refari-justify-start {
  display: flex;
  justify-content: flex-start;
}
.refari-justify-center {
  display: flex;
  justify-content: center;
}
.refari-justify-end {
  display: flex;
  justify-content: flex-end;
}
.refari-align-items-center {
  display: flex;
  align-items: center;
}
.refari-flex {
  display: flex;
}
.refari-copied-wrap {
  position: relative;
}
.refari-copied-wrap .refari-copied-msg {
  position: absolute;
  right: -60px;
  top: 10px;
}
.refari-copied-wrap .refari-copied-click-msg {
  cursor: pointer;
}
.refari-copied-wrap .refari-copied-click-box {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 625px) {
  .refari-copied-wrap .refari-copied-msg {
    display: none;
  }
}
.refari-additional-info {
  display: none;
}
.refari-additional-info__show {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 25px 0;
  border-left: 1px solid #bdbdbd;
  padding: 0px 10px 0;
}
.refari-additional-info--item {
  margin: 0;
}
.MuiListItemIcon-root {
  min-width: auto !important;
}
.refari-slider__wrapper {
  margin-bottom: 15px;
}
.refari-slider__wrapper .refari-slider {
  padding: 5px 0;
}
.refari-slider__wrapper .refari-slider > div > div > div:first-child {
  background-color: #bdbdbd !important;
}
.refari-slider__wrapper .refari-slider__line-indicator {
  display: flex;
  line-height: 10px;
}
.refari-slider__wrapper .refari-slider__line-indicator span {
  position: relative;
  flex: 1;
  font-size: 11px;
}
.refari-slider__wrapper .refari-slider__line-indicator .refari-line-indicator--min {
  text-align: left;
}
.refari-slider__wrapper .refari-slider__line-indicator .refari-line-indicator--zero {
  text-align: center;
}
.refari-slider__wrapper .refari-slider__line-indicator .refari-line-indicator--max {
  text-align: right;
}
.container__editor {
  font-size: 14px;
  font-variant-ligatures: common-ligatures;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.42) !important;
  border-radius: 3px;
  min-height: 100px;
/* Syntax highlighting */
}
.container__editor textarea {
  outline: 0;
}
.container__editor .button {
  display: inline-block;
  padding: 0 6px;
  text-decoration: none;
  background: #000;
  color: #fff;
}
.container__editor .button:hover {
  background: linear-gradient(45deg, #e42b66, #e2433f);
}
.container__editor .token.tag {
  color: #d65900;
}
.m_top_1x {
  margin-top: 12px !important;
}
.m_top_2x {
  margin-top: 24px !important;
}
.m_top_3x {
  margin-top: 36px !important;
}
.m_bottom_1x {
  margin-bottom: 12px !important;
}
.m_bottom_2x {
  margin-bottom: 24px !important;
}
.m_bottom_3x {
  margin-bottom: 36px !important;
}
.pad_top_3x {
  padding-top: 36px;
}
.pad_bottom_3x {
  padding-bottom: 36px;
}
@media screen and (max-width: 600px) {
  .modal {
    width: 100% !important;
  }
}
.refari-refer-modal-wrap .refari-or {
  margin: 40px 0;
}
.refari-refer-modal-wrap .refari-modal-body {
  max-width: 440px;
  margin: 0 auto;
  padding-bottom: 25px;
}
.refari-refer-modal-wrap .refari-create-account {
  margin: 10px auto;
}
.refari-refer-modal-wrap .refari-left-inline-field {
  margin-right: 15px;
}
.let-us-help-you-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.let-us-help-you-modal--header {
  line-height: 21px;
  letter-spacing: 0.642857px;
  color: #757575;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}
.let-us-help-you-modal--optional {
  margin-left: 5px;
  color: #a8a8a8;
  font-weight: 300;
  font-size: 16px;
}
.let-us-help-you-modal__form-item {
  width: 100%;
}
.let-us-help-you-modal--content {
  max-width: 460px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #757575;
}
.refari-w-modal-container {
  font-family: inherit;
  background-color: transparent !important;
}
.refari-w-modal-container .MuiDialog-paper {
  padding: 24px;
  color: rgba(60,72,86,0.6);
  font-size: 16px;
  max-width: 768px;
  background-color: #fff !important;
}
.refari-w-modal-container-small .MuiDialog-paper {
  max-width: 550px;
}
.refari-w-modal-container button {
  text-transform: none;
}
.refari-w-modal-container input,
.refari-w-modal-container button,
.refari-w-modal-container select,
.refari-w-modal-container optgroup,
.refari-w-modal-container textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.refari-w-modal-container label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.refari-long-text {
  white-space: pre-wrap;
  word-break: break-word;
  font-family: inherit;
}
.cursor-text {
  cursor: text;
}
.modal-override h3,
.modal-override h4 {
  max-width: 520px;
}
.bullet-animation-container {
  padding: 1.25rem;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}
.bullet-animation-container .bullet {
  padding: 4px;
  background-color: #25b4d5;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 0px;
}
.bullet-animation-container .bullet[data-delay="100"] {
  left: 0px;
  animation: bullet-bounce 1.5s cubic-bezier(0.44, 0.61, 0, 1.03) infinite;
}
.bullet-animation-container .bullet[data-delay="200"] {
  left: 10px;
  animation: bullet-bounce 1.5s cubic-bezier(0.44, 0.61, 0, 1.03) 0.125s infinite;
}
.bullet-animation-container .bullet[data-delay="300"] {
  left: 20px;
  animation: bullet-bounce 1.5s cubic-bezier(0.44, 0.61, 0, 1.03) 0.25s infinite;
}
.bullet-animation-container .bullet[data-delay="400"] {
  left: 30px;
  animation: bullet-bounce 1.5s cubic-bezier(0.44, 0.61, 0, 1.03) 0.5s infinite;
}
@-moz-keyframes bullet-bounce {
  from {
    top: 0px;
  }
  50% {
    top: -10px;
  }
  to {
    top: 0px;
  }
}
@-webkit-keyframes bullet-bounce {
  from {
    top: 0px;
  }
  50% {
    top: -10px;
  }
  to {
    top: 0px;
  }
}
@-o-keyframes bullet-bounce {
  from {
    top: 0px;
  }
  50% {
    top: -10px;
  }
  to {
    top: 0px;
  }
}
@keyframes bullet-bounce {
  from {
    top: 0px;
  }
  50% {
    top: -10px;
  }
  to {
    top: 0px;
  }
}
.icon-arrow {
  margin-right: 10px;
  margin-top: 2px;
}
.icon-email {
  margin-top: 5px;
  margin-right: 10px;
}
.header__user-name-wrapper {
  position: relative;
  color: #fff !important;
  font-size: 18px !important;
  padding: 20px 24px 0 16px;
}
.header__user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 28px !important;
}
.header {
  padding: 10px 0;
}
@media screen and (max-width: 600px) {
  .header {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 600px) {
  .header .header-space {
    width: 100%;
  }
}
.header .hidden-xs {
  display: block !important;
}
@media screen and (max-width: 768px) {
  .header {
    line-height: initial !important;
  }
}
.header .brand-logo-section {
  width: 63%;
  display: flex;
  justify-content: flex-end;
}
.header .brand-logo-section--button {
  display: none !important;
}
@media screen and (max-width: 991px) {
  .header .brand-logo-section {
    align-items: center;
    justify-content: space-between;
  }
  .header .brand-logo-section--button {
    display: block !important;
  }
}
.header .brand-logo {
  max-width: 425px;
  max-height: 85px;
  display: block !important;
}
@media screen and (max-width: 600px) {
  .header .brand-logo {
    max-width: 200px;
    max-height: 75px;
  }
}
@media screen and (max-width: 440px) {
  .header .brand-logo {
    max-width: 150px;
    max-height: 65px;
  }
}
@media screen and (max-width: 359px) {
  .header .brand-logo {
    max-width: 120px;
    max-height: 55px;
  }
}
.header .logo-height {
  height: 60px;
}
@media screen and (max-width: 768px) {
  .header .logo-height {
    height: 40px;
  }
}
.header .logo-right {
  display: flex;
  justify-content: flex-end;
  width: 37%;
}
.header .logo a {
  display: inline-block;
}
.header .logo img {
  height: 60px;
}
@media screen and (max-width: 768px) {
  .header .logo img {
    height: 40px;
  }
}
.header .logo .logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.header .logo .logo-link:hover {
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .header .logo .logo-link svg {
    width: 70px !important;
  }
}
@media screen and (max-width: 440px) {
  .header .logo .logo-link svg {
    width: 50px !important;
  }
}
@media screen and (max-width: 359px) {
  .header .logo .logo-link svg {
    width: 40px !important;
  }
}
.header .logo .logo-link-text {
  margin-right: 8px;
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9e9e9e;
}
@media screen and (max-width: 600px) {
  .header .logo .logo-link-text {
    font-size: 9px;
    margin-top: 12px;
  }
}
@media screen and (max-width: 440px) {
  .header .logo .logo-link-text {
    font-size: 8px;
    margin-top: 8px;
    margin-right: 5px;
  }
}
.header .logo .logo-link-image {
  width: 45px;
  height: 45px;
}
.header .header-drawer ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}
.header .header-drawer ::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.header .header-drawer ::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #8e8e8e;
}
.header .drawer-wrapper {
  min-height: 100vh;
}
.header .header__user-badge {
  font-size: 0.6em;
  right: 0;
  top: 4px;
  line-height: 1;
}
.header.header--sales {
  position: fixed;
  width: 100%;
  transform: translateX(0);
  border-bottom: 0;
  background-color: transparent;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #f9fbfe;
  box-shadow: 0 0px 0px 0px rgba(0,0,0,0.05);
  z-index: 20;
  will-change: auto;
}
.header.header--sales .logo,
.header.header--sales img {
  transition: height 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: height;
}
@media screen and (max-width: 360px) {
  .header.header--sales .logo,
  .header.header--sales img {
    height: 30px;
  }
}
.header.header--sales.is-minified {
  box-shadow: 0 1px 15px 1px rgba(0,0,0,0.05);
}
.header.header--sales.is-minified .logo,
.header.header--sales.is-minified img {
  height: 40px;
}
@media screen and (max-width: 360px) {
  .header.header--sales.is-minified .logo,
  .header.header--sales.is-minified img {
    height: 30px;
  }
}
.header-burger-menu {
  position: relative;
}
.header-burger-menu:after {
  content: '';
  position: absolute;
  right: 5px;
  top: 10px;
  border: 4px solid #e53935;
  border-radius: 50%;
}
.header-notification-menu-0 {
  position: relative;
}
.header-notification-menu-0:nth-child(1).header-notification-menu-0:after {
  content: '';
  position: absolute;
  right: 42px;
  top: 10px;
  border: 4px solid #e53935;
  border-radius: 50%;
}
.header-notification-menu {
  position: relative;
}
.header-notification-menu:nth-child(1).header-notification-menu:after {
  content: '';
  position: absolute;
  left: 38px;
  top: 10px;
  border: 4px solid #e53935;
  border-radius: 50%;
}
.header-border-button {
  border-bottom: 1px solid #3886d1;
}
.header-space {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 24px 0 224px;
}
.header-nav {
  height: 100%;
  min-height: 98px;
  color: #fff;
}
.header-nav--name {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 24px;
}
.header-nav--board {
  height: 57px;
  margin: 0;
  padding: 0 10px 0 17px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.header-nav--board > div {
  min-width: 170px;
}
.header-nav--left-space {
  padding-left: 40px !important;
  transition: background 0.3s;
  cursor: pointer;
}
.header-nav--top-border {
  border-top: 1px solid #e0efff !important;
}
.header-nav__item .MuiListItemIcon-root {
  min-width: 40px !important;
}
.header-nav--logout {
  padding-left: 56px !important;
}
.header-nav--text {
  color: #757575 !important;
}
.header-nav--text .MuiListItemIcon-root {
  min-width: 40px !important;
}
.header-nav--text svg {
  color: #757575 !important;
}
.header-nav__item svg {
  color: #757575 !important;
}
@media screen and (max-width: 991px) {
  .header-space {
    padding: 0px 15px 0 3px;
  }
}
@media screen and (max-width: 600px) {
  .extra-div-non-loggedin {
    width: inherit !important;
  }
}
.header_height--userLoggedInAs {
  height: 115px;
}
@media screen and (max-width: 991px) {
  .header_height--userLoggedInAs {
    height: 200px;
  }
}
@media screen and (max-width: 768px) {
  .header_height--userLoggedInAs {
    height: 115px;
  }
}
.header_height--regular {
  height: 105px;
}
.refari-input-wrap .MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}
.refari-input-wrap .MuiInputAdornment-positionEnd {
  margin-left: 0;
}
.refari-input-wrap input,
.refari-input-wrap textarea {
  box-shadow: none;
  border-radius: 0;
  background: inherit;
  background-color: inherit;
  border: none;
  padding: 6px 0 7px;
  box-sizing: unset;
  margin: 0;
  border-style: unset;
}
.refari-input-wrap input:focus,
.refari-input-wrap textarea:focus {
  box-shadow: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
}
.refari-field-file-wrap {
  margin-top: 15px;
  margin-bottom: 5px;
}
.refari-field-file-wrap .refari-file-remove {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: #f00;
  margin-top: 3px;
  cursor: pointer;
}
.refari-field-file-wrap .refari-file-label {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
  color: rgba(0,0,0,0.54);
}
.refari-field-file-wrap .refari-file-label .refari-required {
  font-size: 13.5px !important;
}
.refari-field-file-wrap .refari-file-input {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
.refari-field-file-wrap .refari-file-input .refari-field-file-additional-text {
  max-width: 370px;
  margin-bottom: 20px;
}
.refari-field-file-wrap .refari-file-btn-spinner {
  position: absolute !important;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.refari-field-file-wrap .refari-file-wrap {
  display: flex;
  align-items: baseline;
}
.refari-field-file-wrap .refari-file-wrap .refari-file-btn {
  margin-right: 10px;
  flex-grow: 0;
  position: relative;
}
.refari-field-file-wrap .refari-file-wrap .refari-file-preview-wrap {
  flex-grow: 1;
}
.refari-field-file-wrap .refari-file-wrap .refari-file-preview-wrap .refari-upload-file-wrap-tick {
  position: relative;
}
.refari-field-file-wrap .refari-file-wrap .refari-file-preview-wrap .refari-upload-file-wrap-tick input {
  padding-right: 35px !important;
}
.refari-field-file-wrap .refari-file-wrap .refari-file-preview-wrap .refari-upload-file-wrap-tick .refari-file-preview-right-block {
  position: absolute;
  right: 0px;
  top: 14px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
}
.refari-field-file-wrap .refari-file-wrap .refari-file-preview-wrap .refari-upload-file-wrap-tick .refari-file-preview-right-block:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 1px;
  background-color: rgba(0,191,97,0.25);
  left: 0px;
  top: 0px;
  bottom: 0px;
  transform-origin: center center;
  margin: auto 0px;
  animation: 0.2s ease 0s 1 normal none running eqFady;
}
.refari-field-file-wrap .refari-file-wrap .refari-file-preview-wrap .refari-upload-file-wrap-tick .refari-file-preview-right-block .refari-file-preview-right-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: 8s ease 0s 1 normal forwards running ffGLem;
}
.refari-field-file-wrap .refari-file-wrap .refari-file-preview-wrap .refari-upload-file-wrap-tick .refari-file-preview-right-block-error:before {
  background-color: rgba(255,0,0,0.25);
}
.refari-field-file-wrap .refari-file-wrap.refari-image {
  align-items: flex-end;
}
.refari-field-file-wrap .refari-file-wrap.refari-image .refari-file-btn-wrap {
  order: 2;
  margin-right: 0;
  margin-left: 30px;
  flex: 1 0 0;
}
.refari-field-file-wrap .refari-file-wrap.refari-image .refari-file-btn-wrap .refari-file-btn {
  max-width: 200px;
  width: 100%;
}
.refari-field-file-wrap .refari-file-wrap.refari-image .refari-file-preview-wrap {
  order: 1;
}
.refari-field-file-wrap .refari-file-wrap.refari-image .refari-file-preview-wrap .refari-upload-image-wrap {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.refari-field-file-wrap .refari-file-wrap.refari-image .refari-file-preview-wrap .refari-upload-image-wrap img {
  width: 100%;
}
.refari-field-file-wrap .refari-file-wrap .refari-upload-file-wrap input::placeholder {
  font-size: 1em;
}
@media (min-width: 1000px) and (max-width: 1130px) {
  .refari-field-file-wrap .refari-file-wrap .refari-upload-file-wrap input::placeholder {
    font-size: 0.8em;
  }
}
@media (min-width: 792px) and (max-width: 1000px) {
  .refari-field-file-wrap .refari-file-wrap .refari-upload-file-wrap input::placeholder {
    font-size: 0.6em;
  }
}
@media (max-width: 400px) {
  .refari-field-file-wrap .refari-file-wrap .refari-upload-file-wrap input::placeholder {
    font-size: 0.8em;
  }
}
.refari-input-wrap-tick {
  position: relative;
}
.refari-input-wrap-tick input,
.refari-input-wrap-tick textarea {
  padding-right: 35px !important;
}
.refari-input-wrap-tick .right-input-section-block {
  position: absolute;
  right: 0px;
  top: 45px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
}
.refari-input-wrap-tick .right-input-section-block:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 1px;
  background-color: rgba(0,191,97,0.25);
  left: 0px;
  top: 0px;
  bottom: 0px;
  transform-origin: center center;
  margin: auto 0px;
  animation: 0.2s ease 0s 1 normal none running eqFady;
}
.refari-input-wrap-tick .right-input-section-block .right-input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: 8s ease 0s 1 normal forwards running ffGLem;
}
.refari-input-wrap-tick .right-input-section-block-error:before {
  background-color: rgba(255,0,0,0.25);
}
.field-file-wrap {
  margin-top: 15px;
  margin-bottom: 5px;
}
.field-file-wrap.wide-preview .file-wrap.image .file-preview-wrap .upload-image-wrap {
  width: 300px;
  height: 100px;
  border-radius: 0%;
}
.field-file-wrap.wide-preview .file-wrap.image .file-preview-wrap .upload-image-wrap img {
  border-radius: 0%;
  max-width: 100%;
  max-height: 100%;
  width: auto;
}
.field-file-wrap .file-label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
}
.field-file-wrap .file-input {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
.field-file-wrap .file-input .field-file-additional-text {
  max-width: 370px;
  margin-bottom: 20px;
}
.field-file-wrap .file-wrap {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.field-file-wrap .file-wrap .file-btn {
  margin-right: 10px;
}
.field-file-wrap .file-wrap.image {
  align-items: start;
}
.field-file-wrap .file-wrap.image .file-btn-wrap {
  order: 2;
  margin-right: 0;
  margin-left: 30px;
  flex: 1 0 0;
}
.field-file-wrap .file-wrap.image .file-btn-wrap .file-btn {
  max-width: 200px;
  width: 100%;
}
.field-file-wrap .file-wrap.image .file-preview-wrap {
  order: 1;
}
.field-file-wrap .file-wrap.image .file-preview-wrap .upload-image-wrap {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a2c2e5;
  border-radius: 50%;
}
.field-file-wrap .file-wrap.image .file-preview-wrap .upload-image-wrap img {
  border-radius: 50%;
  width: 100%;
}
.field-file-wrap .file-wrap .field-file-additional-text {
  margin-bottom: 25px;
}
.refari-field-tags-wrap {
  max-width: 100%;
}
.refari-field-tags-wrap-new .react-tagsinput {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 10px 0px;
}
.refari-field-tags-wrap-new .react-tagsinput > span {
  align-items: inherit !important;
}
.refari-field-tags-wrap-email {
  margin-top: 15px;
}
.refari-field-tags-wrap-email label {
  font-size: 12px;
}
.refari-field-tags-wrap-floating .react-tagsinput {
  position: relative;
}
.refari-field-tags-wrap-floating .react-tagsinput .refari-react-tagsinput-tag-wrap:first-child {
  margin-top: 8px;
}
.refari-field-tags-wrap-floating .refari-react-tagsinput-input-floating-label {
  position: absolute;
  line-height: 22px;
  font-weight: 400;
  font-size: 16px;
  top: 6px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  transform: scale(1) translate(0px, 0px);
  transform-origin: left top;
  pointer-events: none;
  user-select: none;
  color: rgba(74,74,74,0.8);
}
.submit-btn {
  margin-top: 25px !important;
}
.required {
  top: 0;
  font-size: 18px;
}
.mandatory {
  top: 0;
  font-size: 12px;
}
.non-field-errors {
  font-size: 12px;
}
.inline-form {
  display: flex;
  align-items: center;
}
.inline-form .main-inline-field {
  flex: 1 0 0;
}
.inline-form .side-inline-field {
  margin-left: 30px;
  min-width: 120px;
}
.react-tagsinput {
  max-width: 100%;
}
.react-tagsinput > span {
  flex-wrap: wrap;
  display: flex;
  align-items: baseline;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap {
  margin-right: 5px;
  margin-bottom: 5px;
  max-width: 100%;
  display: flex;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap svg {
  color: #fff;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap .refari-react-tagsinput-tag {
  max-width: 100%;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap .refari-react-tagsinput-tag > span {
  text-overflow: ellipsis;
  overflow: hidden;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap .refari-react-tagsinput-tag > svg {
  flex: 1 0 24px;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap .refari-react-tagsinput-between-op {
  display: flex;
  margin-left: 10px;
  margin-right: 5px;
  font-family: inherit;
  text-decoration: none;
  color: #6db9ff;
  padding: 0px;
  outline: none;
  font-size: 12px;
  font-weight: inherit;
  align-items: center;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap .refari-react-tagsinput-between-op-or {
  color: #f5a623;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap-new {
  margin-bottom: 10px;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap-new .MuiChip-root {
  height: inherit;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap-new .react-tagsinput-tag {
  border-radius: 5px !important;
  padding: 3px !important;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap-new .react-tagsinput-tag span {
  padding-right: 20px !important;
  line-height: 36px !important;
}
.react-tagsinput .refari-react-tagsinput-tag-wrap-new .react-tagsinput-tag svg {
  width: 20px !important;
  color: #fff;
}
.react-tagsinput .refari-react-tagsinput-tag-add-button {
  margin-left: 5px;
  margin-bottom: 10px;
  max-width: 100%;
  display: flex;
}
.react-tagsinput .refari-react-tagsinput-tag-add-button .add-tag-button {
  padding: 0 !important;
  width: 40px !important;
  height: 40px !important;
}
.react-tagsinput .refari-react-tagsinput-tag-add-button .add-tag-button svg {
  height: 35px !important;
  width: 35px !important;
}
.react-tagsinput .refari-react-tagsinput-input {
  flex: 1 0 0;
  min-width: 200px;
}
.react-tagsinput .refari-react-tagsinput-input .MuiFormControl-fullWidth {
  margin: 0;
}
.react-tagsinput .refari-react-tagsinput-input-tick {
  position: relative;
}
.react-tagsinput .refari-react-tagsinput-input-tick input {
  padding-right: 35px !important;
}
.react-tagsinput .refari-react-tagsinput-input-tick .refari-react-tagsinput-input-right-block {
  position: absolute;
  right: 0px;
  top: 15px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
}
.react-tagsinput .refari-react-tagsinput-input-tick .refari-react-tagsinput-input-right-block:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 1px;
  background-color: rgba(0,191,97,0.25);
  left: 0px;
  top: 0px;
  bottom: 0px;
  transform-origin: center center;
  margin: auto 0px;
  animation: 0.2s ease 0s 1 normal none running eqFady;
}
.react-tagsinput .refari-react-tagsinput-input-tick .refari-react-tagsinput-input-right-block .refari-react-tagsinput-input-right-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: 8s ease 0s 1 normal forwards running ffGLem;
}
.react-tagsinput .refari-react-tagsinput-input-new {
  min-width: 100%;
  width: 100%;
  margin-bottom: 10px;
}
.react-tagsinput .refari-react-tagsinput-input-new .MuiFormControl-fullWidth {
  margin-bottom: 5px !important;
  margin-top: 15px !important;
}
.react-tagsinput .refari-react-tags-select-input-new {
  margin-bottom: 10px;
}
.react-tagsinput .refari-react-tags-select-input {
  position: relative;
  flex: 1 0 0;
  min-width: 100%;
  width: 100%;
}
.react-tagsinput .refari-react-tags-select-input__modal {
  position: absolute;
  left: 0;
  top: 45px;
  width: 100%;
  height: auto;
  max-height: 167px;
  overflow-y: scroll !important;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.react-tagsinput .refari-react-tags-select-input__modal .refari-filter-item {
  padding: 0px 56px 0px 20px !important;
}
.react-tagsinput .refari-react-tags-select-input__modal .refari-nested {
  padding-left: 40px !important;
}
.react-tagsinput .refari-react-tags-select-input--empty-modal {
  padding: 10px;
  text-align: 10px;
  font-size: 14px;
}
.refari-google-search-location {
  flex: 1 0 0;
  min-width: 200px;
}
.refari-google-search-location .MuiFormControl-fullWidth {
  margin: 0;
}
.refari-google-search-location-error {
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: #f44336;
}
.refari-google-search-location-tick {
  position: relative;
}
.refari-google-search-location-tick input {
  padding-right: 35px !important;
}
.refari-google-search-location-tick .refari-google-search-location-right-block {
  position: absolute;
  right: 0px;
  top: 18px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
}
.refari-google-search-location-tick .refari-google-search-location-right-block:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 1px;
  background-color: rgba(0,191,97,0.25);
  left: 0px;
  top: 0px;
  bottom: 0px;
  transform-origin: center center;
  margin: auto 0px;
  animation: 0.2s ease 0s 1 normal none running eqFady;
}
.refari-google-search-location-tick .refari-google-search-location-right-block .refari-google-search-location-right-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: 8s ease 0s 1 normal forwards running ffGLem;
}
.refari-textarea-no-rows textarea {
  min-height: 50px;
}
.refari-job-alert-template {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.refari-job-alert-template button.refari-button-raised {
  margin-top: 15px;
}
.refari-message-template-modal {
  max-width: 500px !important;
}
.m-right_1x {
  margin-right: 10px;
}
.m_r_1px {
  margin-right: 14px !important;
}
.consultant-tags {
  flex-wrap: wrap;
  display: flex;
}
.consultant-tags .consultant-tag {
  margin-top: 2px !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}
.consultant-tags .consultant-tag > span {
  text-overflow: ellipsis;
  max-width: 680px;
  overflow: hidden;
}
.images-list label {
  margin-top: 20px;
}
.images-list .images-list__additional-text {
  margin-bottom: 30px;
}
.images-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.images-list .images-list__img-wrapper {
  width: 250px;
  height: 131px;
}
.images-list .images-list__img-wrapper img {
  display: block;
  max-width: 250px;
  width: 250px;
}
.images-list .field-file-wrap .file-wrap.image .file-btn-wrap {
  margin-left: 0;
}
.images-list .field-file-wrap .file-wrap.image .file-btn-wrap .file-btn {
  max-width: 140px;
}
.images-list .square-preview {
  margin-top: 0px;
  margin-bottom: 1rem;
}
.refari-subscribe-tab-content div {
  overflow: visible !important;
}
.refari-subscribe-tab-content .separtor-section {
  display: flex;
  margin-top: 25px;
  margin-bottom: -5px;
  margin-left: -40px;
  align-items: center;
}
.refari-subscribe-tab-content .separtor-section hr {
  border-style: dashed none;
  border-color: #ccc;
  border-top: 0.5px #ccc dashed;
  margin: 0;
  padding: 0;
}
.refari-subscribe-tab-content .separtor-section hr.left-line {
  width: 35px;
}
.refari-subscribe-tab-content .separtor-section hr.right-line {
  width: calc(100% - 100px);
}
.refari-subscribe-tab-content .separtor-section label {
  width: 65px;
  text-align: center;
  color: #6db9ff;
  margin: 0;
  font-size: 14px;
  padding: 0;
}
.refari-subscribe-tab-content__center-button {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.refari-subscribe-tab-content__center-button .refari-submit-btn {
  margin-top: 0 !important;
  margin-right: 12px;
}
.refari-subscribe-link-modal .separtor-section {
  margin-left: 0px !important;
}
.cke_top {
  display: none !important;
}
.cke_bottom {
  display: none !important;
}
.cke_contents {
  height: 100px !important;
}
.cke {
  border: 1px solid rgba(0,0,0,0.42) !important;
}
.refari-input-editor-wrap {
  margin-top: 15px;
}
.refari-input-editor-wrap .MuiTypography-root {
  margin-bottom: 0;
  color: rgba(0,0,0,0.54);
  font-size: 16px;
}
.refari-input-editor-wrap .public-DraftEditor-content {
  min-height: 70px;
}
.refari-submit-btn {
  white-space: nowrap;
  margin-top: 25px !important;
}
.refari-submit-btn [type="submit"] {
  text-shadow: none;
}
.refari-submit-btn [type="submit"]:focus,
.refari-submit-btn [type="submit"]:hover {
  text-shadow: none;
}
.refari-submit-btn button div div span {
  padding: 10px !important;
}
@media screen and (max-width: 800px) {
  .refari-subscribe-wrap form {
    display: flex;
  }
  .refari-subscribe-wrap .refari-input-wrap {
    width: 70% !important;
  }
  .refari-subscribe-wrap .refari-submit-btn {
    margin-left: 24px !important;
    margin-top: 28px !important;
    height: 100% !important;
    width: 30% !important;
    min-width: 112px !important;
    white-space: nowrap;
  }
  .refari-subscribe-wrap .refari-submit-btn button div div span {
    padding: 0 !important;
  }
}
@media screen and (max-width: 460px) {
  .file-wrap {
    flex-direction: column;
  }
  .field-file-wrap .file-btn-wrap {
    margin-left: 0 !important;
    margin-top: 10px;
  }
}
.backgrounds-group-container .images-list__additional-text {
  max-width: 600px;
}
.backgrounds-group-container .images-list__loader-wrapper {
  display: block;
  text-align: center;
  width: 100%;
  max-width: 35%;
}
@media screen and (max-width: 825px) {
  .backgrounds-group-container .images-list__loader-wrapper {
    max-width: 100%;
  }
}
.backgrounds-group-container .backgrounds-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
}
.backgrounds-group-container .backgrounds-list li {
  margin-bottom: 25px;
}
.backgrounds-group-container .backgrounds-list .images-list__img-wrapper {
  flex: 1;
  margin-right: 15px;
  position: relative;
}
.color-picker-wrap {
  position: relative;
  margin-top: 10px;
}
.color-picker-wrap .color-picker__swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  border: 1px solid rgba(0,0,0,0.1);
  display: inline-block;
  cursor: pointer;
}
.color-picker-wrap .color-picker__swatch-inner {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}
.color-picker-wrap .color-picker__popover {
  position: absolute;
  top: 100%;
}
.color-picker-wrap .color-picker__picker-widget {
  z-index: 2;
  position: relative;
}
.color-picker-wrap .color-picker__backdrop {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
}
.color-picker-wrap .color-picker__label {
  max-width: 130px;
  display: inline-block;
  margin-top: 0;
  line-height: 38px;
}
.refari-select-white svg {
  fill: #fff;
}
.position-relative {
  position: relative;
}
.refari-input--nofullWidthMargin .MuiFormControl-root.MuiFormControl-fullWidth {
  margin: 0;
}
.refari-input--disableLabelTransition .MuiTextField-root .MuiInputLabel-formControl {
  transform: none !important;
  transition: none !important;
  top: -22px;
}
.refari-select-wrap {
  position: relative;
}
.refari-select-wrap .MuiSelect-select.Mui-disabled {
  background-color: #f1f5f9;
}
.right-select-section-block {
  position: absolute;
  right: 12px;
  top: 24px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
}
.right-input-section-block-error:before {
  background-color: rgba(255,0,0,0.25);
}
a.link,
button.link {
  font-size: 14px;
  display: flex;
  cursor: pointer;
  padding: 0;
}
a.link.inline,
button.link.inline {
  display: inline-flex;
}
a.link:hover,
button.link:hover,
a.link:active,
button.link:active {
  text-decoration: underline;
}
a.link:hover,
button.link:hover {
  color: #1565c0;
}
a.link.btn-link,
button.link.btn-link {
  background: none;
  border: none;
  outline: none;
}
a.link.small,
button.link.small {
  font-size: 12px;
}
.auth-modal h3 {
  text-align: center;
  font-weight: 700;
  padding-top: 35px;
}
.auth-modal .right-side {
  padding: 0 15px 0 30px;
}
.auth-modal .right-side h3 {
  padding-bottom: 20px;
}
.auth-modal .left-side {
  padding: 0 30px 0 15px;
}
.auth-modal .left-side h3 {
  padding-bottom: 35px;
}
.auth-modal .left-side h3.with-form {
  padding-bottom: 20px;
}
.auth-modal .links-to-static {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.auth-modal .forgot-password-btn {
  margin: 10px auto;
}
.auth-modal .verify-form {
  max-width: 400px;
  margin: 0 auto;
}
.auth-modal .refari-reset-password .align-center h2 {
  font-size: 30px;
  line-height: 54px;
  font-weight: 700;
}
.auth-modal .refari-reset-password .align-center h5 {
  padding: 10px 0 15px 0;
  margin: 0;
  font-size: 16px;
}
.auth-modal .refari-reset-password .align-center p {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 700;
  color: #3e4044;
}
@media screen and (max-width: 792px) {
  .auth-modal .right-side,
  .auth-modal .left-side {
    padding: 0 15px;
  }
  .auth-modal .vertical-divider {
    display: none;
  }
}
.pre-table {
  min-height: 78px;
}
.table-wrap {
  position: relative;
  box-shadow: 0 1px 5px rgba(0,0,0,0.13), 0 1px 2px rgba(0,0,0,0.2);
  overflow: hidden;
  margin-bottom: 25px;
}
.table-wrap .table-fake-scroll-container {
  overflow-y: hidden;
  overflow-x: auto;
  height: 16px;
}
.table-wrap .table-fake-scroll-container > div {
  display: inline-block;
  white-space: nowrap;
}
.table-wrap .table {
  overflow-y: hidden;
  overflow-x: auto;
}
.table-wrap .table tr.disabled {
  opacity: 0.5;
}
.table-wrap .table tr.bg-grey {
  background-color: #f3f3f3;
}
.table-wrap .table tr.highlighted {
  background-color: rgba(255,255,0,0.541);
}
.table-wrap .table .status-button {
  margin-left: 15px !important;
}
.table-wrap .table .status-button.hover-status-button > div > div {
  opacity: 1 !important;
}
.table-wrap .table .status-button > div > div {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ccc;
  padding: 10px !important;
  font-size: 12px !important;
  right: 40px !important;
  left: -250px !important;
  line-height: 20px !important;
  transform: translate(0px, 10px) !important;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  -webkit-box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  -moz-box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
}
.table-wrap .table .status-button > div > div > div {
  background-color: #fff !important;
}
.table-wrap .table .status-button > div > div span {
  white-space: initial !important;
}
.table-wrap .table .MuiTableCell-head {
  width: 200px;
  overflow: visible;
}
.pagination-wrap {
  margin-bottom: 25px;
  text-align: center;
}
.MuiPagination-ul {
  justify-content: center;
}
.job-featured-modal .table-wrap .table {
  min-width: auto;
}
.job-featured-modal .table-wrap .table .MuiTableCell-head {
  padding: 15px 12px 15px 24px;
  color: #fff;
}
.details {
  padding: 20px;
  position: relative;
  width: 100%;
}
.details.loading {
  overflow: hidden;
  height: 100vh;
  opacity: 0.5;
}
.details h3 {
  margin-bottom: 15px;
  padding-right: 30px;
  word-break: break-word;
}
.details dl dt {
  font-weight: 400;
  color: #9e9e9e;
}
.details dl dd {
  word-break: break-word;
}
.details .details-divider {
  margin-bottom: 15px !important;
}
.details .details-message {
  margin-bottom: 30px;
}
.details .details-success {
  height: 150px;
  display: flex;
  align-items: center;
  text-align: center;
  font-style: italic;
}
.details .details-section {
  margin-bottom: 30px;
}
.details__unlink-section {
  padding-top: 26px;
  text-align: center;
}
.tabs-container {
  width: 100%;
  position: relative;
}
.tabs-container .tabs-wrap {
  box-shadow: 0 2px 5px rgba(0,0,0,0.13), 0 1px 2px rgba(0,0,0,0.12);
  margin-bottom: 15px;
}
.tabs-container .container {
  padding: 0 !important;
}
.tabs-container .tabs-inner-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.tabs-container .tabs-inner-wrapper button > div {
  background-color: #fff;
}
.tabs-container .tabs-inner-wrapper.with-scroll-indicator:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 75%);
  background-repeat: no-repeat;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .tabs-container .tabs div button {
    transition: background-color 0.3s ease-in-out;
  }
  .tabs-container .tabs div button:hover {
    background-color: #f5f5f5 !important;
  }
}
@media screen and (max-width: 768px) {
  .tabs-container .tabs-wrap .tabs {
    max-width: 1200px !important;
  }
  .tabs-container .tabs-wrap .tabs div button {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}
.centred-form {
  max-width: 340px;
  display: flex;
  flex: 1 1 340px;
  flex-direction: column;
  justify-content: center;
  margin: 25px auto;
  padding: 0 10px;
}
.centred-form h2 {
  font-weight: 700;
}
.invited-user-form {
  max-width: 400px;
}
@media screen and (max-width: 768px) {
  .invited-user-form {
    max-width: 340px;
  }
  .invited-user-form h2 {
    overflow: scroll;
  }
}
.refari-socialize-modal {
  padding: 24px;
  color: rgba(60,72,86,0.6) !important;
  font-size: 16px;
  max-width: 768px !important;
  background-color: #fff !important;
}
.refari-socialize-modal .MuiButton-label {
  font-size: 14px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.refari-socialize-modal .refari-field-file-wrap .refari-file-input {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
.refari-socialize-modal .MuiPaper-elevation1 {
  box-shadow: rgba(0,0,0,0.12) 0px 1px 6px, rgba(0,0,0,0.12) 0px 1px 4px;
}
.refari-socialize-modal .refari-card-image-holder {
  min-height: 100px;
}
.refari-socialize-modal .refari-modal-header {
  padding: 15px 0;
  font-size: 24px;
  padding-top: 30px !important;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  font-weight: 700 !important;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 36px;
}
.refari-socialize-modal .refari-card-media__loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.5);
}
.refari-socialize-modal .refari-card-header {
  padding: 15px;
}
.refari-socialize-modal .refari-card-title-field {
  font-weight: 600;
}
.refari-socialize-modal .refari-card-description {
  margin-bottom: 15px;
  line-height: 22px;
}
.refari-socialize-modal .refari-card-description-field div {
  font-size: 14px !important;
}
.refari-socialize-modal .refari-field-file-wrap {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 5px;
}
.refari-socialize-modal .refari-card-subtitle {
  max-width: 380px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
}
.refari-socialize-modal .refari-card-tags {
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
  padding-top: 15px;
}
@media screen and (max-width: 500px) {
  .refari-socialize-modal .refari-card-tags {
    flex-wrap: wrap;
  }
}
.refari-socialize-modal .refari-social-btn-wrapper {
  display: none !important;
}
.refari-socialize-modal .refari-direct-link-wrap {
  padding: 30px 15px;
}
.refari-socialize-modal .refari-direct-link-wrap .refari-copied-wrap {
  max-width: 300px;
  margin: 0 auto 30px;
}
.refari-socialize-modal .refari-color-slider {
  padding: 0 0 15px;
}
.refari-direct-link-wrap__copied-section {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}
.refari-direct-link-wrap--copied-header {
  margin-top: 24px !important;
  margin-bottom: 14px !important;
  padding-bottom: 0 !important;
}
.refari-canvas-wrapper {
  position: relative;
}
.refari-socialize__first-step {
  padding: 0 2px;
}
.refari-socialize__first-step .refari-socialize__radio-group {
  margin: 10px 0;
}
.refari-socialize__first-step .refari-socialize__radio-group .refari-socialize__radio-buttons {
  width: auto !important;
  display: inline-block !important;
  min-width: 180px;
}
.refari-socialize__first-step .refari-socialize__radio-group .MuiFormControl-fullWidth {
  margin: 0 !important;
}
.refari-socialize__first-step .refari-first-step__hint {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 22px;
}
.refari-socialize__first-step .refari-description-counter {
  font-size: 12px;
  line-height: 18px;
}
.refari-socialize__first-step .refari-additional-options-row {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0 !important;
}
.refari-socialize__first-step .refari-additional-options-row .MuiFormControl-fullWidth {
  margin: 0 !important;
}
.refari-socialize__first-step .refari-background-options {
  max-width: 500px;
  display: flex;
  margin: 0 -2px 15px;
  overflow-y: auto;
  overflow-x: visible;
  padding-bottom: 5px;
}
.refari-socialize__first-step .refari-background-options .refari-option-wrapper {
  border: 1px solid transparent;
  position: relative;
}
.refari-socialize__first-step .refari-background-options .refari-option-wrapper label.hasVideo::before {
  background-image: url("../../assets/images/playicon.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(128,128,128,0.314);
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.refari-socialize__first-step .refari-background-options label {
  padding: 2px;
  width: 154px;
  max-height: 79px;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
}
.refari-socialize__first-step .refari-background-options label:hover {
  transform: scale(0.9);
}
.refari-socialize__first-step .refari-background-options label:hover img {
  box-shadow: 0px 1px 7px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-background-options img {
  display: inline-block;
  width: 100%;
  user-select: none;
  transition: all 0.2s ease;
}
.refari-socialize__first-step .refari-background-options::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}
.refari-socialize__first-step .refari-background-options::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
}
.refari-socialize__first-step .refari-background-options::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #8e8e8e;
}
.refari-socialize__first-step .refari-background-options-testimonial {
  max-height: 100px;
  display: flex;
  margin: 8px 0;
  position: relative;
}
.refari-socialize__first-step .refari-background-options-testimonial .slick-slider {
  width: 100%;
}
.refari-socialize__first-step .refari-background-options-testimonial .slick-slider .slick-list {
  margin-bottom: 15px;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-background-options-testimonial-shadows-left-shadow {
  position: absolute;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 9;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-background-options-testimonial-shadows-left-shadow img {
  max-width: 50px;
  height: 100%;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-background-options-testimonial-shadows-right-shadow {
  position: absolute;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 9;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-background-options-testimonial-shadows-right-shadow img {
  max-width: 50px;
  height: 100%;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-option-testimonial-wrapper {
  border: 1px solid transparent;
  padding: 0 2px;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-option-testimonial-wrapper img {
  max-height: 100px;
}
.refari-socialize__first-step .refari-background-options-testimonial label {
  padding: 2px;
  margin: 0;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
}
.refari-socialize__first-step .refari-background-options-testimonial label img {
  box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-background-options-testimonial label.selected-background-image img {
  box-shadow: none;
}
.refari-socialize__first-step .refari-background-options-testimonial label:hover {
  transform: scale(0.9);
}
.refari-socialize__first-step .refari-background-options-testimonial label:hover img {
  box-shadow: 0px 1px 7px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-background-options-testimonial img {
  display: inline-block;
  width: 100%;
  user-select: none;
  transition: all 0.2s ease;
}
.refari-socialize__first-step .refari-canvas-wrapper-testimonial .konvajs-content {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-font-selector {
  padding-top: 10px;
  margin-bottom: 25px;
}
.refari-socialize__first-step .refari-font-selector .MuiAutocomplete-endAdornment {
  top: calc(50% - 22px);
}
.refari-socialize__first-step .refari-font-selector__label {
  font-size: 14px;
}
.refari-socialize__first-step .refari-font-selector__label span {
  color: rgba(0,0,0,0.54);
  line-height: 14px;
}
.refari-socialize__first-step .refari-font-selector__provider {
  padding-top: 15px;
  font-size: 12px;
  color: rgba(0,0,0,0.54);
}
.refari-socialize__second-step {
  padding: 10px 5px 15px;
}
.refari-socialize__second-step .refari-social-btn-wrapper {
  margin-bottom: 40px;
}
.refari-socialize__second-step .refari-socialize_networks {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network {
  display: flex;
  flex: 1;
  width: 33.333333% !important;
  min-width: 165px !important;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network .refari-socialize_network__label {
  display: flex;
  flex-wrap: wrap;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network .refari-socialize_network__label span {
  flex: 1 0 100%;
  font-size: 12px;
  display: flex;
  margin: 5px 0 0px;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network .refari-socialize_network__label span svg {
  height: 15px !important;
  width: 15px !important;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network svg {
  margin: 0 5px;
}
.refari-socialize__second-step .refari-socialize_companies {
  margin-bottom: 20px;
}
.refari-socialize__second-step .refari-socialize__need-invite {
  margin-bottom: 15px;
}
.refari-socialize__second-step .refari-direct-link-wrap {
  padding: 0 15px 15px;
}
.refari-socialize__second-step .refari-direct-link-wrap h4 {
  padding-bottom: 15px;
}
.refari-socialise-next-button.Mui-disabled {
  opacity: 0.5;
}
@media screen and (max-width: 792px) {
  .refari-socialize__second-step .refari-social-btn-wrapper {
    display: block;
  }
  .refari-socialize__second-step .refari-col {
    padding: 0;
    margin-bottom: 15px;
  }
  .refari-socialize__second-step .refari-social-btn-section {
    flex-wrap: wrap;
  }
}
.refari-custom-background-modal {
  padding: 24px 12px !important;
  font-size: 16px !important;
  background-color: #fff !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-width: 1060px !important;
}
.refari-custom-background-modal p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 15px;
}
.refari-custom-background-modal .refari-custom-background-template {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
@media screen and (max-width: 1100px) {
  .refari-custom-background-modal .refari-custom-background-template {
    flex-wrap: wrap;
  }
}
.refari-custom-background-modal .refari-custom-background-template-left {
  padding: 0 15px;
}
@media screen and (max-width: 1100px) {
  .refari-custom-background-modal .refari-custom-background-template-left {
    width: 100%;
    padding: 0 15px;
  }
}
.refari-custom-background-modal .refari-custom-background-template-left-most {
  max-width: 146px;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 6px;
  margin: 0 15px 0 0;
}
.refari-custom-background-modal .refari-custom-background-template-left-most::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}
.refari-custom-background-modal .refari-custom-background-template-left-most::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.refari-custom-background-modal .refari-custom-background-template-left-most::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #8e8e8e;
}
.refari-custom-background-modal .refari-custom-background-template-left-most .refari-background-options {
  margin: 0 !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
.refari-custom-background-modal .refari-custom-background-template-left-most .refari-background-options-inner {
  flex-direction: column;
}
.refari-custom-background-modal .refari-custom-background-template-left-most .refari-background-options-inner .refari-option-wrapper label {
  width: 154px;
  max-height: 79px;
  overflow: hidden;
}
.refari-custom-background-modal .refari-custom-background-template-right {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 15px;
  width: 100%;
  max-width: 670px;
}
@media screen and (max-width: 1100px) {
  .refari-custom-background-modal .refari-custom-background-template-right {
    padding: 0 15px;
    margin-top: 20px;
    max-height: none !important;
    overflow: visible;
  }
}
.refari-custom-background-modal .refari-custom-background-template-right-form {
  margin-bottom: 10px;
}
.refari-custom-background-modal .refari-custom-background-template-right-form label {
  color: rgba(0,0,0,0.871);
  font-weight: 700;
}
.refari-custom-background-modal .refari-custom-background-template-right-additional-form p {
  margin-bottom: 5px;
  margin-top: 10px;
  font-weight: 700;
}
.refari-custom-background-modal .refari-custom-background-template-right-additional-form .MuiFormControl-root.MuiFormControl-fullWidth {
  margin-top: 5px;
}
.refari-custom-background-modal .refari-custom-background-template-right-additional-form .color-picker-wrap {
  margin-bottom: 15px;
}
.refari-custom-background-modal .refari-custom-background-template-right::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}
.refari-custom-background-modal .refari-custom-background-template-right::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
.refari-custom-background-modal .refari-custom-background-template-right::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #8e8e8e;
}
.refari-custom-background-modal-socialise {
  max-width: 1440px !important;
}
.refari-custom-background-modal-socialise .refari-custom-background-template-left {
  padding: 0 15px 0 0;
}
.refari-custom-background-modal-socialise .refari-custom-background-template-left-most {
  max-width: 176px;
  min-width: 176px;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.refari-custom-background-modal-socialise .refari-custom-background-template-right {
  max-height: 600px;
  width: 100%;
  padding: 0 15px 0 5px;
  max-width: 670px;
}
.refari-custom-background-modal-socialise .MuiStepConnector-lineVertical {
  border: none;
  min-height: 10px;
}
.refari-custom-background-modal-socialise .refari-modal-header {
  padding-top: 15px !important;
}
.refari-custom-background-modal-socialise .refari-card-header {
  padding: 0 !important;
}
.refari-custom-background-modal .field-tags-wrap {
  margin-top: 0;
}
.refari-custom-background-modal .MuiButton-label {
  font-size: 14px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.refari-custom-background-modal .refari-field-file-wrap .refari-file-input {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}
.refari-custom-background-modal .MuiPaper-elevation1 {
  box-shadow: rgba(0,0,0,0.12) 0px 1px 6px, rgba(0,0,0,0.12) 0px 1px 4px;
}
.refari-custom-background-modal .refari-card-image-holder {
  min-height: 100px;
}
.refari-custom-background-modal .refari-modal-header {
  padding: 15px 0;
  font-size: 24px;
  padding-top: 30px !important;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  font-weight: 700 !important;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 36px;
}
.refari-custom-background-modal .refari-card-media__loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(255,255,255,0.5);
}
.refari-custom-background-modal .refari-card-header {
  padding: 15px;
}
.refari-custom-background-modal .refari-card-title-field {
  font-weight: 600;
}
.refari-custom-background-modal .refari-card-description {
  margin-bottom: 15px;
  line-height: 22px;
}
.refari-custom-background-modal .refari-card-description-field div {
  font-size: 14px !important;
}
.refari-custom-background-modal .refari-field-file-wrap {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 5px;
}
.refari-custom-background-modal .refari-card-subtitle {
  max-width: 380px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
}
.refari-custom-background-modal .refari-card-tags {
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
  padding-top: 15px;
}
@media screen and (max-width: 500px) {
  .refari-custom-background-modal .refari-card-tags {
    flex-wrap: wrap;
  }
}
.refari-custom-background-modal .refari-social-btn-wrapper {
  display: none !important;
}
.refari-custom-background-modal .refari-direct-link-wrap {
  padding: 30px 15px;
}
.refari-custom-background-modal .refari-direct-link-wrap .refari-copied-wrap {
  max-width: 300px;
  margin: 0 auto 30px;
}
.refari-custom-background-modal .refari-color-slider {
  padding: 0 0 15px;
}
.refari-direct-link-wrap__copied-section {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}
.refari-direct-link-wrap--copied-header {
  margin-top: 24px !important;
  margin-bottom: 14px !important;
  padding-bottom: 0 !important;
}
.refari-canvas-wrapper {
  position: relative;
}
.refari-socialize__first-step {
  padding: 0 2px;
}
.refari-socialize__first-step .refari-socialize__radio-group {
  margin: 10px 0;
}
.refari-socialize__first-step .refari-socialize__radio-group .refari-socialize__radio-buttons {
  width: auto !important;
  display: inline-block !important;
  min-width: 180px;
}
.refari-socialize__first-step .refari-socialize__radio-group .MuiFormControl-fullWidth {
  margin: 0 !important;
}
.refari-socialize__first-step .refari-first-step__hint {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 22px;
}
.refari-socialize__first-step .refari-description-counter {
  font-size: 12px;
  line-height: 18px;
}
.refari-socialize__first-step .refari-additional-options-row {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0 !important;
}
.refari-socialize__first-step .refari-additional-options-row .MuiFormControl-fullWidth {
  margin: 0 !important;
}
.refari-socialize__first-step .refari-background-options {
  max-width: 500px;
  display: flex;
  margin: 0 -2px 15px;
  overflow-y: auto;
  overflow-x: visible;
  padding-bottom: 5px;
}
.refari-socialize__first-step .refari-background-options.refari-gif-frames label {
  transform: scale(0.9);
}
.refari-socialize__first-step .refari-background-options.refari-gif-frames label img {
  box-shadow: 0px 1px 7px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-background-options.refari-gif-frames label:hover {
  transform: scale(1);
}
.refari-socialize__first-step .refari-background-options.refari-gif-frames label:hover img {
  box-shadow: none;
}
.refari-socialize__first-step .refari-background-options.refari-gif-frames .refari-option-wrapper-selected label transform scale(1) img {
  box-shadow: none;
}
.refari-socialize__first-step .refari-background-options .refari-option-wrapper {
  border: 1px solid transparent;
  position: relative;
}
.refari-socialize__first-step .refari-background-options .refari-option-wrapper label.hasVideo::before {
  background-image: url("../../assets/images/playicon.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(128,128,128,0.314);
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.refari-socialize__first-step .refari-background-options label {
  padding: 2px;
  width: 154px;
  max-height: 79px;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
}
.refari-socialize__first-step .refari-background-options label:hover {
  transform: scale(0.9);
}
.refari-socialize__first-step .refari-background-options label:hover img {
  box-shadow: 0px 1px 7px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-background-options img {
  display: inline-block;
  width: 100%;
  user-select: none;
  transition: all 0.2s ease;
}
.refari-socialize__first-step .refari-background-options::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}
.refari-socialize__first-step .refari-background-options::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
}
.refari-socialize__first-step .refari-background-options::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #8e8e8e;
}
.refari-socialize__first-step .refari-background-options-testimonial {
  max-height: 100px;
  display: flex;
  margin: 8px 0;
  position: relative;
}
.refari-socialize__first-step .refari-background-options-testimonial .slick-slider {
  width: 100%;
}
.refari-socialize__first-step .refari-background-options-testimonial .slick-slider .slick-list {
  margin-bottom: 15px;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-background-options-testimonial-shadows-left-shadow {
  position: absolute;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 9;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-background-options-testimonial-shadows-left-shadow img {
  max-width: 50px;
  height: 100%;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-background-options-testimonial-shadows-right-shadow {
  position: absolute;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 9;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-background-options-testimonial-shadows-right-shadow img {
  max-width: 50px;
  height: 100%;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-option-testimonial-wrapper {
  border: 1px solid transparent;
  padding: 0 2px;
}
.refari-socialize__first-step .refari-background-options-testimonial .refari-option-testimonial-wrapper img {
  max-height: 100px;
}
.refari-socialize__first-step .refari-background-options-testimonial label {
  padding: 2px;
  margin: 0;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
}
.refari-socialize__first-step .refari-background-options-testimonial label img {
  box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-background-options-testimonial label.selected-background-image img {
  box-shadow: none;
}
.refari-socialize__first-step .refari-background-options-testimonial label:hover {
  transform: scale(0.9);
}
.refari-socialize__first-step .refari-background-options-testimonial label:hover img {
  box-shadow: 0px 1px 7px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-background-options-testimonial img {
  display: inline-block;
  width: 100%;
  user-select: none;
  transition: all 0.2s ease;
}
.refari-socialize__first-step .refari-canvas-wrapper-testimonial .konvajs-content {
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
}
.refari-socialize__first-step .refari-font-selector {
  padding-top: 10px;
  margin-bottom: 25px;
}
.refari-socialize__first-step .refari-font-selector .MuiAutocomplete-endAdornment {
  top: calc(50% - 22px);
}
.refari-socialize__first-step .refari-font-selector__label {
  font-size: 14px;
}
.refari-socialize__first-step .refari-font-selector__label span {
  color: rgba(0,0,0,0.54);
  line-height: 14px;
}
.refari-socialize__first-step .refari-font-selector__provider {
  padding-top: 15px;
  font-size: 12px;
  color: rgba(0,0,0,0.54);
}
.refari-socialize__second-step {
  padding: 10px 5px 15px;
}
.refari-socialize__second-step .refari-social-btn-wrapper {
  margin-bottom: 40px;
}
.refari-socialize__second-step .refari-socialize_networks {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network {
  display: flex;
  flex: 1;
  width: 33.333333% !important;
  min-width: 165px !important;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network .refari-socialize_network__label {
  display: flex;
  flex-wrap: wrap;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network .refari-socialize_network__label span {
  flex: 1 0 100%;
  font-size: 12px;
  display: flex;
  margin: 5px 0 0px;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network .refari-socialize_network__label span svg {
  height: 15px !important;
  width: 15px !important;
}
.refari-socialize__second-step .refari-socialize_networks .refari-socialize_network svg {
  margin: 0 5px;
}
.refari-socialize__second-step .refari-socialize_companies {
  margin-bottom: 20px;
}
.refari-socialize__second-step .refari-socialize__need-invite {
  margin-bottom: 15px;
}
.refari-socialize__second-step .refari-direct-link-wrap {
  padding: 0 15px 15px;
}
.refari-socialize__second-step .refari-direct-link-wrap h4 {
  padding-bottom: 15px;
}
.refari-socialise-editor-panel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: scroll;
}
.refari-socialise-editor-left-panel {
  padding: 10px;
}
.refari-socialise-editor-tabs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 500px;
}
.refari-socialise-editor-tabs .MuiTab-root {
  min-height: auto !important;
  min-width: auto !important;
  padding: 6px 12px !important;
  background-color: #fff;
  height: 36px;
}
.refari-socialise-editor-tabs_heading {
  min-height: auto !important;
}
.refari-socialise-editor-preview-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
  box-shadow: rgba(0,0,0,0.059) 0px 1px 6px, rgba(0,0,0,0.059) 0px 1px 4px;
}
.refari-socialise-editor-preview-heading-title {
  padding-left: 6px;
  font-size: 14px;
}
.refari-socialise-editor-top {
  padding: 10px 10px 0 10px;
}
.refari-socialise-editor-top-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.refari-socialise-editor-top-inner-box {
  padding-left: 10px;
}
.refari-socialise-editor-top-inner-box-title {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 22px;
  color: #000;
  font-weight: 700;
}
.refari-socialise-editor-top-inner-box-response {
  font-size: 12px;
  margin-bottom: 0;
  color: #7a7b7d;
  line-height: 20px;
}
.refari-socialise-editor-top-inner-image {
  position: relative;
  min-width: 60px;
}
.refari-socialise-editor-top-desc {
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0;
}
.refari-socialise-editor-bottom {
  padding: 10px;
}
.refari-socialise-editor-bottom-title {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 20px;
  color: #000;
  font-weight: 700;
}
.refari-socialise-preview {
  max-height: 460px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 10px;
  padding-right: 6px;
}
@media screen and (max-width: 1100px) {
  .refari-socialise-preview {
    max-height: none !important;
    overflow: visible;
  }
}
.refari-socialise-preview::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}
.refari-socialise-preview::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.refari-socialise-preview::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #8e8e8e;
}
.refari-socialise-preview .refari-socialise-editor-top-inner-image {
  min-width: auto;
}
.refari-socialise-preview .refari-socialise-editor-top-inner-box-title {
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.refari-socialise-preview .refari-socialise-editor-top-inner-box-title span {
  padding-left: 5px;
  color: #7a7b7d;
  font-size: 12px;
}
.refari-socialise-preview .refari-socialise-editor-top-inner-box-response {
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.refari-socialise-preview .refari-socialise-editor-top-inner-box-response svg {
  margin-left: 4px !important;
}
.refari-socialise-preview .refari-socialise-editor-top-inner-box-response span {
  padding-left: 5px;
}
.refari-socialise-preview .refari-socialise-editor-bottom {
  background-color: #eef3f8;
}
.refari-socialise-preview .refari-socialise-editor-bottom-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  margin-bottom: 0;
  line-height: 20px;
  color: #7a7b7d;
  padding-top: 3px;
}
.refari-socialise-preview .refari-socialise-editor-bottom-info span {
  padding-left: 5px;
}
.refari-socialise-preview .refari-socialise-editor-bottom-domain {
  font-size: 12px;
  margin-bottom: 0px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #7a7b7d;
}
.refari-socialise-preview .refari-socialise-editor-bottom-domain span {
  padding-left: 5px;
}
.refari-socialise-preview .refari-socialise-editor-bottom-desc {
  font-size: 13px;
  margin-bottom: 0px;
  line-height: 20px;
  color: #7a7b7d;
}
.refari-socialise-preview .refari-socialise-editor-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  border-top: 1px solid #ccc;
  margin: 10px;
}
@media screen and (max-width: 792px) {
  .refari-socialize__second-step .refari-social-btn-wrapper {
    display: block;
  }
  .refari-socialize__second-step .refari-col {
    padding: 0;
    margin-bottom: 15px;
  }
  .refari-socialize__second-step .refari-social-btn-section {
    flex-wrap: wrap;
  }
}
.footer {
  transition: background-color 0.3s ease-in-out;
}
.footer.footer-top {
  padding: 20px 0;
}
.footer.footer-bottom {
  padding: 15px 0;
  color: #e1ecf8;
}
.footer.footer-bottom,
.footer .footer-link {
  font-size: 13px;
  font-weight: 300;
}
.footer .footer-link {
  text-align: center;
}
.footer .social-links {
  margin-top: -5px;
}
.footer .social-links a {
  display: inline-block;
  margin: 3px 10px 0;
  vertical-align: middle;
}
@media screen and (max-width: 727px) {
  .footer .footer-link {
    margin-top: 5px;
  }
  .footer .social-links {
    margin-top: 15px;
  }
}
.common-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 100;
}
.with-particle-background {
  position: relative;
}
.particles-background-wrapper {
  position: absolute;
  width: 100%;
  z-index: 0;
  height: 100%;
}
.page-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.page-background div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 400% !important;
  will-change: all;
}
@-webkit-keyframes colorSpin {
  25% {
    background-position: 0 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 100% 0;
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -o-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
  }
}
@-moz-keyframes colorSpin {
  25% {
    background-position: 0 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 100% 0;
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -o-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
  }
}
@-webkit-keyframes colorSpin {
  25% {
    background-position: 0 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 100% 0;
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -o-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
  }
}
@-o-keyframes colorSpin {
  25% {
    background-position: 0 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 100% 0;
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -o-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
  }
}
@keyframes colorSpin {
  25% {
    background-position: 0 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 100% 0;
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -o-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
  }
}
.btn-progress .btn-progress__spinner {
  position: absolute !important;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
.label {
  font-size: 11px;
  font-weight: 600;
  display: inline;
  padding: 2px 6px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 5px;
  margin-left: 6px;
}
.date-range-picker__actions {
  padding: 5px;
}
.Cal__Today__chevron {
  left: 57%;
}
.Cal__Header__day .Cal__Header__date {
  font-size: 26px !important;
}
.password-hint {
  font-size: 12px;
  font-style: italic;
  padding-top: 5px;
  overflow: hidden;
  transition: all 0.3s ease-in;
}
.password-hint.expand {
  max-height: 500px;
}
.password-hint.collapse {
  max-height: 0px;
}
.password-hint ul {
  padding-left: 16px;
  margin-bottom: 0;
}
.password-hint-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.password-hint-icon {
  padding-left: 12px;
}
.float-action-button-group {
  position: fixed;
  right: 30px;
  bottom: 30px;
}
.agency-heading {
  height: 53px;
}
@media screen and (max-width: 768px) {
  .agency-heading {
    margin-bottom: 15px;
  }
}
.agency-heading h1 {
  font-size: 30px;
  padding-bottom: 5px;
  text-align: center;
  color: #fff;
}
.slick-dots {
  list-style: none;
  display: flex !important;
  justify-content: center;
  padding: 10px 0;
}
.slick-dots button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  width: 10px;
  height: 10px;
  background-color: #e1ecf8;
  border-radius: 50%;
  margin: 0 6px;
  line-height: 0;
  color: transparent;
  outline: none;
}
.flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-bottom: -5px;
}
.flag-au {
  background-position: -24px 0;
}
.flag-gb {
  background-position: -48px 0;
}
.flag-hk {
  background-position: 0 -24px;
}
.flag-nz {
  background-position: -24px -24px;
}
.flag-sg {
  background-position: -48px -24px;
}
.flag-us {
  background-position: 0 -48px;
}
.request-modal h3 {
  font-weight: 400 !important;
  padding: 20px 0 15px 0;
  font-size: 28px;
}
.request-modal h3 {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.request-modal .request-modal-content {
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
}
.request-modal .request-modal-content .tel-input {
  margin-bottom: 22px;
}
.sales-page-navigation {
  display: flex;
  align-items: center;
}
.sales-page-navigation nav {
  margin-right: 25px;
}
@media screen and (max-width: 1024px) {
  .sales-page-navigation nav {
    display: none;
  }
}
.sales-page-navigation .navigation-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.sales-page-navigation .navigation-item {
  padding: 19px 25px;
}
.sales-page-navigation .navigation-link {
  color: #7f8197;
}
.sales-page-navigation .navigation-link:hover {
  color: #64667b;
  text-decoration: none;
}
.sales-page-navigation .navigation-link:active {
  color: #0074bc;
}
@media screen and (min-width: 1200px) {
  .sales-page-navigation .navigation-link {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .sales-page-navigation .navigation__btn:hover {
    background-color: #0074bc !important;
  }
}
.sales-page-navigation .navigation__btn:hover span {
  color: #fff !important;
}
@media screen and (max-width: 560px) {
  .sales-page-navigation .navigation__btn span {
    font-size: 10px !important;
  }
}
.header--sales .sales-page-navigation {
  right: 0;
  transform: translateX(0%);
  transition: right 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 374px) {
  .header--sales .sales-page-navigation {
    padding-top: 15px;
  }
}
.header--sales nav {
  overflow: hidden;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1), height 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.header--sales nav .navigation-list {
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (min-width: 560px) {
  .header--sales.is-minified .sales-page-navigation {
    position: relative;
    right: 50%;
    transform: translateX(50%);
  }
}
.header--sales.is-minified nav {
  transform: scale(0.8);
  height: 0px;
  width: 0px;
  margin: 0;
}
.header--sales.is-minified nav .navigation-list {
  transform: translateY(-50%);
}
@media screen and (max-width: 560px) {
  .header--sales.is-minified .navigation__btn {
    min-width: 125px !important;
  }
}
@media screen and (min-width: 561px) {
  .header--sales.is-minified .navigation__btn {
    min-width: 150px !important;
  }
}
.ui-placeholder {
  margin-bottom: 1em;
}
.ui-placeholder__line {
  height: 0.8em;
  background-color: rgba(0,0,0,0.12);
  margin-bottom: 0.8em;
}
.card-field {
  position: relative;
}
.card-field label + .MuiInput-formControl {
  margin-top: 24px;
}
.card-field .StripeElement__wrapper {
  position: relative;
}
.card-field .StripeElement {
  width: 100%;
  min-height: 22px;
}
.card-field .StripeElement__wrapper--focus + div hr:last-child {
  transform: scaleX(1) !important;
}
.card-field__brand {
  height: 25px;
  width: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 30px;
}
.floating-link-widget__link {
  position: relative;
  margin-bottom: 24px;
}
.floating-link-widget__link:hover .floating-link-widget--close {
  opacity: 1;
}
.floating-link-widget--save-button {
  margin-left: 14px !important;
}
.floating-link-widget--close {
  position: absolute !important;
  top: 10px;
  right: 10px;
  opacity: 0;
}
.floating-link-widget--close button {
  width: 28px !important;
  height: 28px !important;
}
.floating-link-widget--close svg {
  width: 20px !important;
  height: 28px !important;
}
.application-policies-widget {
  margin-top: 10px;
  margin-bottom: 10px;
}
.application-policies-widget__link {
  position: relative;
  margin-bottom: 10px;
}
.application-policies-widget__link:hover .application-policies-widget--close {
  opacity: 1;
}
.application-policies-widget--close {
  position: absolute !important;
  top: 10px;
  right: 10px;
  opacity: 0;
}
.application-policies-widget--close button {
  width: 28px !important;
  height: 28px !important;
}
.application-policies-widget--close svg {
  width: 20px !important;
  height: 28px !important;
}
.badges {
  width: 100%;
  margin-bottom: 48px;
}
.badges-container,
.your-badges {
  width: 830px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
}
.badges-container__left-section,
.your-badges__left-section {
  width: 450px;
}
.badges-container__header,
.your-badges__header {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 17px 21px;
  background-color: #3886d1;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.badges-container--title,
.your-badges--title {
  text-transform: capitalize;
}
.badges-container__content-item,
.your-badges__content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.badges-container__content-description,
.your-badges__content-description {
  text-align: center;
}
.badges-container--content-header,
.your-badges--content-header {
  margin-top: 16px;
  margin-bottom: 0;
  color: #757575;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.badges-container--content-description,
.your-badges--content-description {
  margin-bottom: 0;
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.badges-container--points,
.your-badges--points {
  margin-top: 8px;
  margin-bottom: 0;
  color: #616161;
  font-size: 18px;
  font-weight: 500;
}
.badges-container--points-count,
.your-badges--points-count {
  margin-bottom: 0;
  color: #0074bc;
  font-size: 36px;
  font-weight: 400;
}
.badges-container__points-section,
.your-badges__points-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 360px;
  margin-bottom: 20px;
  padding: 16px;
}
.badges-container__loader-item,
.your-badges__loader-item {
  display: grid;
  padding: 16px 30px;
  text-decoration: none;
}
.badges-container--loader-number,
.your-badges--loader-number {
  color: #9e9e9e;
  font-size: 12px;
}
.badges-container--loader-avatar,
.your-badges--loader-avatar {
  width: 32px;
  height: 32px;
  margin-left: 12px;
  margin-right: 12px;
  overflow: hidden;
  border-radius: 50%;
}
.badges-container--loader-name,
.your-badges--loader-name {
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.badges-container--loader-points,
.your-badges--loader-points {
  margin-bottom: 0;
  padding-left: 63px;
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.badges-container__badge-icon-section,
.your-badges__badge-icon-section {
  display: flex;
  flex-wrap: wrap;
  max-width: 240px;
  margin-left: 70px;
}
.badges-container__badge-icon-section .wrapper-points-section,
.your-badges__badge-icon-section .wrapper-points-section {
  margin-bottom: 5px;
  cursor: pointer;
}
.all-badges {
  width: 770px;
  margin-left: auto;
  margin-right: auto;
}
.all-badges--header {
  margin-top: 16px;
  color: #616161;
  font-size: 24px;
  font-weight: 400;
}
.all-badges--content {
  width: 523px;
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.all-badges__badges-group {
  display: flex;
}
.all-badges__badges-group .wrapper-points-section:nth-child(2) {
  transform: translate(-52px);
}
.all-badges__badges-group .wrapper-points-section:nth-child(3) {
  transform: translate(-104px);
}
.all-badges__badges-group .wrapper-points-section:nth-child(4) {
  transform: translate(-156px);
}
.all-badges__badges-group .wrapper-points-section:nth-child(5) {
  transform: translate(-209px);
}
.all-badges--receive-data {
  margin-left: auto;
  color: #9e9e9e;
  font-size: 14px;
}
.all-badges__roles {
  display: flex;
  padding: 36px 80px;
  justify-content: space-between;
}
.all-badges__roles-item {
  margin-top: 24px;
}
.all-badges--roles-header-img {
  margin-left: 7px;
  margin-right: 20px;
}
.all-badges__your-badges-list .all-badges__roles-list-item {
  cursor: pointer;
}
.all-badges__your-badges-list .all-badges__roles-list-item:hover {
  background-color: rgba(0,0,0,0.039);
}
.all-badges__roles-list-item {
  display: flex;
  padding: 17px 21px;
}
.all-badges__roles-list-item:nth-child(n + 2) {
  border-top: 1px solid #eceff1;
}
.all-badges--roles-img {
  width: 60%;
}
.all-badges--roles-counter {
  font-size: 12px;
  margin-right: 6px;
  color: #000;
  text-align: center;
  margin-top: 4px;
}
.all-badges__roles-list-description {
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.all-badges--roles-list-header {
  margin: unset;
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.all-badges--roles-list-description {
  margin: unset;
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.wrap-points-section {
  position: relative;
  margin-right: 16px;
  margin-bottom: 8px;
}
.wrap-points-section:hover .wrapper-points-section__description {
  display: inline-block;
}
.wrap-points-section--min .close-wrapper {
  position: relative;
  background-color: rgba(206,206,208,0.3);
}
.wrap-points-section--min .close-wrapper:after {
  content: "";
  position: absolute;
  right: -4px;
  bottom: 0;
  width: 13px;
  height: 13px;
  background-image: url("../../assets/images/lock.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.wrapper-points-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 48px;
  width: 48px;
  max-width: 48px;
  min-width: 48px;
  margin-right: 10px;
  border-radius: 50%;
}
.wrapper-points-section__description {
  display: none;
  position: absolute;
  bottom: -34px;
  max-width: 100px;
  padding: 5px;
  font-size: 10px;
  background-color: rgba(0,0,0,0.5);
  color: #fff;
  z-index: 99;
}
.wrapper-small {
  height: 30px;
  width: 30px;
  max-width: unset;
  min-width: 24px;
}
.core-points-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83%;
  width: 83%;
  border-radius: 50%;
}
.tin-wrapper {
  background-color: #e1ecf8;
}
.tin-core {
  background-color: #c3daf1;
}
.bronze-wrapper {
  background-color: #ffd8a5;
}
.bronze-core {
  background-color: #edb86e;
}
.silver-wrapper {
  background-color: #e7e8ea;
}
.silver-core {
  background-color: #ceced0;
}
.gold-wrapper {
  background-color: #fbdb54;
}
.gold-core {
  background-color: #debb2c;
}
.platinum-wrapper {
  background-color: #bcc1c5;
}
.platinum-core {
  background-color: #a2a7aa;
}
.close-wrapper {
  position: relative;
  background-color: rgba(206,206,208,0.3);
}
.close-wrapper:after {
  content: '';
  position: absolute;
  right: -4px;
  bottom: 3px;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/lock.svg");
}
.close-core {
  background-color: rgba(206,206,208,0.5);
}
@media screen and (max-width: 1124px) {
  .badges-container,
  .your-badges {
    flex-direction: column;
    max-width: 830px;
    width: 100%;
  }
  .badges-container__left-section,
  .your-badges__left-section {
    width: 100%;
    margin-bottom: 24px;
  }
  .badges-container__right-section,
  .your-badges__right-section {
    width: 100%;
  }
  .badges-container__badge-icon-section,
  .your-badges__badge-icon-section {
    max-width: 100%;
  }
  .badges-container__points-section,
  .your-badges__points-section {
    width: 100%;
  }
  .all-badges {
    max-width: 770px;
    width: 100%;
  }
  .all-badges__header {
    height: auto;
  }
  .all-badges--content {
    max-width: 523px;
    width: 100%;
  }
  .all-badges__roles-list-item {
    padding: 17px 20px;
  }
  .all-badges--roles-header-img {
    width: 26px;
    height: 26px;
  }
}
@media screen and (max-width: 768px) {
  .your-badges__header {
    height: auto;
    padding: 17px 24px;
  }
  .your-badges__header p {
    margin: 0;
  }
  .your-badges__loader-item {
    padding: 16px 26px;
    padding-bottom: 0;
  }
  .your-badges__loader-item:last-child {
    padding-bottom: 16px;
  }
  .all-badges__roles-list-item {
    padding: 17px 20px;
  }
  .all-badges__roles {
    padding: 30px;
  }
  .all-badges--roles-header-img {
    width: 26px;
    height: 26px;
  }
}
@media screen and (max-width: 560px) {
  .all-badges__roles {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
  }
  .all-badges__roles .your-badges__content-item {
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .all-badges--receive-data {
    text-align: right;
    width: 120px;
    min-width: 120px;
  }
}
.leaderboard__badges-cell .MuiBadge-anchorOriginTopRightCircle,
.all-badges__your-badges-list .MuiBadge-anchorOriginTopRightCircle,
.yourProgress_leaderboard_badges .MuiBadge-anchorOriginTopRightCircle,
.all-badges__roles-list-item_badge--center .MuiBadge-anchorOriginTopRightCircle {
  top: 0;
  right: 50%;
}
.reports {
  width: 100%;
  margin-bottom: 48px;
}
.reports .reports-container {
  margin-bottom: 5px;
}
.report-blur-image {
  background: url("../../assets/images/review-blur-image.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: calc(100% + 48px);
  min-height: 600px;
}
.rating-blur-image {
  background: url("../../assets/images/rating-blur-image.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 600px;
}
.ai-budget-blur-image {
  background: url("../../assets/images/ai-budget-blr-image.webp") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 600px;
}
.talent-alert-blur-image {
  background: url("../../assets/images/talent-alert-blur-image.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 600px;
}
.consultant-box {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #f8f8f8;
  height: 100%;
}
.consultant-boxes {
  max-height: 850px;
  overflow-y: scroll;
  padding-right: 20px;
}
.consultant-boxes .pagination-wrap {
  margin-top: 10px;
}
.consultant-boxes .pagination-wrap .MuiPaginationItem-root {
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.recruiter-header h5 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #3886d1;
  margin: 0;
  line-height: 30px;
  text-transform: uppercase;
}
.recruiter-box {
  display: block;
  width: 255px;
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
  background-color: #fff;
  box-shadow: 14px 0px 15px rgba(234,234,234,0.2);
}
.select-recruiter-button {
  display: none;
  width: 8%;
}
.select-recruiter-button .MuiButton-startIcon {
  margin-right: 0;
}
.recruiter-input {
  position: relative;
  display: block;
  width: 100%;
  background-color: #f9f9f9;
  border: 0.5px solid #d9d9d9;
  font-size: 12px;
  font-weight: 300;
  line-height: 30px;
  color: #9f9fa1;
  letter-spacing: 0.3px;
  padding: 2px 15px;
  padding-left: 30px;
  border-radius: 2px;
  transition: 0.5s all ease;
}
.search-box {
  position: relative;
  display: block;
  width: 100%;
}
.search-icon {
  position: absolute;
  display: block;
  width: fit-content;
  top: 6px;
  left: 5px;
  color: #9f9fa1;
}
.found-box {
  position: relative;
  width: 100%;
  align-items: center;
  margin: 5px 0;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 20px;
}
.recruiter-header {
  padding-left: 15px;
  padding-right: 20px;
}
.found-title h5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #3886d1;
  margin: 0;
}
.found-title {
  display: block;
  width: 100%;
  min-height: 30px;
}
.found-link {
  display: block;
  width: 100%;
}
.found-link a {
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #757575;
  border: 0.2px solid #404144;
  padding: 4px 10px;
  text-decoration: none;
}
.found-link .dropdown-item {
  border: none;
  font-size: 14px;
}
.conslt-box {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 6px 0;
  border-left: 3px solid transparent;
  padding-left: 20px;
  border-bottom: 0.5px solid #d9d9d9;
  text-decoration: none;
  transition: 0.5s all ease;
}
.consultant-cont img {
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}
.consultant-cont .img-fluid-circle,
.graph-tab-header .img-fluid-circle {
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: inline-block;
  background-color: #ccc;
  vertical-align: middle;
  border-style: none;
}
.consultant-cont {
  display: block;
  width: 85%;
}
.consultant-arrow {
  display: block;
  width: 15%;
  text-align: right;
}
.consultant-cont {
  display: block;
  width: 85%;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #2e364b;
}
.conslt-box:hover,
.conslt-box.active {
  background: transparent linear-gradient(90deg, rgba(162,210,255,0.341) 0%, rgba(255,255,255,0) 71%, #fff 100%) 0% 0% no-repeat padding-box;
  border-left: 3px solid #3886d1;
  text-decoration: none;
  transition: 0.5s all ease;
}
.consultant-area {
  position: relative;
  display: block;
  width: calc(100% - 255px);
}
.dashboard-graph-tab {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eceff1;
  padding: 10px 30px;
  height: 61px;
}
.graph-tab-header {
  display: block;
  width: 25%;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #2e364b;
}
.graph-tab {
  display: block;
  width: 75%;
}
.dashboard-graph {
  position: relative;
  display: block;
  width: 85%;
  background-color: #fff;
  margin: 20px;
  box-shadow: 0 0 15px rgba(234,234,234,0.6);
}
.graph-tab-header img {
  border-radius: 50%;
  margin-right: 15px;
  width: 40px;
  height: 40px;
}
.graph-tab .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.graph-tab ul li span.nav-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.28px;
  color: #9f9fa1;
  padding: 0;
  margin: 0 16px;
}
.graph-tab ul li span.nav-link.active {
  background-color: transparent;
  color: #3886d1;
}
.graph-tab ul li span.nav-link.active:after {
  position: absolute;
  display: block;
  content: '';
  border-top: 1px solid #3886d1;
  width: 100%;
  bottom: 0;
  left: 0;
}
.graph-area {
  min-height: 450px;
}
.graph-tab ul li.nav-item {
  position: relative;
  padding: 15px 0px;
  cursor: pointer;
}
.chart-box {
  width: 75%;
  display: block;
  position: relative;
  margin: 10px auto 0 auto;
}
.avg-rating-star ul li {
  position: relative;
  display: inline-block;
  color: #ffbf00;
}
.avg-rating-star ul {
  list-style: none;
  margin: 0;
}
.rate-cont {
  display: block;
  width: 50%;
  align-self: flex-end;
}
.avg-rating-star {
  display: block;
}
.avg-rating-box {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}
.rating-link {
  display: flex;
  width: 30%;
  text-align: right;
  justify-content: flex-end;
}
.rating-link-left {
  margin-right: 40px;
  text-align: left;
}
.filter-date-picker-block {
  width: 100%;
  display: flex;
  align-items: center;
}
.custom-date-picker .filter-date-picker {
  width: 45%;
  overflow: hidden;
  margin-left: 5%;
}
.filter-date-button {
  margin-top: 5px;
}
.rating-cont {
  display: block;
  width: 70%;
  align-self: flex-start;
}
.rating-box {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 30px;
}
.avg-rating-star h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #949496;
  margin-bottom: 0;
}
.avg-rating-star .widget-ratings {
  margin-top: 3px;
}
.rate-cont p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  color: #35a28a;
  letter-spacing: 0.5px;
}
.rating-link a {
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #757575;
  border: 0.2px solid #404144;
  padding: 4px 10px;
  text-decoration: none;
}
.rating-link .dropdown {
  display: inline-block;
  min-width: 140px;
  text-align: left;
}
.avg-rat p {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  color: #9f9fa1;
  letter-spacing: 0.5px;
}
.avg-rat {
  position: absolute;
  display: block;
  width: fit-content;
  top: 50%;
  left: 3%;
  transform: rotateZ(-91deg);
}
.avg-rat.Months {
  position: relative;
  display: block;
  width: 100%;
  transform: rotateZ(0deg);
  text-align: center;
  padding-bottom: 30px;
  margin-top: 10px;
}
.rat-dot {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 4px solid #6bb129;
  margin: 0 auto;
  background: #fff;
}
.rating-dot {
  position: absolute;
  display: none;
  width: fit-content;
  text-align: center;
  top: -45px;
  left: 50%;
}
.progress:hover .rating-dot {
  display: block;
}
.rat-flag p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin: 0;
  color: #404144;
}
.rat-flag p span {
  font-size: 12px;
  font-weight: 400;
  color: #9f9fa1;
}
.rat-flag {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(105,105,105,0.231);
  border-radius: 4px;
}
.rat-flag:after {
  position: absolute;
  display: block;
  content: '';
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-top: 15px solid #fff;
  left: 40px;
  bottom: -23px;
}
.dashboard-graph-tab h5 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0;
  color: #404144;
}
.overview-box {
  position: relative;
  display: flex;
  width: 85%;
  margin: 20px;
}
.avg-rating-star .star-cont {
  color: #404144;
  margin-left: 10px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}
.rating-overview {
  display: block;
  width: 50%;
  margin-right: 30px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(234,234,234,0.6);
}
.cloud-review {
  display: block;
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(234,234,234,0.6);
}
.rating-overview .avg-rating-box {
  padding: 0 30px;
  margin-top: 10px;
}
.rating-overview .avg-rating-star {
  width: 50%;
}
.rating-overview .rate-cont p {
  text-align: right;
}
.toprec-feedback.consultant-box {
  background-color: #fff;
}
.toprec-feedback.consultant-box .dashboard-graph {
  box-shadow: none;
  width: calc(100% - 40px);
}
.toprec-feedback.consultant-box .recruiter-box {
  overflow: hidden;
}
.toprec-feedback.consultant-box .consultant-boxes {
  max-height: inherit;
  height: 100%;
  overflow-y: auto;
}
.toprec-feedback.consultant-box .select-recruiter-button {
  width: inherit;
}
.progress-title {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0 0 20px;
}
.progress {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.75rem;
  height: 10px;
  background: #eeeff3;
  border-radius: 12px;
  box-shadow: none;
  margin-bottom: 0px;
  overflow: visible;
  width: 70%;
}
.progress .progress-bar {
  position: relative;
  -webkit-animation: animate-positive 2s;
  animation: animate-positive 2s;
  border-radius: 12px 0 0 12px;
}
.progress .progress-value {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  position: relative;
}
.progressbar {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 30px;
  align-items: center;
}
.progress-value {
  display: block;
  width: 15%;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #9f9fa1;
}
.progressbar-box {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 0 30px 0;
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
.anychart-credits {
  display: none !important;
}
.cloud-box {
  position: relative;
  display: block;
  width: 100%;
  height: 80%;
}
.toprec-box {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 60px;
  text-align: center;
}
.toprec-cont h5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #2e364b;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}
.toprec-cont p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  color: #9f9fa1;
  letter-spacing: 0.2px;
}
.toprec .modal-header {
  padding: 0;
  border: none;
}
.recruiter-header-close {
  display: none;
}
.recruiter-header-close .MuiButton-startIcon {
  margin-right: 0;
}
.toprec {
  background-color: rgba(56,134,209,0.2);
  backdrop-filter: blur(8px);
  width: 86%;
  margin-left: auto;
}
.nav-pills-dropdown {
  display: none;
}
@media screen and (max-width: 1330px) {
  .graph-tab-header {
    width: 30%;
  }
  .graph-tab {
    width: 70%;
  }
  .graph-tab ul li span.nav-link {
    margin: 0 12px;
  }
}
@media screen and (max-width: 1190px) {
  .graph-tab-header {
    width: 35%;
  }
  .graph-tab {
    width: 65%;
  }
}
@media screen and (max-width: 1295px) {
  .avg-rat {
    left: 0;
  }
  .overview-box {
    display: block;
  }
  .rating-overview {
    width: 100%;
  }
  .cloud-review {
    width: 100%;
    margin-top: 20px;
  }
  .graph-tab ul li span.nav-link {
    margin: 0 5px;
  }
}
@media screen and (max-width: 1200px) {
  .recruiter-box {
    display: none;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    padding-top: 10px;
  }
  .graph-tab-header {
    width: 30%;
  }
  .graph-tab ul li span.nav-link {
    margin: 0 8px;
  }
  .recruiter-header-close {
    display: block;
    text-align: right;
    margin-right: 10px;
  }
  .select-recruiter-button {
    display: block;
  }
  .graph-tab {
    width: 62%;
  }
  .consultant-area {
    width: 100%;
  }
  .dashboard-graph {
    width: calc(100% - 48px);
    margin: 24px;
  }
  .overview-box {
    width: calc(100% - 48px);
    margin: 24px;
  }
}
@media screen and (max-width: 602px) {
  .select-recruiter-button {
    width: 52px;
  }
  .graph-tab-header {
    width: calc(100% - 237px);
  }
  .graph-tab {
    width: 185px;
  }
  .graph-tab .nav {
    display: none;
  }
  .graph-tab .nav-pills-dropdown {
    display: flex;
  }
  .avg-rat {
    left: -15px;
  }
}
@media screen and (max-width: 530px) {
  .graph-tab-header {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .select-recruiter-button {
    width: 52px;
  }
  .graph-tab-header {
    width: calc(100% - 237px);
  }
  .graph-tab {
    width: 185px;
  }
  .graph-tab .nav {
    display: none;
  }
  .graph-tab .nav-pills-dropdown {
    display: flex;
  }
  .avg-rat {
    left: -15px;
  }
  .avg-rating-box,
  .avg-rating-star,
  .rate-cont,
  .rating-overview .avg-rating-star {
    display: block;
    width: 100%;
  }
  .rating-overview .rate-cont p {
    text-align: left;
  }
}
@media screen and (max-width: 600px) {
  .graph-tab-header {
    display: none;
  }
  .rating-box,
  .rating-cont,
  .avg-rating-box,
  .avg-rating-star,
  .rate-cont,
  .rating-overview .avg-rating-star,
  .rating-link .dropdown,
  .rating-link {
    display: block;
    width: 100%;
  }
  .avg-rat {
    left: -40px;
    top: 65%;
  }
  .rating-overview .rate-cont p {
    text-align: left;
  }
  .progress-value {
    width: 25%;
  }
  .dashboard-graph-tab h5 {
    font-size: 15px;
  }
  .graph-area {
    min-height: 352px;
  }
  .chart-box {
    margin: 35px auto 0 auto;
  }
  .dashboard-graph-tab {
    padding: 10px 15px;
  }
}
@-moz-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@-o-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.dashboard-report-container {
  background-color: #f8f8f8;
}
.dashboard-report {
  margin: 20px 0;
}
.dashboard-report .toggle-button-group .MuiToggleButtonGroup-grouped {
  border: 0;
  border-radius: 5px;
  min-width: 110px;
}
.dashboard-report .toggle-button-group .MuiToggleButtonGroup-grouped.Mui-selected {
  background-color: #fff;
}
.dashboard-report .toggle-button-group .MuiToggleButtonGroup-grouped:not(.Mui-selected) {
  color: #7b7b7b !important;
}
.dashboard-report-filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.dashboard-report-filter-type {
  display: flex;
  background-color: #ebebeb !important;
  flex-wrap: wrap;
  max-width: 229px;
  margin-right: 20px;
  border-radius: 5px !important;
}
.dashboard-report-filter-date {
  max-width: 480px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.dashboard-report-filter-date .MuiFormHelperText-root {
  margin: 0;
}
.dashboard-report-filter-billing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.dashboard-report-filter-billing-value {
  background-color: #ebebeb !important;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 3px;
}
.dashboard-report .dashboard-chart-wrapper {
  margin-top: 15px;
}
.dashboard-report .dashboard-chart-wrapper h3 {
  letter-spacing: 0.48px;
  color: #2e364b;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 2px;
}
.dashboard-report .dashboard-chart-wrapper .dashboard-chart-data {
  padding: 70px 15px 15px 15px;
  min-height: 354px;
  position: relative;
}
.dashboard-report .dashboard-chart-wrapper .dashboard-chart-data .dashboard-chart-data-summary {
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.051);
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 5px 10px;
}
.feedback-page .table-wrap .table tbody .review-star {
  padding: 15px 12px 15px 24px;
}
.response-box {
  border: 1px solid #ececec;
  padding: 10px 20px;
}
.feedback-page-modal .excellent-box {
  position: relative;
  display: block;
  width: 100%;
  background-color: #fcfcfc;
  border: 1px solid #ececec;
}
.feedback-page-modal .excellent-box .excellent-rating {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 20px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star {
  display: block;
  width: 70%;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul {
  padding: 0;
  list-style: none;
  margin: 0;
  display: inline-block;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li {
  font-size: 22px;
  margin: 0 1px;
  position: relative;
  display: inline-block;
  width: fit-content;
  font-size: 26px;
  color: #ffc107;
  margin: 0 2px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li.by {
  color: #d9d9d9;
  font-weight: 400;
  margin: 0 5px;
  font-size: 20px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li.Bruce {
  color: #515151;
  font-weight: 500;
  font-size: 20px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li.range {
  color: #35a28a;
  font-weight: 500;
  font-size: 18px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li span {
  font-size: 16px;
  color: #515151;
  font-weight: 500;
  letter-spacing: 0.5px;
  background-color: #f2f2f2;
  padding: 2px 10px;
  margin-left: 5px;
}
.feedback-page-modal .excellent-box .excellent-rating .excelent-time {
  display: block;
  width: 30%;
  text-align: right;
}
.feedback-page-modal .excellent-box .excellent-rating .excelent-time p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: #9f9fa1;
  letter-spacing: 0.5px;
}
.feedback-page-modal .excellent-box .excelent-cont {
  padding: 0 20px;
}
.feedback-page-modal .excellent-box .excelent-cont h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: #515151;
  letter-spacing: 0.5px;
}
.feedback-page-modal .excellent-box .excelent-cont p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #828282;
  word-break: break-word;
  overflow: hidden;
}
.feedback-page-toggle-publish {
  padding: 0 20px;
  display: inline-block;
  text-align: left;
}
.feedback-page-comment {
  padding: 0 20px;
}
.feedback-page-comment-respond {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  color: #515151;
}
.feedback-page-comment-respond svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.feedback-page-comment-respond span {
  text-transform: uppercase;
  font-size: 14px;
}
.feedback-page-comment-response {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  justify-content: space-between;
  color: #515151;
  font-size: 14px;
}
@media screen and (max-width: 550px) {
  .feedback-page-comment-response-public {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .feedback-page-comment-response-private {
    width: 100%;
  }
}
.feedback-page-comment-response-box {
  min-width: 370px;
}
@media screen and (max-width: 550px) {
  .feedback-page-comment-response-box {
    min-width: auto;
  }
}
.feedback-page-comment-response-box .MuiTextField-root {
  border: 1px solid #ececec;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #f6f6f5;
  margin-top: 5px;
}
.feedback-page-comment-response-box-social .MuiIconButton-root {
  padding: 5px;
}
.feedback-page-comment-response-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feedback-page-comment-response-title-icon {
  height: 20px;
}
.feedback-page-comment-response-title-icon svg {
  width: 20px;
  height: 20px;
}
.comment-box {
  margin-bottom: 15px;
}
.comment-box-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 15px;
}
.comment-box-inner-box {
  padding-left: 10px;
}
.comment-box-inner-box-title {
  color: #515151;
  font-weight: 500;
  font-size: 16px;
}
.comment-box-inner-box-response .MuiTextField-root {
  border: 1px solid #ececec;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #f6f6f5;
  margin-top: 5px;
}
.comment-box-buttons {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal-comment-box .comment-box-inner {
  padding-left: 0;
}
.refari-placements-wrapper .table {
  width: 100%;
}
.refari-placements-wrapper .modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
}
.refari-placements-wrapper .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.refari-placements-wrapper .modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.refari-placements-wrapper .modal-body.crm-status-body {
  padding: 0 1rem 1rem 1rem;
}
.refari-placements-wrapper .modal-body .crm-status-no-data-found {
  margin: 10px 0;
  text-align: center;
}
.refari-placements-wrapper .modal-body .crm-status-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 20px;
}
.refari-placements-wrapper .modal-body .crm-status-list-li {
  flex: 0 1 40%;
  box-sizing: border-box;
  width: 100%;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-name {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 15px;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-name-title {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.2px;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-name-help {
  display: flex;
  padding-left: 10px;
  align-items: center;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-ul-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.refari-placements-wrapper .modal-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  color: #404144;
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 0;
}
.refari-placements-wrapper .modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  border: 0;
  background: transparent;
}
.refari-placements-wrapper button.close {
  position: absolute;
  display: block;
  right: 15px;
  top: 10px;
  z-index: 5;
  outline: none;
  font-size: 30px;
}
.refari-placements-wrapper .close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.refari-placements-wrapper .setting-tab .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.refari-placements-wrapper .setting-tab .nav-link {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
}
.refari-placements-wrapper .setting-tab ul li.nav-item {
  width: 25%;
  text-align: center;
  cursor: pointer;
}
.refari-placements-wrapper .setting-tab ul li.nav-item .nav-link {
  font-size: 14px;
  line-height: 30px;
  color: #404144;
  letter-spacing: 0.3px;
  border: 1px solid #ececec;
  border-radius: 0;
}
.refari-placements-wrapper .setting-tab ul li.nav-item .nav-link.active {
  background-color: transparent;
  color: #3886d1;
  border-bottom: none;
  border-top: 2px solid #3886d1;
}
.refari-placements-wrapper .setting-title h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #404144;
  margin: 0;
}
.refari-placements-wrapper .setting-title h5 span {
  color: #3886d1;
}
.refari-placements-wrapper .setting-title {
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.refari-placements-wrapper .table thead th {
  vertical-align: middle;
  border-bottom: 1px solid #ececec;
  border-top: none;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #9f9fa1;
  padding: 0 12px;
}
.refari-placements-wrapper .setting-cont .table input {
  float: left;
  margin-top: 4px;
  margin-right: 16px;
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
}
.refari-placements-wrapper .Donald {
  position: relative;
  display: inline-block;
  width: 30px;
  margin-right: 10px;
}
.refari-placements-wrapper .Donald img {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.refari-placements-wrapper .Donald .img-fluid-circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: #ccc;
  vertical-align: middle;
  border-style: none;
}
.refari-placements-wrapper .page-preload-spinner-table {
  border-bottom: 0 !important;
  text-align: center !important;
}
.refari-placements-wrapper .hnt-img {
  float: right;
  cursor: pointer;
}
.refari-placements-wrapper .pagination-wrap {
  margin-top: 15px;
}
.refari-placements-wrapper .setting-cont .table td {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #2e364b;
  padding: 10px 10px;
  max-width: 150px;
}
.refari-placements-wrapper .setting-cont .table td label {
  margin: 0;
}
.refari-placements-wrapper .setting-cont .table tr {
  border-bottom: 1px solid #ececec;
}
.refari-placements-wrapper .setting-cont .table-responsive {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}
.refari-placements-wrapper .setting-btn {
  margin: 20px 0;
  text-align: center;
}
.refari-placements-wrapper .setting-btn .align-items-center {
  padding-bottom: 20px;
}
.refari-placements-wrapper .popover-body {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.28px;
  color: #757575;
  padding: 15px;
}
.refari-placements-wrapper .popover {
  box-shadow: 0 0 15px rgba(0,0,0,0.14);
  border: none;
}
.refari-placements-wrapper button.close {
  position: absolute;
  display: block;
  right: 15px;
  top: 10px;
  z-index: 5;
  outline: none;
  font-size: 30px;
}
.refari-placements-wrapper .setting-cont .table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}
.refari-placements-wrapper .setting-cont .table-responsive::-webkit-scrollbar {
  width: 8px;
  background-color: #cfcfcf;
}
.refari-placements-wrapper .setting-cont .table-responsive::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #cfcfcf;
}
.refari-placements-wrapper .round {
  position: relative;
  display: flex;
  align-items: center;
}
.refari-placements-wrapper .round.disabledCheckbox svg {
  opacity: 0.5 !important;
}
.refari-placements-wrapper .round > div {
  height: 20px !important;
  width: 20px !important;
}
.modal-container-without-left .refari-modal > div {
  border-radius: 5px !important;
}
.modal-container-without-left .refari-modal > div > div {
  overflow-x: hidden;
}
.tooltip-container {
  background-color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 15px rgba(0,0,0,0.14);
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
  padding: 0.4rem;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  transition: opacity 0.3s;
  z-index: 2147483647;
}
.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
}
.tooltip-arrow::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}
.tooltip-arrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}
.tooltip-arrow[data-placement*='bottom'] {
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;
}
.tooltip-arrow[data-placement*='bottom']::before {
  border-color: transparent transparent #c0c0c0 transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}
.tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent #fff transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}
.tooltip-arrow[data-placement*='top'] {
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;
}
.tooltip-arrow[data-placement*='top']::before {
  border-color: #c0c0c0 transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}
.tooltip-arrow[data-placement*='top']::after {
  border-color: #fff transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}
.tooltip-arrow[data-placement*='right'] {
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;
}
.tooltip-arrow[data-placement*='right']::before {
  border-color: transparent #c0c0c0 transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}
.tooltip-arrow[data-placement*='right']::after {
  border-color: transparent #fff transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}
.tooltip-arrow[data-placement*='left'] {
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;
}
.tooltip-arrow[data-placement*='left']::before {
  border-color: transparent transparent transparent #c0c0c0;
  border-width: 0.5rem 0 0.5rem 0.4em;
}
.tooltip-arrow[data-placement*='left']::after {
  border-color: transparent transparent transparent #fff;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
.tooltip-body {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.28px;
  color: #757575;
  padding: 15px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .refari-placements-wrapper .setting-cont .table-responsive {
    overflow: auto;
  }
}
.refari-phone-code-body {
  padding: 0 4rem 2rem !important;
}
@media screen and (max-width: 480px) {
  .refari-phone-code-body {
    padding: 1rem 0 !important;
  }
}
.about-page-section-navigation {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .about-page-section-navigation .navigation__btn:hover {
    background-color: #0074bc !important;
  }
}
.about-page-section-navigation .navigation__btn:hover span {
  color: #fff !important;
}
@media screen and (max-width: 560px) {
  .about-page-section-navigation .navigation__btn span {
    font-size: 10px !important;
  }
}
.header--sales .sales-page-navigation {
  right: 0;
  transform: translateX(0%);
  transition: right 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 374px) {
  .header--sales .sales-page-navigation {
    padding-top: 15px;
  }
}
@media screen and (min-width: 560px) {
  .header--sales.is-minified .sales-page-navigation {
    position: relative;
    right: 50%;
    transform: translateX(50%);
  }
}
@media screen and (max-width: 560px) {
  .header--sales.is-minified .navigation__btn {
    min-width: 125px !important;
  }
}
@media screen and (min-width: 561px) {
  .header--sales.is-minified .navigation__btn {
    min-width: 150px !important;
  }
}
.about-page-section {
  width: 100%;
  margin-top: 80px;
  position: relative;
  overflow: hidden;
  color: #24253d;
}
.about-page-section .about-page-section-header {
  height: 600px;
  position: relative;
  background-image: url("../../assets/images/integration_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.about-page-section .about-page-section-header .section-conversion__bg {
  display: none;
}
@media screen and (min-width: 768px) {
  .about-page-section .about-page-section-header .section-conversion__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/images/sales-page_users-illustration.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    transform: translate(0, 60%);
    transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    opacity: 0;
  }
}
@media screen and (min-width: 768px) {
  .about-page-section .about-page-section-header.a-on-enter .section-conversion__bg {
    transform: translate(0, 0%);
    opacity: 1;
  }
}
.about-page-section .about-page-section-header.a-on-enter .a-bottom-enter {
  transform: translate(0, 0%);
  opacity: 1;
}
.about-page-section .about-page-section-header__flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-page-section .about-page-section-header--header {
  max-width: 686px;
  font-size: 36px;
  font-weight: 300;
  line-height: 54px;
}
.about-page-section .about-page-section-header--content {
  margin-top: 27px;
  margin-bottom: 60px;
  max-width: 600px;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}
.about-page-section .about-page-section-recruitment {
  margin-top: 120px;
  margin-bottom: 120px;
}
.about-page-section .about-page-section-recruitment__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: all 1s;
  transform: translate(0, 50%);
  opacity: 0;
}
.about-page-section .about-page-section-recruitment--header {
  margin-bottom: 120px;
  max-width: 600px;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
}
.about-page-section .about-page-section-recruitment__content {
  display: flex;
  justify-content: space-between;
  width: 65%;
}
.about-page-section .about-page-section-recruitment--content {
  margin-bottom: 0;
}
.about-page-section .about-page-section-recruitment__content-left-first {
  margin-bottom: 40px;
}
.about-page-section .about-page-section-recruitment__content-left {
  max-width: 430px;
  text-align: left;
  transition: all 1s;
  transform: translate(-50%, 0);
  opacity: 0;
}
.about-page-section .about-page-section-recruitment__content-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 30px;
  transition: all 1s;
  transform: translate(50%, 0);
  opacity: 0;
}
.about-page-section .about-page-section-recruitment--header-content {
  margin-top: 16px;
  margin-bottom: 0;
  color: #24253d;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
}
.about-page-section .about-page-section-recruitment--content {
  max-width: 320px;
  color: #24253d;
  font-size: 16px;
  line-height: 32px;
}
.about-page-section .flex-column-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-page-section .about-page-section-contact {
  margin-top: 135px;
  background-color: #f9fbfe;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 532px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.about-page-section .about-page-section-contact__icon {
  background-image: url("../../assets/images/ultimate_tool.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: all 1s;
  width: 48px;
  height: 48px;
  margin: 0 auto 36px;
  transform: scale(0);
}
.about-page-section .about-page-section-contact--header {
  max-width: 455px;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .about-page-section-contact--content {
  margin-top: 17px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
.about-page-section .about-page-section-contact--link {
  text-decoration: none;
  color: #4e77ff;
  font-size: 24px;
  line-height: 40px;
}
.about-page-section .about-page-section-contact__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
}
.about-page-section .integration-page-footer__container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background-color: #0074bc;
  transition: all 1s;
  opacity: 0;
}
.about-page-section .integration-page-footer__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-page-section .integration-page-footer--header {
  margin-bottom: 56px;
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .integration-page-footer--input {
  width: 430px !important;
}
.about-page-section .about-page-section-technology {
  padding-top: 129px;
  padding-bottom: 120px;
  position: relative;
}
.about-page-section .about-page-section-technology--header {
  max-width: 570px;
  color: #24253d;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .about-page-section-technology__check-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 36px;
}
.about-page-section .about-page-section-technology__check-item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.about-page-section .about-page-section-technology--content {
  margin: 0 0 0 14px;
}
.about-page-section .about-page-section-technology .section-conversion__bg {
  display: none;
}
@media screen and (min-width: 768px) {
  .about-page-section .about-page-section-technology .section-conversion__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/images/sales-page_users-illustration.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    transform: translate(0, 60%);
    transition: all 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    opacity: 0;
  }
}
@media screen and (min-width: 768px) {
  .about-page-section .about-page-section-technology.a-on-enter .section-conversion__bg {
    transform: translate(0, 0%);
    opacity: 1;
  }
}
.about-page-section .about-page-section-technology.a-on-enter .a-bottom-enter {
  transform: translate(0, 0%);
  opacity: 1;
}
.about-page-section .about-page-section-vision {
  margin-bottom: 120px;
}
.about-page-section .about-page-section-vision__icon {
  background-image: url("../../assets/images/ultimate_tool.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: all 1s !important;
  width: 48px;
  height: 48px;
  margin: 0 auto 36px;
  transform: scale(0);
}
.about-page-section .about-page-section-vision__paper {
  transition: all 1s cubic-bezier(0.39, 0.575, 0.565, 1) !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 64px !important;
}
.about-page-section .about-page-section-vision--header {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
}
.about-page-section .about-page-section-vision--content {
  max-width: 615px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
}
.about-page-section .about-page-section-values {
  background-color: #f9fbfe;
  padding: 120px 0 0;
}
.about-page-section .about-page-section-values__values-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
}
.about-page-section .about-page-section-values__values {
  height: 289px;
  width: 370px;
  border-radius: 6px;
  transition: all 1s;
  transform: translate(0, 91px);
  opacity: 0;
}
.about-page-section .about-page-section-values--values-img {
  margin-bottom: 36px;
}
.about-page-section .about-page-section-values--values-header {
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .about-page-section-values--values-content {
  font-size: 16px;
  line-height: 32px;
}
.about-page-section .about-page-section-values__paper {
  max-width: 370px;
  padding: 40px 36px 30px;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values__paper:nth-child(2) {
  transform: translate(0, 200px);
  opacity: 0;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values__paper:nth-child(3) {
  transform: translate(0, 0);
  opacity: 0;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values__paper:nth-child(4) {
  transform: translate(0, 200px);
  opacity: 0;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values__paper:nth-child(5) {
  transform: translate(0, 0);
  opacity: 0;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values--paper-img {
  margin-bottom: 35px;
}
.about-page-section .about-page-section-values--paper-header {
  color: #24253d;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}
.about-page-section .about-page-section-values--paper-content {
  color: #24253d;
  font-size: 16px;
  line-height: 32px;
}
.about-page-section .about-page-section-company {
  margin-top: 100px;
}
.about-page-section .about-page-section-company__company-section {
  min-width: 780px;
  min-height: 300px;
  padding-top: 110px;
  padding-bottom: 120px;
  background: url("../../assets/images/mapReferrer.svg") no-repeat right;
}
.about-page-section .about-page-section-company--header {
  padding-bottom: 46px;
  color: #24253d;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .about-page-section-company--content {
  color: #24253d;
  font-size: 16px;
  line-height: 32px;
}
.about-page-section .about-page-section-company__list-company {
  display: flex;
  flex-wrap: wrap;
  max-width: 640px;
  margin-left: -10px;
}
.about-page-section--animation {
  transition: transform 1s, opacity 1s;
  transform: translate(0, 20vh);
  opacity: 0;
}
.about-page-section .a-on-enter .about-page-section-recruitment__section,
.about-page-section .a-on-enter .about-page-section-recruitment__content-left,
.about-page-section .a-on-enter .about-page-section-recruitment__content-right,
.about-page-section .a-on-enter .about-page-section--animation,
.about-page-section .a-on-enter .integration-page-footer__container {
  transform: translate(0, 0);
  opacity: 1;
}
.about-page-section .a-on-enter .about-page-section-contact__icon,
.about-page-section .a-on-enter .about-page-section-vision__icon {
  transform: scale(1);
}
.about-page-section .a-on-enter .about-page-section-values {
  transition: all 1.5s;
}
.about-page-section .a-on-enter .about-page-section-values__values {
  transform: translate(0, 0);
  opacity: 1;
  transition: all 0.5s !important;
}
.about-page-section .a-on-enter .about-page-section-values__paper:nth-child(2) {
  transform: translate(0, 80px);
  opacity: 1;
}
.about-page-section .a-on-enter .about-page-section-values__paper:nth-child(3) {
  transform: translate(0, -70px);
  opacity: 1;
}
.about-page-section .a-on-enter .about-page-section-values__paper:nth-child(4) {
  transform: translate(0, 115px);
  opacity: 1;
}
.about-page-section .a-on-enter .about-page-section-values__paper:nth-child(5) {
  transform: translate(0, -115px);
  opacity: 1;
}
@media screen and (max-width: 991px) {
  .about-page-section {
    margin-top: 60px;
  }
  .about-page-section .about-page-section-recruitment {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .about-page-section .about-page-section-recruitment--header {
    margin-bottom: 50px;
    text-align: center;
  }
  .about-page-section .about-page-section-recruitment__content {
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  .about-page-section .about-page-section-recruitment__content-right {
    margin-top: 0;
  }
  .about-page-section .about-page-section-contact {
    margin-top: 80px;
    height: 480px;
  }
  .about-page-section .about-page-section-contact__content {
    margin-top: 100px;
  }
}
@media screen and (max-width: 860px) {
  .about-page-section .about-page-section-values {
    padding: 70px 0 0;
    padding-bottom: 70px;
  }
  .about-page-section .about-page-section-values__values-section {
    justify-content: center;
    flex-direction: column;
  }
  .about-page-section .about-page-section-values__paper {
    margin-bottom: 28px;
  }
  .about-page-section .about-page-section-values__paper:nth-child(2) {
    transform: translate(0, 80px);
    opacity: 0;
    transition: all 1.5s;
  }
  .about-page-section .about-page-section-values__paper:nth-child(3) {
    transform: translate(0, 80px);
    opacity: 0;
    transition: all 1.5s;
  }
  .about-page-section .about-page-section-values__paper:nth-child(4) {
    transform: translate(0, 80px);
    opacity: 0;
    transition: all 1.5s;
  }
  .about-page-section .about-page-section-values__paper:nth-child(5) {
    transform: translate(0, 80px);
    opacity: 0;
    transition: all 1.5s;
  }
  .about-page-section .about-page-section-company {
    margin-top: 70px;
  }
  .about-page-section .about-page-section-company__company-section {
    min-width: unset;
    width: 95%;
    padding-top: 0;
    padding-bottom: 50px;
  }
  .about-page-section .a-on-enter .about-page-section-values__values-section {
    justify-content: center;
    flex-direction: column;
  }
  .about-page-section .a-on-enter .about-page-section-values__paper:nth-child(2) {
    transform: translate(0, 0);
  }
  .about-page-section .a-on-enter .about-page-section-values__paper:nth-child(3) {
    transform: translate(0, 0);
  }
  .about-page-section .a-on-enter .about-page-section-values__paper:nth-child(4) {
    transform: translate(0, 0);
  }
  .about-page-section .a-on-enter .about-page-section-values__paper:nth-child(5) {
    transform: translate(0, 0);
  }
}
@media screen and (max-width: 768px) {
  .about-page-section .about-page-section-recruitment__content-left {
    margin-bottom: 30px;
  }
  .about-page-section .about-page-section-recruitment__content-left-first {
    margin-bottom: 30px;
  }
  .about-page-section .about-page-section-recruitment__content {
    flex-direction: column;
    align-items: center;
  }
  .about-page-section .about-page-section-recruitment__content-right {
    margin-left: 0;
  }
  .about-page-section .about-page-section-technology {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .about-page-section .about-page-section-vision {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }
  .about-page-section .about-page-section-vision__paper {
    padding: 14px !important;
  }
  .about-page-section .about-page-section-vision__icon {
    margin-bottom: 20px;
  }
  .about-page-section .about-page-section-vision--header {
    margin-bottom: 0;
  }
  .about-page-section .about-page-section-contact__content {
    margin-top: 70px;
  }
}
@media screen and (max-width: 640px) {
  .about-page-section .integration-page-footer__form {
    width: 90%;
    display: flex;
  }
  .about-page-section .integration-page-footer--input {
    width: 60% !important;
  }
  .about-page-section .about-page-section-company__company-section {
    background-image: none;
  }
  .about-page-section .about-page-section-company--header {
    padding-bottom: 0;
  }
  .about-page-section .about-page-section-company__list-company {
    justify-content: space-around;
  }
}
@media screen and (max-width: 480px) {
  .about-page-section .about-page-section-recruitment__content-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-page-section .about-page-section-recruitment--plus {
    display: none;
  }
  .about-page-section .about-page-section-recruitment__paper:first-child {
    margin-bottom: 20px;
  }
  .about-page-section .integration-page-footer__form {
    width: 100%;
    justify-content: center;
  }
  .about-page-section .integration-page-footer--input {
    width: 56% !important;
  }
  .about-page-section .integration-page-footer--button {
    min-width: 40% !important;
    width: 40% !important;
  }
  .about-page-section .integration-page-footer--button button {
    min-width: 100% !important;
    width: 100% !important;
  }
  .about-page-section .integration-page-footer--button span {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .about-page-section .about-page-section-values {
    padding: 90px 0 0;
  }
  .about-page-section .about-page-section-values__values {
    width: 320px;
  }
  .about-page-section .about-page-section-values__paper {
    width: 320px;
    padding: 15px;
  }
}
.question-mapping-container {
  max-width: inherit !important;
}
.question-mapping-section__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0,0,0,0.122) 0px 1px 6px, rgba(0,0,0,0.122) 0px 1px 4px;
}
.question-mapping-section__tab_heading {
  box-shadow: none !important;
}
.question-mapping-section__tab_heading .Mui-selected {
  box-shadow: 0 1px 5px rgba(0,0,0,0.129), 0 1px 2px rgba(0,0,0,0.129);
}
.question-mapping-section__tab_heading .MuiTab-root {
  margin-right: 5px;
}
.question-mapping-section-add-question {
  padding: 0 15px;
}
.question-mapping-section-questions-td {
  font-weight: 500;
  line-height: 2;
  padding: 5px 0;
  font-size: 14px;
  position: relative;
}
.question-mapping-section-questions-td .anchor-txt {
  padding-left: 15px;
}
.question-mapping-section-questions-td .settings-icon-box {
  position: absolute;
  right: 0;
  top: 5px;
}
.question-mapping-section-questions-td .delete-icon-box {
  position: absolute;
  right: 30px;
  top: 5px;
}
.question-mapping-section-questions-item {
  text-indent: -1em;
  padding-left: 1em;
  padding-right: 30px;
}
.question-mapping-section-questions-item span {
  font-weight: 300;
  font-size: 13px;
  color: #7a7a7a;
}
.question-mapping-section-jobs-td {
  line-height: 2;
  padding: 5px 0;
  font-size: 14px;
  position: relative;
}
.question-mapping-section-jobs-td .anchor-txt {
  position: absolute;
  right: 0;
  bottom: 5px;
}
.question-mapping-section-jobs-data {
  width: calc(100% - 55px);
}
.question-mapping-section-jobs-item {
  hyphens: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.question-mapping-section-candidate-main-td {
  min-height: 80px;
}
.question-mapping-section-candidate-td {
  border: 1px solid #ccc;
  padding: 5px 10px 0 15px;
  margin: 5px 0px;
  border-radius: 5px;
}
.question-mapping-section-candidate-td hr {
  display: none !important;
}
.question-mapping-section-settings-td .MuiFormControl-root.MuiFormControl-fullWidth {
  margin-top: 0;
  margin-bottom: 8px;
}
.question-mapping-section-settings-update-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.question-mapping-section-settings-all-buttons {
  display: flex;
  justify-content: space-between;
}
.question-mapping-section .anchor-txt {
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
}
.question-mapping-section .clear-data-btn {
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  text-align: right;
}
.question-mapping-section .clear-data-btn span {
  cursor: pointer;
}
.question-mapping-section-table-body tr {
  border-bottom: 2px solid #ccc !important;
}
.question-mapping-section-table-body td {
  white-space: unset !important;
  vertical-align: top;
  background-color: inherit !important;
  border-right: 2px solid #ccc !important;
}
.question-mapping-section-table-body td:last-child {
  border-right: none !important;
}
.question-mapping-section-answer-modal-question {
  font-weight: 500;
  color: #404144;
  font-size: 16px;
  margin: 10px;
}
.question-mapping-section-answer-modal-answer {
  font-weight: normal;
  font-size: 15px;
  margin: 15px 10px;
  color: #757575;
}
.question-mapping-section-answer-modal-wrapper .refari-field-tags-wrap {
  margin-top: 10px;
}
.empty-value {
  color: #ccc !important;
}
.settings-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
}
.settings-section .refari-select-wrap {
  width: 40%;
}
.settings-section .MuiCheckbox-root {
  padding-bottom: 0;
  padding-top: 0;
}
.settings-section .MuiFormControlLabel-root {
  margin: 0;
}
.refari-add-questions-label {
  font-size: 12px;
  color: #515151;
}
.refari-add-questions-value {
  font-size: 16px;
  margin-top: 5px;
}
.refari-add-questions-question {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.refari-add-questions-question-value {
  border: 1px solid #ccc;
  padding: 5px 10px;
}
.refari-add-questions-ul {
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: scroll;
}
.refari-add-questions-ul-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 5px 7px 15px;
  border-bottom: 1px solid #ccc;
}
.refari-pitch-me .custom-text-field hr {
  display: none !important;
}
.refari-pitch-me-top-block-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.refari-pitch-me-back-content {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  margin-top: 40px;
  justify-content: space-between;
}
.refari-pitch-me-back-content-share {
  display: flex;
}
.refari-pitch-me-head-title {
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 25px;
  letter-spacing: 1.2px;
  font-size: 36px;
  line-height: 54px;
  font-family: inherit;
  padding-left: 5px;
}
.refari-pitch-me-desc {
  font-weight: normal;
  font-size: 15px;
  font-family: inherit;
  padding-left: 5px;
  margin-bottom: 0;
  margin-top: 0;
}
.refari-pitch-me-divider {
  border: none;
  color: transparent;
  background-color: transparent;
  padding: 35px 0 25px 0;
}
.refari-pitch-me-divider:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  max-width: 100%;
  width: 100%;
  border: 1px solid #ccc;
}
.refari-pitch-me-button-block {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 0;
}
.refari-pitch-me-button-block .refari-select-wrap {
  margin-top: 5px !important;
}
.refari-pitch-me-button-block-fixed {
  position: fixed;
}
.refari-pitch-me-preview-loader {
  margin-right: 15px;
  padding-top: 5px;
}
.refari-pitch-me-create-loader {
  margin-left: 15px;
  padding-top: 5px;
}
.refari-pitch-me-heading {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.refari-pitch-me-heading-title-desc {
  flex-grow: 1;
}
.refari-pitch-me-heading-avatar {
  margin-left: 15px;
}
.refari-pitch-me-heading-avatar .refari-avatar-wrap {
  max-width: 167px;
  max-height: 167px;
  border-radius: 0 !important;
}
.refari-pitch-me-heading-avatar .refari-avatar-wrap img {
  border-radius: 0 !important;
}
.refari-pitch-me-heading-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.refari-pitch-me-heading-profile .MuiFormControlLabel-root {
  margin-right: 0;
  margin-bottom: 0;
}
.refari-pitch-me-heading--title-input {
  font-size: 36px;
  color: #757575;
  background-color: transparent;
  width: 100% !important;
}
.refari-pitch-me-heading--desc-input {
  margin-bottom: 0 !important;
  color: #757575;
  font-size: 16px;
  line-height: 24px;
  font-family: inherit;
}
.refari-pitch-me-heading--header-input {
  padding: 10px 33px 10px 16px;
  font-size: 36px;
  color: #757575;
  background-color: transparent;
  border: 1px dashed #757575 !important;
  width: 100% !important;
}
.refari-pitch-me-heading-closing-input,
.refari-pitch-me-heading-regards-input,
.refari-pitch-me-heading-description-input {
  width: 100% !important;
  padding: 12px 16px !important;
  font-style: normal;
  font-size: 18px;
  color: #757575;
  border: 1px dashed #757575 !important;
  padding-right: 33px !important;
  margin-top: 0px !important;
}
.refari-pitch-me-heading-closing-input textarea,
.refari-pitch-me-heading-regards-input textarea,
.refari-pitch-me-heading-description-input textarea {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  color: #757575 !important;
}
.refari-pitch-me-heading .input-wrap {
  position: relative;
  width: 100%;
}
.refari-pitch-me-heading .input-wrap textarea {
  background: transparent;
}
.refari-pitch-me-heading .pencil-icon {
  position: absolute;
  right: 7px;
  top: 0;
}
.refari-pitch-me-heading .m-t-2 {
  margin-top: 20px;
}
.refari-pitch-me-block {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.refari-pitch-me-block-title {
  flex: 1;
  margin: 0;
  padding-left: 5px;
}
.refari-pitch-me-block-title-help-input,
.refari-pitch-me-block-title-expertise-input,
.refari-pitch-me-block-title-job-input,
.refari-pitch-me-block-title-client-input {
  color: #000;
  font-weight: 700;
  margin: 0;
  font-size: 23px;
  line-height: 36px;
  font-family: inherit;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
}
.refari-pitch-me-block-title-editor {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: center;
}
.refari-pitch-me-block-title svg {
  margin-right: 10px;
}
.refari-pitch-me-block-toggle {
  flex: 1;
  text-align: right;
}
.refari-pitch-me-block-toggle .MuiFormControlLabel-root {
  margin-right: 0;
  margin-bottom: 0;
}
.refari-pitch-me-help {
  margin-top: 50px;
}
.refari-pitch-me-video-wrapper {
  position: relative;
  width: 100%;
  height: 600px;
}
.refari-pitch-me-video-wrapper iframe,
.refari-pitch-me-video-wrapper .refari-video-dimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.refari-pitch-me-video-wrapper .refari-video-dimmer {
  background-position: center;
  background-size: cover;
}
.refari-pitch-me-video-wrapper .refari-video-dimmer-change-link {
  border: 1px dashed #757575;
}
.refari-pitch-me-video-wrapper .refari-play-button,
.refari-pitch-me-video-wrapper .refari-pitch-me-video-change-link-block {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.refari-pitch-me-video-wrapper .refari-play-button-icon,
.refari-pitch-me-video-wrapper .refari-pitch-me-video-change-link-block-icon {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 120px;
  border-color: transparent transparent transparent #fff;
  transition: 100ms all ease;
  cursor: default;
  border-style: solid;
  border-width: 90px 0 90px 120px;
}
.refari-pitch-me-video-wrapper .refari-pitch-me-video-change-link-block {
  max-width: 500px;
  width: 90%;
}
.refari-pitch-me-expertise {
  margin-top: 50px;
}
.refari-pitch-me-expertise .react-tagsinput {
  border: none !important;
}
.refari-pitch-me-expertise .refari-react-tagsinput-input {
  min-width: 100%;
}
.refari-pitch-me-expertise__search {
  max-width: 400px;
}
.refari-pitch-me-expertise-job-selected {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.refari-pitch-me-expertise-job-selected svg {
  color: #fff;
}
.refari-pitch-me-expertise-job-item {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}
.refari-pitch-me-expertise-item {
  border: 10px;
  font-family: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-decoration: none;
  margin: 0px;
  margin-right: 10px;
  outline: none;
  font-size: 18px;
  font-weight: inherit;
  background-color: #65707b;
  border-radius: 22px;
  color: #fff;
  width: fit-content;
  padding: 8px 20px !important;
}
.refari-pitch-me-placed {
  margin-top: 50px;
}
.refari-pitch-me-placed__search {
  max-width: 400px;
}
.refari-pitch-me-placed-section {
  margin-top: 25px;
}
.refari-pitch-me-placed-job-selected {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.refari-pitch-me-placed-job-item {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  width: fit-content;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 15px;
}
.refari-pitch-me-placed-job-item-header {
  margin: 0;
  line-height: 1.5;
  font-size: 15px;
  font-weight: bold;
}
.refari-pitch-me-placed-job-item-subtitle {
  margin: 0;
  line-height: 1.5;
  font-size: 13px;
  font-weight: normal;
}
.refari-pitch-me-placed-job-item-close {
  border: 0;
  background: 0;
  color: #9e9e9e;
  cursor: pointer;
  font-size: 25px;
  margin: 0;
  padding: 0;
  line-height: 0px;
  margin-left: 20px;
}
.refari-pitch-me-testimonial-section {
  margin-top: 25px;
}
.refari-pitch-me-testimonial__search {
  max-width: 400px;
}
.refari-pitch-me-testimonial-job-selected {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.refari-pitch-me-testimonial-job-item {
  position: relative;
  height: 396px;
  text-align: left;
  padding: 10px;
  width: 25%;
}
.refari-pitch-me-testimonial-job-item-inner {
  padding: 23px;
  height: 330px;
}
.refari-pitch-me-testimonial-job-item-quotes {
  position: absolute;
  top: 0;
  left: 0;
}
.refari-pitch-me-testimonial-job-item-content {
  overflow: hidden;
  height: 160px;
  margin-bottom: 18px;
  margin-top: 0px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}
.refari-pitch-me-testimonial-job-item-content-small {
  height: 120px;
}
.refari-pitch-me-testimonial-job-item-star {
  padding: 0;
  margin: 0;
  max-height: 30px;
}
.refari-pitch-me-testimonial-job-item__verified {
  display: inline-block;
}
.refari-pitch-me-testimonial-job-item--verified-show {
  display: inline-block;
}
.refari-pitch-me-testimonial-job-item--verified {
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #27ae60;
}
.refari-pitch-me-testimonial-job-item--verified a {
  color: #27ae60;
}
.refari-pitch-me-testimonial-job-item--verified-hide {
  display: none;
}
.refari-pitch-me-testimonial-job-item:hover .refari-pitch-me-testimonial-job-item--verified-show {
  display: none;
}
.refari-pitch-me-testimonial-job-item:hover .refari-pitch-me-testimonial-job-item--verified-hide {
  display: inline-block;
}
.refari-pitch-me-testimonial-job-item-bottom-section {
  position: absolute;
  bottom: 76px;
  width: calc(100% - 66px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.refari-pitch-me-testimonial-job-item-name {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}
.refari-pitch-me-testimonial-job-item-occupation {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}
.refari-pitch-me-testimonial-job-item-date {
  margin-bottom: 3px;
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #9e9e9e;
}
.refari-pitch-me-testimonial-job-item-close-section {
  width: 100%;
  text-align: center;
}
.refari-pitch-me-testimonial-job-item-close {
  border: 0;
  background: 0;
  color: #9e9e9e;
  cursor: pointer;
  font-size: 25px;
  margin: 0;
  padding: 0;
  line-height: 0px;
  text-align: center;
  margin-top: 10px;
}
.refari-pitch-me-regards {
  width: 300px;
  margin-right: 30px;
}
.refari-pitch-me-regards-section {
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
  flex-wrap: wrap;
  width: 100%;
}
.refari-pitch-me-call-to-action {
  display: flex;
  width: calc(100% - 330px);
}
.refari-pitch-me-call-to-action-button-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}
.refari-pitch-me-call-to-action-box {
  min-height: 134px;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;
}
.refari-pitch-me-call-to-action-box-inner {
  display: flex;
  align-items: center;
}
.refari-pitch-me-call-to-action .react-draggable {
  width: fit-content;
}
.refari-pitch-me .auto-complete-component-item {
  position: relative;
  padding: 18px 32px;
  border-bottom: 1px solid #eceff1;
  cursor: pointer;
}
.refari-pitch-me .auto-complete-component-item p {
  padding: 0;
  margin: 0;
}
.refari-pitch-me .auto-complete-component-item--content {
  font-size: 14px;
}
.refari-pitch-me .auto-complete-component-item--plus-icon {
  position: absolute;
  top: 27%;
  right: 30px;
  font-size: 22px;
}
.refari-pitch-me .auto-complete-component-item:last-child {
  border-bottom: none;
}
.refari-pitch-me .auto-complete-component-item:hover {
  background-color: #eceff1;
}
.refari-pitch-me-testimonial-job-item-content .refari-pitch-me-testimonial-job-item-content-read-more {
  display: inline;
  font-size: 14px;
  text-decoration: underline;
}
.refari-pitch-me-preview .refari-pitch-me-block {
  margin-bottom: 20px;
}
.refari-pitch-me-preview .refari-pitch-me-block-title {
  padding-left: 0px;
}
.refari-pitch-me-preview .refari-pitch-me-expertise-section {
  display: flex;
}
.refari-pitch-me-preview .refari-pitch-me-testimonial-job-item {
  height: 350px;
}
.refari-pitch-me-preview .refari-pitch-me-testimonial-job-selected .refari-pitch-me-testimonial-job-item:first-child {
  padding-left: 0;
}
.refari-pitch-me-preview .refari-pitch-me-testimonial-job-selected .refari-pitch-me-testimonial-job-item:last-child {
  padding-right: 0;
}
.refari-pitch-me-preview .refari-pitch-me-closing {
  margin-bottom: 0 !important;
  margin-top: 40px;
  color: #757575;
  font-size: 18px;
  line-height: 24px;
  font-family: inherit;
}
.refari-pitch-me-preview .refari-pitch-me-regards {
  color: #757575;
  font-size: 22px;
  line-height: 30px;
  font-family: inherit;
  margin-top: 40px;
}
.refari-pitch-me-preview .refari-pitch-me-call-to-action {
  margin-top: 35px;
}
.no-drag-area {
  position: absolute;
  user-select: none;
  width: 20px;
  height: 20px;
  right: -10px;
  bottom: -10px;
}
.refari-skeleton .refari-pitch-me-button-block {
  display: flex;
  justify-content: flex-end;
}
.refari-skeleton .refari-pitch-me-button-block > div {
  margin-left: 20px;
}
.refari-skeleton .refari-pitch-me-block-title {
  padding-left: 0px !important;
}
.refari-skeleton .refari-pitch-me-expertise-skeleton {
  display: flex;
  margin-top: 20px;
}
.refari-skeleton .refari-pitch-me-expertise-skeleton > div {
  margin-right: 15px;
}
.link.refari-detail-back-link {
  margin-bottom: 15px;
  display: inline-flex;
}
.refari-link.refari-detail-back-link {
  margin-bottom: 15px;
  display: inline-flex;
}
.refari-detail-back-button {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  color: #2b2b2b;
}
.refari-detail-back-button:hover .refari-detail-back-button--hover {
  text-decoration: underline;
  color: #2b2b2b;
}
.refari-detail-back-button--hover {
  margin-left: 5px;
}
.refari-detail-top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.refari-detail-top-header .refari-link.refari-detail-back-link {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
@media screen and (max-width: 600px) {
  .refari-detail-top-header-right button {
    margin-bottom: 10px;
  }
}
.refari-detail-top-header-right-pitch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.refari-avatar-wrap {
  width: 240px;
  height: 240px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
}
.refari-avatar-wrap-not-round {
  border-radius: 0% !important;
}
.refari-avatar-wrap-not-round > img {
  border-radius: 0% !important;
}
.refari-avatar-wrap > img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
@media screen and (max-width: 1140px) {
  .refari-pitch-me-testimonial-job-item {
    width: 33%;
  }
}
@media screen and (max-width: 850px) {
  .refari-pitch-me-testimonial-job-item {
    width: 50%;
  }
}
@media screen and (max-width: 570px) {
  .refari-pitch-me-testimonial-job-item {
    width: 100%;
  }
}
.about-page-section-navigation {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .about-page-section-navigation .navigation__btn:hover {
    background-color: #0074bc !important;
  }
}
.about-page-section-navigation .navigation__btn:hover span {
  color: #fff !important;
}
@media screen and (max-width: 560px) {
  .about-page-section-navigation .navigation__btn span {
    font-size: 10px !important;
  }
}
.header--sales .sales-page-navigation {
  right: 0;
  transform: translateX(0%);
  transition: right 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 374px) {
  .header--sales .sales-page-navigation {
    padding-top: 15px;
  }
}
@media screen and (min-width: 560px) {
  .header--sales.is-minified .sales-page-navigation {
    position: relative;
    right: 50%;
    transform: translateX(50%);
  }
}
@media screen and (max-width: 560px) {
  .header--sales.is-minified .navigation__btn {
    min-width: 125px !important;
  }
}
@media screen and (min-width: 561px) {
  .header--sales.is-minified .navigation__btn {
    min-width: 150px !important;
  }
}
.about-page-section {
  width: 100%;
  margin-top: 80px;
  position: relative;
  overflow: hidden;
  color: #24253d;
}
.about-page-section .about-page-section-header {
  height: 600px;
  position: relative;
  background-image: url("../../assets/images/integration_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.about-page-section .about-page-section-header .section-conversion__bg {
  display: none;
}
@media screen and (min-width: 768px) {
  .about-page-section .about-page-section-header .section-conversion__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/images/sales-page_users-illustration.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    transform: translate(0, 60%);
    transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    opacity: 0;
  }
}
@media screen and (min-width: 768px) {
  .about-page-section .about-page-section-header.a-on-enter .section-conversion__bg {
    transform: translate(0, 0%);
    opacity: 1;
  }
}
.about-page-section .about-page-section-header.a-on-enter .a-bottom-enter {
  transform: translate(0, 0%);
  opacity: 1;
}
.about-page-section .about-page-section-header__flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-page-section .about-page-section-header--header {
  max-width: 686px;
  font-size: 36px;
  font-weight: 300;
  line-height: 54px;
}
.about-page-section .about-page-section-header--content {
  margin-top: 27px;
  margin-bottom: 60px;
  max-width: 600px;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}
.about-page-section .about-page-section-recruitment {
  margin-top: 120px;
  margin-bottom: 120px;
}
.about-page-section .about-page-section-recruitment__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: all 1s;
  transform: translate(0, 50%);
  opacity: 0;
}
.about-page-section .about-page-section-recruitment--header {
  margin-bottom: 120px;
  max-width: 600px;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
}
.about-page-section .about-page-section-recruitment__content {
  display: flex;
  justify-content: space-between;
  width: 65%;
}
.about-page-section .about-page-section-recruitment--content {
  margin-bottom: 0;
}
.about-page-section .about-page-section-recruitment__content-left-first {
  margin-bottom: 40px;
}
.about-page-section .about-page-section-recruitment__content-left {
  max-width: 430px;
  text-align: left;
  transition: all 1s;
  transform: translate(-50%, 0);
  opacity: 0;
}
.about-page-section .about-page-section-recruitment__content-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 30px;
  transition: all 1s;
  transform: translate(50%, 0);
  opacity: 0;
}
.about-page-section .about-page-section-recruitment--header-content {
  margin-top: 16px;
  margin-bottom: 0;
  color: #24253d;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
}
.about-page-section .about-page-section-recruitment--content {
  max-width: 320px;
  color: #24253d;
  font-size: 16px;
  line-height: 32px;
}
.about-page-section .flex-column-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-page-section .about-page-section-contact {
  margin-top: 135px;
  background-color: #f9fbfe;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 532px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.about-page-section .about-page-section-contact__icon {
  background-image: url("../../assets/images/ultimate_tool.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: all 1s;
  width: 48px;
  height: 48px;
  margin: 0 auto 36px;
  transform: scale(0);
}
.about-page-section .about-page-section-contact--header {
  max-width: 455px;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .about-page-section-contact--content {
  margin-top: 17px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
.about-page-section .about-page-section-contact--link {
  text-decoration: none;
  color: #4e77ff;
  font-size: 24px;
  line-height: 40px;
}
.about-page-section .about-page-section-contact__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
}
.about-page-section .integration-page-footer__container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background-color: #0074bc;
  transition: all 1s;
  opacity: 0;
}
.about-page-section .integration-page-footer__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-page-section .integration-page-footer--header {
  margin-bottom: 56px;
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .integration-page-footer--input {
  width: 430px !important;
}
.about-page-section .about-page-section-technology {
  padding-top: 129px;
  padding-bottom: 120px;
  position: relative;
}
.about-page-section .about-page-section-technology--header {
  max-width: 570px;
  color: #24253d;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .about-page-section-technology__check-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 36px;
}
.about-page-section .about-page-section-technology__check-item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
.about-page-section .about-page-section-technology--content {
  margin: 0 0 0 14px;
}
.about-page-section .about-page-section-technology .section-conversion__bg {
  display: none;
}
@media screen and (min-width: 768px) {
  .about-page-section .about-page-section-technology .section-conversion__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/images/sales-page_users-illustration.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    transform: translate(0, 60%);
    transition: all 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    opacity: 0;
  }
}
@media screen and (min-width: 768px) {
  .about-page-section .about-page-section-technology.a-on-enter .section-conversion__bg {
    transform: translate(0, 0%);
    opacity: 1;
  }
}
.about-page-section .about-page-section-technology.a-on-enter .a-bottom-enter {
  transform: translate(0, 0%);
  opacity: 1;
}
.about-page-section .about-page-section-vision {
  margin-bottom: 120px;
}
.about-page-section .about-page-section-vision__icon {
  background-image: url("../../assets/images/ultimate_tool.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: all 1s !important;
  width: 48px;
  height: 48px;
  margin: 0 auto 36px;
  transform: scale(0);
}
.about-page-section .about-page-section-vision__paper {
  transition: all 1s cubic-bezier(0.39, 0.575, 0.565, 1) !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 64px !important;
}
.about-page-section .about-page-section-vision--header {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
}
.about-page-section .about-page-section-vision--content {
  max-width: 615px;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
}
.about-page-section .about-page-section-values {
  background-color: #f9fbfe;
  padding: 120px 0 0;
}
.about-page-section .about-page-section-values__values-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
}
.about-page-section .about-page-section-values__values {
  height: 289px;
  width: 370px;
  border-radius: 6px;
  transition: all 1s;
  transform: translate(0, 91px);
  opacity: 0;
}
.about-page-section .about-page-section-values--values-img {
  margin-bottom: 36px;
}
.about-page-section .about-page-section-values--values-header {
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .about-page-section-values--values-content {
  font-size: 16px;
  line-height: 32px;
}
.about-page-section .about-page-section-values__paper {
  max-width: 370px;
  padding: 40px 36px 30px;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values__paper:nth-child(2) {
  transform: translate(0, 200px);
  opacity: 0;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values__paper:nth-child(3) {
  transform: translate(0, 0);
  opacity: 0;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values__paper:nth-child(4) {
  transform: translate(0, 200px);
  opacity: 0;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values__paper:nth-child(5) {
  transform: translate(0, 0);
  opacity: 0;
  transition: all 1.5s;
}
.about-page-section .about-page-section-values--paper-img {
  margin-bottom: 35px;
}
.about-page-section .about-page-section-values--paper-header {
  color: #24253d;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}
.about-page-section .about-page-section-values--paper-content {
  color: #24253d;
  font-size: 16px;
  line-height: 32px;
}
.about-page-section .about-page-section-company {
  margin-top: 100px;
}
.about-page-section .about-page-section-company__company-section {
  min-width: 780px;
  min-height: 300px;
  padding-top: 110px;
  padding-bottom: 120px;
  background: url("../../assets/images/mapReferrer.svg") no-repeat right;
}
.about-page-section .about-page-section-company--header {
  padding-bottom: 46px;
  color: #24253d;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.about-page-section .about-page-section-company--content {
  color: #24253d;
  font-size: 16px;
  line-height: 32px;
}
.about-page-section .about-page-section-company__list-company {
  display: flex;
  flex-wrap: wrap;
  max-width: 640px;
  margin-left: -10px;
}
.about-page-section--animation {
  transition: transform 1s, opacity 1s;
  transform: translate(0, 20vh);
  opacity: 0;
}
.about-page-section .a-on-enter .about-page-section-recruitment__section,
.about-page-section .a-on-enter .about-page-section-recruitment__content-left,
.about-page-section .a-on-enter .about-page-section-recruitment__content-right,
.about-page-section .a-on-enter .about-page-section--animation,
.about-page-section .a-on-enter .integration-page-footer__container {
  transform: translate(0, 0);
  opacity: 1;
}
.about-page-section .a-on-enter .about-page-section-contact__icon,
.about-page-section .a-on-enter .about-page-section-vision__icon {
  transform: scale(1);
}
.about-page-section .a-on-enter .about-page-section-values {
  transition: all 1.5s;
}
.about-page-section .a-on-enter .about-page-section-values__values {
  transform: translate(0, 0);
  opacity: 1;
  transition: all 0.5s !important;
}
.about-page-section .a-on-enter .about-page-section-values__paper:nth-child(2) {
  transform: translate(0, 80px);
  opacity: 1;
}
.about-page-section .a-on-enter .about-page-section-values__paper:nth-child(3) {
  transform: translate(0, -70px);
  opacity: 1;
}
.about-page-section .a-on-enter .about-page-section-values__paper:nth-child(4) {
  transform: translate(0, 115px);
  opacity: 1;
}
.about-page-section .a-on-enter .about-page-section-values__paper:nth-child(5) {
  transform: translate(0, -115px);
  opacity: 1;
}
@media screen and (max-width: 991px) {
  .about-page-section {
    margin-top: 60px;
  }
  .about-page-section .about-page-section-recruitment {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .about-page-section .about-page-section-recruitment--header {
    margin-bottom: 50px;
    text-align: center;
  }
  .about-page-section .about-page-section-recruitment__content {
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  .about-page-section .about-page-section-recruitment__content-right {
    margin-top: 0;
  }
  .about-page-section .about-page-section-contact {
    margin-top: 80px;
    height: 480px;
  }
  .about-page-section .about-page-section-contact__content {
    margin-top: 100px;
  }
}
@media screen and (max-width: 860px) {
  .about-page-section .about-page-section-values {
    padding: 70px 0 0;
    padding-bottom: 70px;
  }
  .about-page-section .about-page-section-values__values-section {
    justify-content: center;
    flex-direction: column;
  }
  .about-page-section .about-page-section-values__paper {
    margin-bottom: 28px;
  }
  .about-page-section .about-page-section-values__paper:nth-child(2) {
    transform: translate(0, 80px);
    opacity: 0;
    transition: all 1.5s;
  }
  .about-page-section .about-page-section-values__paper:nth-child(3) {
    transform: translate(0, 80px);
    opacity: 0;
    transition: all 1.5s;
  }
  .about-page-section .about-page-section-values__paper:nth-child(4) {
    transform: translate(0, 80px);
    opacity: 0;
    transition: all 1.5s;
  }
  .about-page-section .about-page-section-values__paper:nth-child(5) {
    transform: translate(0, 80px);
    opacity: 0;
    transition: all 1.5s;
  }
  .about-page-section .about-page-section-company {
    margin-top: 70px;
  }
  .about-page-section .about-page-section-company__company-section {
    min-width: unset;
    width: 95%;
    padding-top: 0;
    padding-bottom: 50px;
  }
  .about-page-section .a-on-enter .about-page-section-values__values-section {
    justify-content: center;
    flex-direction: column;
  }
  .about-page-section .a-on-enter .about-page-section-values__paper:nth-child(2) {
    transform: translate(0, 0);
  }
  .about-page-section .a-on-enter .about-page-section-values__paper:nth-child(3) {
    transform: translate(0, 0);
  }
  .about-page-section .a-on-enter .about-page-section-values__paper:nth-child(4) {
    transform: translate(0, 0);
  }
  .about-page-section .a-on-enter .about-page-section-values__paper:nth-child(5) {
    transform: translate(0, 0);
  }
}
@media screen and (max-width: 768px) {
  .about-page-section .about-page-section-recruitment__content-left {
    margin-bottom: 30px;
  }
  .about-page-section .about-page-section-recruitment__content-left-first {
    margin-bottom: 30px;
  }
  .about-page-section .about-page-section-recruitment__content {
    flex-direction: column;
    align-items: center;
  }
  .about-page-section .about-page-section-recruitment__content-right {
    margin-left: 0;
  }
  .about-page-section .about-page-section-technology {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .about-page-section .about-page-section-vision {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }
  .about-page-section .about-page-section-vision__paper {
    padding: 14px !important;
  }
  .about-page-section .about-page-section-vision__icon {
    margin-bottom: 20px;
  }
  .about-page-section .about-page-section-vision--header {
    margin-bottom: 0;
  }
  .about-page-section .about-page-section-contact__content {
    margin-top: 70px;
  }
}
@media screen and (max-width: 640px) {
  .about-page-section .integration-page-footer__form {
    width: 90%;
    display: flex;
  }
  .about-page-section .integration-page-footer--input {
    width: 60% !important;
  }
  .about-page-section .about-page-section-company__company-section {
    background-image: none;
  }
  .about-page-section .about-page-section-company--header {
    padding-bottom: 0;
  }
  .about-page-section .about-page-section-company__list-company {
    justify-content: space-around;
  }
}
@media screen and (max-width: 480px) {
  .about-page-section .about-page-section-recruitment__content-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-page-section .about-page-section-recruitment--plus {
    display: none;
  }
  .about-page-section .about-page-section-recruitment__paper:first-child {
    margin-bottom: 20px;
  }
  .about-page-section .integration-page-footer__form {
    width: 100%;
    justify-content: center;
  }
  .about-page-section .integration-page-footer--input {
    width: 56% !important;
  }
  .about-page-section .integration-page-footer--button {
    min-width: 40% !important;
    width: 40% !important;
  }
  .about-page-section .integration-page-footer--button button {
    min-width: 100% !important;
    width: 100% !important;
  }
  .about-page-section .integration-page-footer--button span {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .about-page-section .about-page-section-values {
    padding: 90px 0 0;
  }
  .about-page-section .about-page-section-values__values {
    width: 320px;
  }
  .about-page-section .about-page-section-values__paper {
    width: 320px;
    padding: 15px;
  }
}
.activities-page .table tbody td:first-child a {
  position: relative;
  padding-left: 50px;
}
.activities-page .table tbody td:first-child a:before {
  content: '';
  display: block;
  position: absolute;
  left: 24px;
  top: 50%;
  margin-top: -4px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  vertical-align: middle;
}
.activities-page .table .application-created td:first-child a:before {
  background-color: #4ab6fe;
}
.activities-page .table .application-created-linkedin td:first-child a:before {
  background-color: #2024ec;
}
.activities-page .table .job-shared td:first-child a:before {
  background-color: #fe7c7a;
}
.activities-page .table .job-saved td:first-child a:before {
  background-color: #4cf8ee;
}
.activities-page .table .job-rated td:first-child a:before {
  background-color: #966a03;
}
.activities-page .table .publication-created td:first-child a:before {
  background-color: #b8e985;
}
.activities-page .table .subscription-created td:first-child a:before {
  background-color: #c4daf1;
}
.activities-page .table .talent-alert-subscription-created td:first-child a:before {
  background-color: #800080;
}
.activities-page .table .job-removed td:first-child a:before {
  background-color: #009688;
}
.activities-page .table .subscription-removed td:first-child a:before {
  background-color: #abb8c3;
}
.activities-page .table .talent-alert-subscription-removed td:first-child a:before {
  background-color: #8b0000;
}
.activities-page .table .candidate-referred-crm td:first-child a:before {
  background-color: #795548;
}
.activities-page .table .candidate-referred td:first-child a:before {
  background-color: #ff9800;
}
.activities-page .table .suggestion_accepted td:first-child a:before {
  background-color: #116804;
}
.activities-page .table .talent_alert_suggestion_accepted td:first-child a:before {
  background-color: #006400;
}
.activities-page .table .suggestion_created td:first-child a:before {
  background-color: #68b65a;
}
.activities-page .table .talent_alert_suggestion_created td:first-child a:before {
  background-color: #b8860b;
}
.activities-page .table .testimonial_published td:first-child a:before {
  background-color: #0074bc;
}
.activities-page .table .testimonial_created td:first-child a:before {
  background-color: #b9058c;
}
.activities-page .table .candidate_created td:first-child a:before {
  background-color: #5239c2;
}
.activities-page .table .feedback-created td:first-child a:before {
  background-color: #3fd342;
}
.activities-page .table .testimonial_approved td:first-child a:before {
  background-color: #722e3a;
}
.activities-page .table .testimonial_revised td:first-child a:before {
  background-color: #c77481;
}
.activities-page .table .testimonial_updated td:first-child a:before {
  background-color: #fccad2;
}
.activities-page .table .candidate_ad_verified td:first-child a:before {
  background-color: #722e3a;
}
.activities-page .table .custom_talent_request_created td:first-child a:before {
  background-color: #722e3a;
}
.activities-page .checked-multiselect {
  width: 320px;
}
.activities-page .checked-multiselect ul li:before {
  content: '';
  display: block;
  position: absolute;
  right: 44px;
  top: 50%;
  margin-top: -4px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background-color: #4ab6fe;
}
.activities-page .checked-multiselect ul li[data-value="application_created"]:before {
  background-color: #4ab6fe;
}
.activities-page .checked-multiselect ul li[data-value="application_created_linkedin"]:before {
  background-color: #2024ec;
}
.activities-page .checked-multiselect ul li[data-value="job_shared"]:before {
  background-color: #fe7c7a;
}
.activities-page .checked-multiselect ul li[data-value="job_saved"]:before {
  background-color: #4cf8ee;
}
.activities-page .checked-multiselect ul li[data-value="job_rated"]:before {
  background-color: #966a03;
}
.activities-page .checked-multiselect ul li[data-value="publication_created"]:before {
  background-color: #b8e985;
}
.activities-page .checked-multiselect ul li[data-value="subscription_created"]:before {
  background-color: #c4daf1;
}
.activities-page .checked-multiselect ul li[data-value="talent_alert_subscription_created"]:before {
  background-color: #800080;
}
.activities-page .checked-multiselect ul li[data-value="job_featured_deleted"]:before {
  background-color: #009688;
}
.activities-page .checked-multiselect ul li[data-value="subscription_deleted"]:before {
  background-color: #abb8c3;
}
.activities-page .checked-multiselect ul li[data-value="talent_alert_subscription_deleted"]:before {
  background-color: #8b0000;
}
.activities-page .checked-multiselect ul li[data-value="candidate_referred_crm"]:before {
  background-color: #795548;
}
.activities-page .checked-multiselect ul li[data-value="candidate_referred"]:before {
  background-color: #ff9800;
}
.activities-page .checked-multiselect ul li[data-value="suggestion_accepted"]:before {
  background-color: #116804;
}
.activities-page .checked-multiselect ul li[data-value="talent_alert_suggestion_accepted"]:before {
  background-color: #006400;
}
.activities-page .checked-multiselect ul li[data-value="suggestion_created"]:before {
  background-color: #68b65a;
}
.activities-page .checked-multiselect ul li[data-value="talent_alert_suggestion_created"]:before {
  background-color: #b8860b;
}
.activities-page .checked-multiselect ul li[data-value="testimonial_published"]:before {
  background-color: #0074bc;
}
.activities-page .checked-multiselect ul li[data-value="testimonial_created"]:before {
  background-color: #b9058c;
}
.activities-page .checked-multiselect ul li[data-value="candidate_created"]:before {
  background-color: #5239c2;
}
.activities-page .checked-multiselect ul li[data-value="feedback_created"]:before {
  background-color: #3fd342;
}
.activities-page .checked-multiselect ul li[data-value="testimonial_approved"]:before {
  background-color: #722e3a;
}
.activities-page .checked-multiselect ul li[data-value="testimonial_revised"]:before {
  background-color: #c77481;
}
.activities-page .checked-multiselect ul li[data-value="testimonial_updated"]:before {
  background-color: #fccad2;
}
.activities-page .checked-multiselect ul li[data-value="candidate_ad_verified"]:before {
  background-color: #722e3a;
}
.activities-page .checked-multiselect ul li[data-value="custom_talent_request_created"]:before {
  background-color: #722e3a;
}
.activity-subject .application-created {
  background-color: #4ab6fe;
}
.activity-subject .application-created-linkedin {
  background-color: #2024ec;
}
.activity-subject .job-shared {
  background-color: #fe7c7a;
}
.activity-subject .job-saved {
  background-color: #4cf8ee;
}
.activity-subject .job-rated {
  background-color: #966a03;
}
.activity-subject .publication-created {
  background-color: #b8e985;
}
.activity-subject .subscription-created {
  background-color: #c4daf1;
}
.activity-subject .talent-alert-subscription-created {
  background-color: #800080;
}
.activity-subject .job-removed {
  background-color: #009688;
}
.activity-subject .subscription-removed {
  background-color: #abb8c3;
}
.activity-subject .talent-alert-subscription-removed {
  background-color: #8b0000;
}
.activity-subject .candidate-referred-crm {
  background-color: #795548;
}
.activity-subject .candidate-referred {
  background-color: #ff9800;
}
.activity-subject .suggestion_accepted {
  background-color: #116804;
}
.activity-subject .suggestion_created {
  background-color: #68b65a;
}
.activity-subject .talent_alert_suggestion_accepted {
  background-color: #006400;
}
.activity-subject .talent_alert_suggestion_created {
  background-color: #b8860b;
}
.activity-subject .testimonial_published {
  background-color: #0074bc;
}
.activity-subject .testimonial_created {
  background-color: #b9058c;
}
.activity-subject .candidate_created {
  background-color: #5239c2;
}
.activity-subject .feedback-created {
  background-color: #3fd342;
}
.activity-subject .testimonial_approved {
  background-color: #722e3a;
}
.activity-subject .testimonial_revised {
  background-color: #c77481;
}
.activity-subject .testimonial_updated {
  background-color: #fccad2;
}
.activity-subject .candidate_ad_verified {
  background-color: #722e3a;
}
.activity-subject .custom_talent_request_created {
  background-color: #722e3a;
}
.badges {
  width: 100%;
  margin-bottom: 48px;
}
.badges-container,
.your-badges {
  width: 830px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
}
.badges-container__left-section,
.your-badges__left-section {
  width: 450px;
}
.badges-container__header,
.your-badges__header {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 17px 21px;
  background-color: #3886d1;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.badges-container--title,
.your-badges--title {
  text-transform: capitalize;
}
.badges-container__content-item,
.your-badges__content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.badges-container__content-description,
.your-badges__content-description {
  text-align: center;
}
.badges-container--content-header,
.your-badges--content-header {
  margin-top: 16px;
  margin-bottom: 0;
  color: #757575;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.badges-container--content-description,
.your-badges--content-description {
  margin-bottom: 0;
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.badges-container--points,
.your-badges--points {
  margin-top: 8px;
  margin-bottom: 0;
  color: #616161;
  font-size: 18px;
  font-weight: 500;
}
.badges-container--points-count,
.your-badges--points-count {
  margin-bottom: 0;
  color: #0074bc;
  font-size: 36px;
  font-weight: 400;
}
.badges-container__points-section,
.your-badges__points-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 360px;
  margin-bottom: 20px;
  padding: 16px;
}
.badges-container__loader-item,
.your-badges__loader-item {
  display: grid;
  padding: 16px 30px;
  text-decoration: none;
}
.badges-container--loader-number,
.your-badges--loader-number {
  color: #9e9e9e;
  font-size: 12px;
}
.badges-container--loader-avatar,
.your-badges--loader-avatar {
  width: 32px;
  height: 32px;
  margin-left: 12px;
  margin-right: 12px;
  overflow: hidden;
  border-radius: 50%;
}
.badges-container--loader-name,
.your-badges--loader-name {
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.badges-container--loader-points,
.your-badges--loader-points {
  margin-bottom: 0;
  padding-left: 63px;
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.badges-container__badge-icon-section,
.your-badges__badge-icon-section {
  display: flex;
  flex-wrap: wrap;
  max-width: 240px;
  margin-left: 70px;
}
.badges-container__badge-icon-section .wrapper-points-section,
.your-badges__badge-icon-section .wrapper-points-section {
  margin-bottom: 5px;
  cursor: pointer;
}
.all-badges {
  width: 770px;
  margin-left: auto;
  margin-right: auto;
}
.all-badges--header {
  margin-top: 16px;
  color: #616161;
  font-size: 24px;
  font-weight: 400;
}
.all-badges--content {
  width: 523px;
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.all-badges__badges-group {
  display: flex;
}
.all-badges__badges-group .wrapper-points-section:nth-child(2) {
  transform: translate(-52px);
}
.all-badges__badges-group .wrapper-points-section:nth-child(3) {
  transform: translate(-104px);
}
.all-badges__badges-group .wrapper-points-section:nth-child(4) {
  transform: translate(-156px);
}
.all-badges__badges-group .wrapper-points-section:nth-child(5) {
  transform: translate(-209px);
}
.all-badges--receive-data {
  margin-left: auto;
  color: #9e9e9e;
  font-size: 14px;
}
.all-badges__roles {
  display: flex;
  padding: 36px 80px;
  justify-content: space-between;
}
.all-badges__roles-item {
  margin-top: 24px;
}
.all-badges--roles-header-img {
  margin-left: 7px;
  margin-right: 20px;
}
.all-badges__your-badges-list .all-badges__roles-list-item {
  cursor: pointer;
}
.all-badges__your-badges-list .all-badges__roles-list-item:hover {
  background-color: rgba(0,0,0,0.039);
}
.all-badges__roles-list-item {
  display: flex;
  padding: 17px 21px;
}
.all-badges__roles-list-item:nth-child(n + 2) {
  border-top: 1px solid #eceff1;
}
.all-badges--roles-img {
  width: 60%;
}
.all-badges--roles-counter {
  font-size: 12px;
  margin-right: 6px;
  color: #000;
  text-align: center;
  margin-top: 4px;
}
.all-badges__roles-list-description {
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.all-badges--roles-list-header {
  margin: unset;
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.all-badges--roles-list-description {
  margin: unset;
  color: #9e9e9e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.wrap-points-section {
  position: relative;
  margin-right: 16px;
  margin-bottom: 8px;
}
.wrap-points-section:hover .wrapper-points-section__description {
  display: inline-block;
}
.wrap-points-section--min .close-wrapper {
  position: relative;
  background-color: rgba(206,206,208,0.3);
}
.wrap-points-section--min .close-wrapper:after {
  content: "";
  position: absolute;
  right: -4px;
  bottom: 0;
  width: 13px;
  height: 13px;
  background-image: url("../../assets/images/lock.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.wrapper-points-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 48px;
  width: 48px;
  max-width: 48px;
  min-width: 48px;
  margin-right: 10px;
  border-radius: 50%;
}
.wrapper-points-section__description {
  display: none;
  position: absolute;
  bottom: -34px;
  max-width: 100px;
  padding: 5px;
  font-size: 10px;
  background-color: rgba(0,0,0,0.5);
  color: #fff;
  z-index: 99;
}
.wrapper-small {
  height: 30px;
  width: 30px;
  max-width: unset;
  min-width: 24px;
}
.core-points-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83%;
  width: 83%;
  border-radius: 50%;
}
.tin-wrapper {
  background-color: #e1ecf8;
}
.tin-core {
  background-color: #c3daf1;
}
.bronze-wrapper {
  background-color: #ffd8a5;
}
.bronze-core {
  background-color: #edb86e;
}
.silver-wrapper {
  background-color: #e7e8ea;
}
.silver-core {
  background-color: #ceced0;
}
.gold-wrapper {
  background-color: #fbdb54;
}
.gold-core {
  background-color: #debb2c;
}
.platinum-wrapper {
  background-color: #bcc1c5;
}
.platinum-core {
  background-color: #a2a7aa;
}
.close-wrapper {
  position: relative;
  background-color: rgba(206,206,208,0.3);
}
.close-wrapper:after {
  content: '';
  position: absolute;
  right: -4px;
  bottom: 3px;
  width: 16px;
  height: 16px;
  background-image: url("../../assets/images/lock.svg");
}
.close-core {
  background-color: rgba(206,206,208,0.5);
}
@media screen and (max-width: 1124px) {
  .badges-container,
  .your-badges {
    flex-direction: column;
    max-width: 830px;
    width: 100%;
  }
  .badges-container__left-section,
  .your-badges__left-section {
    width: 100%;
    margin-bottom: 24px;
  }
  .badges-container__right-section,
  .your-badges__right-section {
    width: 100%;
  }
  .badges-container__badge-icon-section,
  .your-badges__badge-icon-section {
    max-width: 100%;
  }
  .badges-container__points-section,
  .your-badges__points-section {
    width: 100%;
  }
  .all-badges {
    max-width: 770px;
    width: 100%;
  }
  .all-badges__header {
    height: auto;
  }
  .all-badges--content {
    max-width: 523px;
    width: 100%;
  }
  .all-badges__roles-list-item {
    padding: 17px 20px;
  }
  .all-badges--roles-header-img {
    width: 26px;
    height: 26px;
  }
}
@media screen and (max-width: 768px) {
  .your-badges__header {
    height: auto;
    padding: 17px 24px;
  }
  .your-badges__header p {
    margin: 0;
  }
  .your-badges__loader-item {
    padding: 16px 26px;
    padding-bottom: 0;
  }
  .your-badges__loader-item:last-child {
    padding-bottom: 16px;
  }
  .all-badges__roles-list-item {
    padding: 17px 20px;
  }
  .all-badges__roles {
    padding: 30px;
  }
  .all-badges--roles-header-img {
    width: 26px;
    height: 26px;
  }
}
@media screen and (max-width: 560px) {
  .all-badges__roles {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
  }
  .all-badges__roles .your-badges__content-item {
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .all-badges--receive-data {
    text-align: right;
    width: 120px;
    min-width: 120px;
  }
}
.leaderboard__badges-cell .MuiBadge-anchorOriginTopRightCircle,
.all-badges__your-badges-list .MuiBadge-anchorOriginTopRightCircle,
.yourProgress_leaderboard_badges .MuiBadge-anchorOriginTopRightCircle,
.all-badges__roles-list-item_badge--center .MuiBadge-anchorOriginTopRightCircle {
  top: 0;
  right: 50%;
}
.billing {
  width: 100%;
}
.invoice-section a:not([href]):not([tabindex]) {
  cursor: auto;
}
.invoice-section .table-row-col-item {
  color: #757575 !important;
}
.border-color-grey {
  border-color: #e0e0e0 !important;
}
.budget-settings {
  width: 100%;
  max-width: 100%;
  margin: 25px auto;
}
.budget-settings .title {
  padding: 1rem;
}
.budget-settings .recruiter-budget-detail {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}
.card-settings {
  max-width: 780px;
  margin: 25px auto;
}
.card-settings__wrapper {
  padding: 20px 30px 20px 30px;
  margin-bottom: 25px;
}
.card-settings__wrapper h3 {
  margin-bottom: 20px;
}
.card-settings__card-number {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.card-settings__card-brand {
  height: 25px;
  width: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}
.communications-page .table tbody td:first-child a {
  position: relative;
  padding-left: 50px;
}
.communications-page .table tbody td:first-child a:before {
  content: '';
  display: block;
  position: absolute;
  left: 24px;
  top: 50%;
  margin-top: -4px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  vertical-align: middle;
}
.communications-page .table .general td:first-child a:before {
  background-color: #4ab6fe;
}
.communications-page .table .internal td:first-child a:before {
  background-color: #6d70ff;
}
.communications-page .table .referrer td:first-child a:before {
  background-color: #ff0a95;
}
.communications-page .table .subscription td:first-child a:before {
  background-color: #4cf8ee;
}
.communications-page .table .job td:first-child a:before {
  background-color: #ffb509;
}
.communications-page .table .testimonials td:first-child a:before {
  background-color: #8dff9a;
}
.communications-page .table .registration td:first-child a:before {
  background-color: #008000;
}
.communications-page .table .talent_board td:first-child a:before {
  background-color: #32012f;
}
.communications-page .table .talent_alert td:first-child a:before {
  background-color: #52012f;
}
.communications-page .checked-multiselect ul li:before {
  content: '';
  display: block;
  position: absolute;
  right: 44px;
  top: 50%;
  margin-top: -4px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background-color: #4ab6fe;
}
.communications-page .checked-multiselect ul li[data-value="general"]:before {
  background-color: #4ab6fe;
}
.communications-page .checked-multiselect ul li[data-value="internal"]:before {
  background-color: #6d70ff;
}
.communications-page .checked-multiselect ul li[data-value="referrer"]:before {
  background-color: #ff0a95;
}
.communications-page .checked-multiselect ul li[data-value="subscription"]:before {
  background-color: #4cf8ee;
}
.communications-page .checked-multiselect ul li[data-value="job"]:before {
  background-color: #ffb509;
}
.communications-page .checked-multiselect ul li[data-value="testimonials"]:before {
  background-color: #8dff9a;
}
.communications-page .checked-multiselect ul li[data-value="registration"]:before {
  background-color: #008000;
}
.communications-page .checked-multiselect ul li[data-value="talent_board"]:before {
  background-color: #32012f;
}
.communications-page .checked-multiselect ul li[data-value="talent_alert"]:before {
  background-color: #52012f;
}
.communication-subject .general {
  background-color: #4ab6fe;
}
.communication-subject .internal {
  background-color: #6d70ff;
}
.communication-subject .referrer {
  background-color: #ff0a95;
}
.communication-subject .subscription {
  background-color: #4cf8ee;
}
.communication-subject .job {
  background-color: #ffb509;
}
.communication-subject .testimonials {
  background-color: #8dff9a;
}
.communication-subject .registration {
  background-color: #008000;
}
.communication-subject .talent_board {
  background-color: #32012f;
}
.communication-subject .talent_alert {
  background-color: #52012f;
}
.mail-preview__modal .mail-preview__content {
  position: relative;
  padding: 10px;
  border-radius: 2px;
  box-shadow: rgba(0,0,0,0.12) 0px 1px 6px, rgba(0,0,0,0.12) 0px 1px 4px;
  margin-bottom: 10px;
  min-height: 460px;
  background-color: rgba(0,0,0,0.2);
  transition: background-color 0.3s ease;
}
.mail-preview__modal .mail-preview__content.general {
  background-color: #4ab6fe;
}
.mail-preview__modal .mail-preview__content.internal {
  background-color: #6d70ff;
}
.mail-preview__modal .mail-preview__content.referrer {
  background-color: #ff0a95;
}
.mail-preview__modal .mail-preview__content.subscription {
  background-color: #4cf8ee;
}
.mail-preview__modal .mail-preview__content.job {
  background-color: #ffb509;
}
.mail-preview__modal .mail-preview__content.testimonials {
  background-color: #8dff9a;
}
.mail-preview__modal .mail-preview__content.registration {
  background-color: #008000;
}
.mail-preview__modal .mail-preview__title {
  margin: 0 0 10px 0;
  text-shadow: 1px 1px 2px #808080;
  font-weight: 700;
}
.mail-preview__modal .mail-preview__body {
  width: 100%;
  height: 400px;
  border: 0;
  overflow: auto;
  box-shadow: rgba(0,0,0,0.12) 0px 1px 6px, rgba(0,0,0,0.12) 0px 1px 4px;
}
.mail-preview__modal .mail-preview__receivers {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6px;
}
.mail-preview__modal .mail-preview__receivers > div {
  text-overflow: ellipsis;
  max-width: 680px;
  overflow: hidden;
  margin: 5px 5px 0 0 !important;
}
.mail-preview__modal .mail-preview__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.consultants-page th:nth-child(5) > div {
  white-space: pre;
}
.consultants-page th:nth-child(6) > div {
  white-space: normal;
}
.dashboard {
  width: 100%;
}
.job-ads-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}
.job-ads-title span {
  font-size: 14px;
  margin-left: 10px;
  line-height: 20px;
}
@media screen and (max-width: 450px) {
  .job-ads-title span {
    margin-left: 0px;
  }
}
.edm-builder-section__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}
.edm-builder-section--title {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #757575;
}
.edm-builder-section__header {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 20px 36px;
}
.edm-builder-section--header {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9e9e9e;
}
.edm-builder-section--header-input {
  padding: 10px 33px 10px 16px;
  font-size: 36px;
  color: #fff;
  background-color: transparent;
  border: 1px dashed #fff;
}
.edm-builder-section--input {
  padding: 12px 16px !important;
  font-style: normal;
  font-size: 18px;
  color: #757575;
  border: 1px dashed #757575 !important;
  padding-right: 33px !important;
}
.edm-builder-section--white-input {
  padding: 12px 36px 12px 16px;
  font-style: normal;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: 1px dashed #fff;
}
.edm-builder-section--input-min {
  font-size: 14px;
}
.edm-builder-section__header-img .file-wrap {
  justify-content: center;
}
.edm-builder-section__header-img .file-wrap .file-btn-wrap {
  margin-top: 14px;
}
.edm-builder-section--img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.edm-builder-section .rdw-editor-main {
  background-color: #fff;
  padding: 14px;
  height: 130px;
}
.edm-builder-section__header-editor {
  width: 75%;
}
.edm-builder-section__header-editor .edm-builder-section--header-input,
.edm-builder-section__header-editor .edm-builder-section--input {
  width: 100%;
}
.edm-builder-section .dotted-border {
  border: 1px dotted;
}
.edm-builder-section .pad-1x {
  padding: 14px;
}
.edm-builder-section__container {
  padding: 24px 36px;
}
.edm-builder-section__container .MuiFormControl-root.MuiFormControl-fullWidth {
  margin-bottom: 0px;
  margin-top: 0px;
}
.edm-builder-section .white ::placeholder {
  color: #fff;
}
.edm-builder-section .white .error-text {
  color: #fff !important;
}
.edm-builder-section .font-bold {
  font-weight: bold;
}
.edm-builder-section .input-wrap {
  position: relative;
}
.edm-builder-section .pencil-icon {
  position: absolute;
  right: 7px;
}
.edm-builder-section .error-text {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 14px;
  color: #e53935;
  text-align: right;
  padding-right: 10px;
}
.edm-builder-section--job-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #5c5c5c;
  margin-bottom: 20px;
}
.edm-builder-section__search {
  max-width: 400px;
  width: 100%;
}
.edm-builder-section__jobs-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.edm-builder-section__jobs-list-empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edm-builder-section__empty-job {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 328px;
  min-width: 300px;
  max-width: 49%;
  border: 1px dashed #9e9e9e;
  color: #9e9e9e;
}
.edm-builder-section--img-wrap {
  display: inline-block;
  width: 20px;
  margin-right: 5px;
}
.edm-builder-section__job-item {
  display: flex;
  flex-direction: column;
  flex: 1 0 33.33333%;
  margin-bottom: 30px;
  padding: 25px 30px;
  width: 100%;
  min-width: 300px;
  max-width: 49%;
  min-height: 328px;
  position: relative;
  box-shadow: rgba(0,0,0,0.12) 0px 1px 6px, rgba(0,0,0,0.12) 0px 1px;
}
.edm-builder-section--job-item-header {
  margin-bottom: 0;
  padding-right: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}
.edm-builder-section--job-item-subtitle {
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(0,0,0,0.54);
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  color: #9e9e9e;
}
.edm-builder-section--pointer {
  cursor: pointer;
}
.edm-builder-section--job-item-content {
  min-height: 100px;
  font-size: 14px;
  padding: 10px 0;
  margin: 0;
  word-break: break-word;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  color: #757575;
}
.edm-builder-section--job-item-reward {
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #757575;
}
.edm-builder-section--job-item-close {
  margin-bottom: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  background: 0;
  color: #9e9e9e;
  cursor: pointer;
}
.edm-builder-section__job-item-icon {
  margin-top: 14px;
}
.edm-builder-section--job-item-icon {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #757575;
}
.edm-builder-section__alert-create {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 56px;
}
.edm-builder-section--create-job-alert-btn {
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.24), 0px 0px 2px rgba(0,0,0,0.12);
  border-radius: 2px;
  color: #35a243;
  padding: 14px 26px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 18px;
}
.edm-builder-section__empty-alert {
  margin-top: 40px;
  margin-bottom: 30px;
  border: 1px dashed #fff;
  width: 200px;
  height: 200px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: #fff;
}
.edm-builder-section__empty-alert .plus {
  font-size: 40px;
  line-height: 40px;
}
.edm-builder-section__icon-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 600px;
  width: 100%;
}
.edm-builder-section__icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  width: 33%;
  min-height: 150px;
  margin-top: 24px;
}
.edm-builder-section--icon-text {
  margin-bottom: 0;
  margin-top: 14px;
  max-height: 90px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 0 5px;
  width: 100%;
  overflow: hidden;
}
.edm-builder-section--icon-btn {
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
}
.edm-builder-section .icon-btn-edit {
  position: absolute;
  right: 0;
  top: -18px;
}
.edm-builder-section .icon-btn-remove {
  position: absolute;
  right: 0;
  top: 16px;
}
.edm-builder-section__sociale-links {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
}
.edm-builder-section--sociale-links {
  margin: 0 14px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edm-builder-section--instagram {
  background-color: #6091b2;
}
.edm-builder-section--facebook {
  background-color: #4a6ca7;
}
.edm-builder-section--linkedin {
  background-color: #006cac;
}
.edm-builder-section--twitter {
  background-color: #00b9ef;
}
.edm-builder-section__footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 30px;
  padding-right: 30px;
}
.font-bold {
  font-weight: bold;
}
.auto-complete-component-item {
  position: relative;
  padding: 18px 32px;
  border-bottom: 1px solid #eceff1;
  cursor: pointer;
}
.auto-complete-component-item p {
  padding: 0;
  margin: 0;
}
.auto-complete-component-item--content {
  font-size: 14px;
}
.auto-complete-component-item--plus-icon {
  position: absolute;
  top: 27%;
  right: 30px;
  font-size: 22px;
}
.auto-complete-component-item:last-child {
  border-bottom: none;
}
.auto-complete-component-item:hover {
  background-color: #eceff1;
}
@media screen and (max-width: 768px) {
  .edm-builder-section__header {
    padding: 20px 15px;
  }
  .edm-builder-section__header-editor {
    width: 100%;
    margin-right: 10px;
  }
  .edm-builder-section--header-input {
    font-size: 28px;
  }
  .edm-builder-section__title {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
  }
  .edm-builder-section__container {
    padding: 24px 15px;
  }
  .edm-builder-section--img {
    width: 70px;
    height: 70px;
  }
  .edm-builder-section__jobs-list {
    flex-direction: column;
  }
  .edm-builder-section__empty-job {
    max-width: 100%;
  }
  .edm-builder-section__job-item {
    max-width: 100%;
  }
  .edm-builder-section--sociale-links {
    width: 50px;
    height: 50px;
  }
}
.edm-preview-page {
  max-width: 770px;
  padding-bottom: 40px;
  padding-top: 40px;
}
.edm-preview-page--back {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #757575;
  text-decoration: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 166px;
  margin-bottom: 30px;
}
.edm-preview-page--back .icon-arrow {
  margin-top: 0;
}
.edm-preview-page .edm-builder__icon:hover {
  color: #fff;
  text-decoration: unset;
}
.edm-preview-page .edm-builder--create-job-alert-btn:hover {
  text-decoration: unset;
}
.edm-preview-page .edm-builder--social-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edm-preview-page .edm-builder--social-links {
  width: unset !important;
  height: unset !important;
}
.edm-preview-page .edm-builder__job-item,
.edm-preview-page .edm-builder__job-item-center {
  width: 45%;
}
.edm-preview-page .center-job {
  display: block;
}
.edm-preview-page .center {
  flex-wrap: wrap;
}
.edm-preview-page .edm-builder--job-item-content {
  padding-bottom: 10px;
}
.option--is-disabled,
.option--is-selected {
  display: none !important;
}
.error {
  width: 100%;
  min-height: 570px;
}
.error .error-block {
  max-width: 350px;
}
.error .error-left {
  display: flex;
  flex-direction: column;
}
.error .error-left h1 {
  margin-bottom: 35px;
}
.error .error-left h3 {
  font-weight: 300;
  margin-bottom: 35px;
}
.error .error-left h4 {
  font-weight: 300;
}
.error .error-right {
  max-width: 100%;
}
.error .static-link {
  margin-top: 30px;
}
@media screen and (max-width: 1023px) {
  .error-left h1 {
    font-size: 36px;
  }
  .error-left h4,
  .error-left h3 {
    font-size: 16px;
    line-height: 24px;
  }
  .error-right {
    display: none;
  }
}
.feedback-page .table-wrap .table tbody .review-star {
  padding: 15px 12px 15px 24px;
}
.response-box {
  border: 1px solid #ececec;
  padding: 10px 20px;
}
.feedback-page-modal .excellent-box {
  position: relative;
  display: block;
  width: 100%;
  background-color: #fcfcfc;
  border: 1px solid #ececec;
}
.feedback-page-modal .excellent-box .excellent-rating {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 20px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star {
  display: block;
  width: 70%;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul {
  padding: 0;
  list-style: none;
  margin: 0;
  display: inline-block;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li {
  font-size: 22px;
  margin: 0 1px;
  position: relative;
  display: inline-block;
  width: fit-content;
  font-size: 26px;
  color: #ffc107;
  margin: 0 2px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li.by {
  color: #d9d9d9;
  font-weight: 400;
  margin: 0 5px;
  font-size: 20px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li.Bruce {
  color: #515151;
  font-weight: 500;
  font-size: 20px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li.range {
  color: #35a28a;
  font-weight: 500;
  font-size: 18px;
}
.feedback-page-modal .excellent-box .excellent-rating .review-star ul li span {
  font-size: 16px;
  color: #515151;
  font-weight: 500;
  letter-spacing: 0.5px;
  background-color: #f2f2f2;
  padding: 2px 10px;
  margin-left: 5px;
}
.feedback-page-modal .excellent-box .excellent-rating .excelent-time {
  display: block;
  width: 30%;
  text-align: right;
}
.feedback-page-modal .excellent-box .excellent-rating .excelent-time p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: #9f9fa1;
  letter-spacing: 0.5px;
}
.feedback-page-modal .excellent-box .excelent-cont {
  padding: 0 20px;
}
.feedback-page-modal .excellent-box .excelent-cont h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: #515151;
  letter-spacing: 0.5px;
}
.feedback-page-modal .excellent-box .excelent-cont p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #828282;
  word-break: break-word;
  overflow: hidden;
}
.feedback-page-toggle-publish {
  padding: 0 20px;
  display: inline-block;
  text-align: left;
}
.feedback-page-comment {
  padding: 0 20px;
}
.feedback-page-comment-respond {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  color: #515151;
}
.feedback-page-comment-respond svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.feedback-page-comment-respond span {
  text-transform: uppercase;
  font-size: 14px;
}
.feedback-page-comment-response {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
  justify-content: space-between;
  color: #515151;
  font-size: 14px;
}
@media screen and (max-width: 550px) {
  .feedback-page-comment-response-public {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .feedback-page-comment-response-private {
    width: 100%;
  }
}
.feedback-page-comment-response-box {
  min-width: 370px;
}
@media screen and (max-width: 550px) {
  .feedback-page-comment-response-box {
    min-width: auto;
  }
}
.feedback-page-comment-response-box .MuiTextField-root {
  border: 1px solid #ececec;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #f6f6f5;
  margin-top: 5px;
}
.feedback-page-comment-response-box-social .MuiIconButton-root {
  padding: 5px;
}
.feedback-page-comment-response-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feedback-page-comment-response-title-icon {
  height: 20px;
}
.feedback-page-comment-response-title-icon svg {
  width: 20px;
  height: 20px;
}
.comment-box {
  margin-bottom: 15px;
}
.comment-box-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 15px;
}
.comment-box-inner-box {
  padding-left: 10px;
}
.comment-box-inner-box-title {
  color: #515151;
  font-weight: 500;
  font-size: 16px;
}
.comment-box-inner-box-response .MuiTextField-root {
  border: 1px solid #ececec;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #f6f6f5;
  margin-top: 5px;
}
.comment-box-buttons {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal-comment-box .comment-box-inner {
  padding-left: 0;
}
.dashboard-report-container {
  background-color: #f8f8f8;
}
.dashboard-report {
  margin: 20px 0;
}
.dashboard-report .toggle-button-group .MuiToggleButtonGroup-grouped {
  border: 0;
  border-radius: 5px;
  min-width: 110px;
}
.dashboard-report .toggle-button-group .MuiToggleButtonGroup-grouped.Mui-selected {
  background-color: #fff;
}
.dashboard-report .toggle-button-group .MuiToggleButtonGroup-grouped:not(.Mui-selected) {
  color: #7b7b7b !important;
}
.dashboard-report-filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.dashboard-report-filter-type {
  display: flex;
  background-color: #ebebeb !important;
  flex-wrap: wrap;
  max-width: 229px;
  margin-right: 20px;
  border-radius: 5px !important;
}
.dashboard-report-filter-date {
  max-width: 480px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.dashboard-report-filter-date .MuiFormHelperText-root {
  margin: 0;
}
.dashboard-report-filter-billing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.dashboard-report-filter-billing-value {
  background-color: #ebebeb !important;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 3px;
}
.dashboard-report .dashboard-chart-wrapper {
  margin-top: 15px;
}
.dashboard-report .dashboard-chart-wrapper h3 {
  letter-spacing: 0.48px;
  color: #2e364b;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 2px;
}
.dashboard-report .dashboard-chart-wrapper .dashboard-chart-data {
  padding: 70px 15px 15px 15px;
  min-height: 354px;
  position: relative;
}
.dashboard-report .dashboard-chart-wrapper .dashboard-chart-data .dashboard-chart-data-summary {
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.051);
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 5px 10px;
}
.hashtag-section--header-content {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
}
.hashtag-section .add-edit-hashtag {
  display: flex;
}
.hashtag-section .add-edit-hashtag--stepper {
  margin-right: 24px;
}
.hashtag-section .add-edit-hashtag--tegs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: 0;
  border-radius: 50px;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.hashtag-section .add-edit-hashtag--label-header {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}
.hashtag-section .add-edit-hashtag__video-section {
  margin-top: 29px;
}
.hashtag-section .add-edit-hashtag__loaded {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 15px;
}
.hashtag-section .add-edit-hashtag--loaded {
  width: 60px !important;
  height: 60px !important;
  min-width: 60px !important;
  margin-right: 14px;
  margin-top: 0 !important;
  cursor: pointer;
}
.hashtag-section .add-edit-hashtag--loaded .MuiButton-startIcon {
  margin: 0 !important;
}
.hashtag-section .add-edit-hashtag--loaded .MuiSvgIcon-root {
  margin-top: 0 !important;
}
.hashtag-section .add-edit-hashtag--loaded-content {
  margin: 0;
  color: #757575;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.hashtag-section .add-edit-hashtag--or {
  margin: 0;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}
.hashtag-section .add-edit-hashtag__input {
  margin-top: -15px;
  max-width: 352px;
}
.hashtag-section .file-input {
  cursor: pointer;
}
.hashtag-section .first-tag {
  position: relative;
  margin-bottom: 100px;
}
.hashtag-section .first-tag:after {
  content: '';
  position: absolute;
  top: 30px;
  height: 89px;
  border-left: 1px solid #d6d6d6;
}
.hashtag-section .hashtag-item {
  display: flex;
  margin-bottom: 24px;
}
.hashtag-section .hashtag-item__left-section {
  margin-right: 10px;
}
.hashtag-section .hashtag-item__right-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.hashtag-section .hashtag-item--img {
  width: auto;
  height: auto;
}
.hashtag-section .hashtag-item__video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 110px;
  position: relative;
  z-index: 2;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
.hashtag-section .hashtag-item--name {
  margin: 0 0 5px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: #757575;
}
.hashtag-section .hashtag-item__edit-remove {
  display: flex;
}
.hashtag-section .hashtag-item--edit {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.home-page-section {
  width: 100%;
}
.home-page-section .home {
  width: 100%;
  min-height: 800px;
  height: 100%;
  transition: background-color 0.4s ease-in-out;
}
.home-page-section .home .home-background div {
  background: -webkit-radial-gradient(circle, #0074bc, transparent);
  background: -ms-radial-gradient(circle, #0074bc, transparent);
  background: -o-radial-gradient(circle, #0074bc, transparent);
  background: radial-gradient(circle, #0074bc, transparent);
}
@supports (-webkit-filter: hue-rotate(360deg)) {
  .home-page-section .home .home-background div {
    background: -webkit-radial-gradient(circle, #4cf8ee, transparent);
    background: -ms-radial-gradient(circle, #4cf8ee, transparent);
    background: -o-radial-gradient(circle, #4cf8ee, transparent);
    background: radial-gradient(circle, #4cf8ee, transparent);
  }
}
@supports (filter: hue-rotate(360deg)) {
  .home-page-section .home .home-background div {
    background: -webkit-radial-gradient(circle, #4cf8ee, transparent);
    background: -ms-radial-gradient(circle, #4cf8ee, transparent);
    background: -o-radial-gradient(circle, #4cf8ee, transparent);
    background: radial-gradient(circle, #4cf8ee, transparent);
  }
}
.home-page-section .home .home-successful-img {
  margin-right: 15px;
}
.home-page-section .home .home-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.home-page-section .home .home-block h1 {
  margin-bottom: 35px;
}
.home-page-section .home .home-block h3 {
  font-weight: 300;
  margin-bottom: 35px;
}
.home-page-section .home .home-block h1,
.home-page-section .home .home-block h3 {
  position: relative;
}
.home-page-section--header {
  transform: translate(0, 30vh);
  transition: all 1s;
  opacity: 0;
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 54px;
  text-align: center;
}
.home-page-section--header-content {
  margin-bottom: 40px;
  width: 600px;
  transform: translate(0, 30vh);
  transition: all 1s;
  opacity: 0;
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}
.home-page-section__content {
  background-color: #fff;
}
.home-page-section__content-second {
  margin-top: -1px;
}
.home-page-section__integration-placements {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  transform: translate(0, 0);
  transition: all 1s;
  opacity: 0;
}
.home-page-section__placements-integration-section {
  width: 100%;
  max-width: 470px;
  margin: 0 15px;
  padding: 47px 36px;
  border-radius: 6px !important;
  transition: all 1s;
}
.home-page-section--integration-placements-header {
  margin-top: 24px;
  margin-bottom: 16px;
  color: #24253d;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
}
.home-page-section--integration-placements-content {
  max-width: 298px;
  margin-bottom: 30px;
  color: #24253d;
  font-size: 16px;
  line-height: 32px;
}
.home-page-section--integration-placements-link {
  text-decoration: none !important;
}
.home-page-section--integration-placements-img {
  margin-left: 10px;
}
.home-page-section__features {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translate(0, 50px);
  transition: all 1s;
  opacity: 0;
}
.home-page-section--features-content {
  max-width: 581px;
  margin-top: 36px;
  color: #24253d;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
}
.home-page-section--request {
  min-width: 148px !important;
  transform: translate(0, 30vh);
  opacity: 0;
  transition: all 1.5s;
}
.home-page-section .integration-page-footer__form {
  margin-bottom: 160px;
}
.home-page-section .integration-page-footer--input,
.home-page-section .integration-page-footer--input-home {
  width: 400px;
}
.home-page-section .integration-page-footer--congratulations {
  color: #24253d;
  font-weight: 400;
}
@media screen and (min-width: 1024px) {
  .home-page-section .a-on-enter .home-page-section__integration-placements {
    transform: translate(0, -140px);
    opacity: 1;
  }
  .home-page-section .a-on-enter .home-page-section__features {
    transform: translate(0, 0);
    opacity: 1;
  }
  .home-page-section .a-on-enter-animate .home-page-section--header,
  .home-page-section .a-on-enter-animate .home-page-section--header-content,
  .home-page-section .a-on-enter-animate .home-page-section--request {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@media screen and (max-width: 1024px) {
  .home-page-section .home-block h1 {
    font-size: 36px;
  }
  .home-page-section .home-block h3 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-page-section--header {
    transform: translate(0, 0);
    opacity: 1;
    transition: all 0;
  }
  .home-page-section--header-content {
    transform: translate(0, 0);
    opacity: 1;
    transition: all 0;
  }
  .home-page-section--request {
    transform: translate(0, 0);
    opacity: 1;
    transition: all 0;
  }
  .home-page-section__integration-placements {
    transform: translate(0, -140px);
    opacity: 1;
    transition: all 0s;
  }
  .home-page-section__integration {
    max-height: 700px;
  }
  .home-page-section__placements-integration-section {
    max-width: 600px;
    margin-bottom: 24px;
  }
  .home-page-section__features {
    transform: translate(0, 0);
    opacity: 1;
    transition: all 0s;
  }
  .home-page-section .integration-page-footer__form {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .home-page-section__placements-integration-section {
    padding: 37px 26px;
  }
  .home-page-section--header-content {
    width: 100%;
    font-size: 20px;
  }
  .home-page-section__integration {
    max-height: 650px;
  }
  .home-page-section .integration-page-footer--input {
    width: 300px;
  }
  .home-page-section .integration-page-footer--input-home {
    width: 200px;
  }
}
@media screen and (max-width: 768px) and (max-width: 460px) {
  .home-page-section__integration {
    max-height: 700px;
  }
  .home-page-section .integration-page-footer__form {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .home-page-section .integration-page-footer--input,
  .home-page-section .integration-page-footer--input-home {
    width: 50%;
  }
  .home-page-section .integration-page-footer--button {
    width: 150px !important;
    min-width: 150px !important;
  }
  .home-page-section .integration-page-footer--button button {
    width: 150px !important;
    min-width: 150px !important;
  }
}
@media screen and (max-width: 768px) and (max-width: 374px) {
  .home-page-section__integration {
    max-height: 750px;
  }
}
.integration-page-navigation {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .integration-page-navigation .navigation__btn:hover {
    background-color: #0074bc !important;
  }
}
.integration-page-navigation .navigation__btn:hover span {
  color: #fff !important;
}
@media screen and (max-width: 560px) {
  .integration-page-navigation .navigation__btn span {
    font-size: 10px !important;
  }
}
.header--sales .sales-page-navigation {
  right: 0;
  transform: translateX(0%);
  transition: right 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media screen and (max-width: 374px) {
  .header--sales .sales-page-navigation {
    padding-top: 15px;
  }
}
@media screen and (min-width: 560px) {
  .header--sales.is-minified .sales-page-navigation {
    position: relative;
    right: 50%;
    transform: translateX(50%);
  }
}
@media screen and (max-width: 560px) {
  .header--sales.is-minified .navigation__btn {
    min-width: 125px !important;
  }
}
@media screen and (min-width: 561px) {
  .header--sales.is-minified .navigation__btn {
    min-width: 150px !important;
  }
}
.integration-page {
  width: 100%;
  margin-top: 80px;
  position: relative;
  overflow: hidden;
  color: #24253d;
}
.integration-page .integration-page-header {
  height: 600px;
  position: relative;
  background-image: url("../../assets/images/integration_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.integration-page .integration-page-header .section-conversion__bg {
  display: none;
}
@media screen and (min-width: 768px) {
  .integration-page .integration-page-header .section-conversion__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/images/sales-page_users-illustration.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    transform: translate(0, 60%);
    transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    opacity: 0;
  }
}
@media screen and (min-width: 768px) {
  .integration-page .integration-page-header.a-on-enter .section-conversion__bg {
    transform: translate(0, 0%);
    opacity: 1;
  }
}
.integration-page .integration-page-header.a-on-enter .a-bottom-enter {
  transform: translate(0, 0%);
  opacity: 1;
}
.integration-page .integration-page-header__flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.integration-page .integration-page-header--header {
  max-width: 686px;
  font-size: 36px;
  font-weight: 300;
  line-height: 54px;
}
.integration-page .integration-page-header--content {
  margin-top: 27px;
  margin-bottom: 60px;
  max-width: 600px;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}
.integration-page .integration-page-ats {
  margin-top: 120px;
  margin-bottom: 120px;
}
.integration-page .integration-page-ats__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: all 1s;
  transform: translate(0, 50%);
  opacity: 0;
}
.integration-page .integration-page-ats--header {
  margin-bottom: 120px;
  max-width: 600px;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.integration-page .integration-page-ats__content {
  display: flex;
}
.integration-page .integration-page-ats__content-left {
  max-width: 430px;
  text-align: left;
  transition: all 1s;
  transform: translate(-50%, 0);
  opacity: 0;
}
.integration-page .integration-page-ats--content-section-header {
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.integration-page .integration-page-ats--content-section-active {
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
  color: #0074bc;
}
.integration-page .integration-page-ats--content-section {
  margin-top: 21px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
.integration-page .integration-page-ats__paper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  border-radius: 16px !important;
}
.integration-page .integration-page-ats--img {
  width: 70%;
}
.integration-page .integration-page-ats__content-right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  transition: all 1s;
  transform: translate(50%, 0);
  opacity: 0;
}
.integration-page .integration-page-ats--plus {
  margin-left: 18px;
  margin-right: 18px;
}
.integration-page .flex-column-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.integration-page .integration-page-solution--header {
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
}
.integration-page .integration-page-solution--header-content {
  max-width: 430px;
  margin-top: 18px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
.integration-page .integration-page-solution--info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px auto;
  max-width: 806px;
}
.integration-page .integration-page-solution__paper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  margin: 15px;
  border-radius: 12px !important;
  cursor: pointer;
}
.integration-page .integration-page-solution__paper:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
}
.integration-page .integration-page-solution--footer {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}
.integration-page .integration-page-contact {
  margin-top: 135px;
  background-image: url("../../assets/images/footer-integration-bg.png");
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 532px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.integration-page .integration-page-contact__icon {
  background-image: url("../../assets/sprites/integration-settings.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: all 1s;
  width: 48px;
  height: 48px;
  margin: 0 auto 36px;
  transform: scale(0);
}
.integration-page .integration-page-contact--header {
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.integration-page .integration-page-contact--content {
  max-width: 575px;
  margin-top: 17px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
.integration-page .integration-page-contact__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
}
.integration-page .integration-page-footer__container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  background-color: #0074bc;
  transition: all 1s;
  opacity: 0;
}
.integration-page .integration-page-footer__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.integration-page .integration-page-footer--header {
  margin-bottom: 56px;
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
}
.integration-page .integration-page-footer--input {
  width: 430px !important;
}
.integration-page--animation {
  transition: transform 1s, opacity 1s;
  transform: translate(0, 20vh);
  opacity: 0;
}
.integration-page .a-on-enter .integration-page-ats__section,
.integration-page .a-on-enter .integration-page-ats__content-left,
.integration-page .a-on-enter .integration-page-ats__content-right,
.integration-page .a-on-enter .integration-page--animation,
.integration-page .a-on-enter .integration-page-footer__container {
  transform: translate(0, 0);
  opacity: 1;
}
.integration-page .a-on-enter .integration-page-contact__icon {
  transform: scale(1);
}
@media screen and (max-width: 991px) {
  .integration-page {
    margin-top: 60px;
  }
  .integration-page .integration-page-ats {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .integration-page .integration-page-ats--header {
    margin-bottom: 50px;
    text-align: center;
  }
  .integration-page .integration-page-ats__content {
    flex-direction: column;
  }
  .integration-page .integration-page-ats--content-section-header {
    text-align: center;
  }
  .integration-page .integration-page-ats--content-section {
    max-width: 530px;
    text-align: center;
  }
  .integration-page .integration-page-ats__content-right {
    margin-left: 0;
    margin-top: 7px;
  }
  .integration-page .integration-page-solution--info {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .integration-page .integration-page-contact {
    margin-top: 80px;
    height: 480px;
  }
  .integration-page .integration-page-contact__content {
    margin-top: 100px;
  }
}
@media screen and (max-width: 640px) {
  .integration-page .integration-page-footer__form {
    width: 90%;
    display: flex;
  }
  .integration-page .integration-page-footer--input {
    width: 60% !important;
  }
}
@media screen and (max-width: 480px) {
  .integration-page .integration-page-ats__content-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .integration-page .integration-page-ats--plus {
    display: none;
  }
  .integration-page .integration-page-ats__paper:first-child {
    margin-bottom: 20px;
  }
  .integration-page .integration-page-footer__form {
    width: 100%;
    justify-content: center;
  }
  .integration-page .integration-page-footer--input {
    width: 56% !important;
  }
  .integration-page .integration-page-footer--button {
    min-width: 40% !important;
    width: 40% !important;
  }
  .integration-page .integration-page-footer--button button {
    min-width: 100% !important;
    width: 100% !important;
  }
  .integration-page .integration-page-footer--button span {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.invoice-wrapper {
  margin: 25px auto;
  padding: 20px 30px 50px;
  box-shadow: none !important;
}
.invoice-wrapper__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #9e9e9e;
}
.invoice-wrapper--darken {
  color: #757575;
}
.invoice-wrapper-popup-icon img {
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 3px;
}
.invoice-wrapper--color {
  color: #9e9e9e;
}
.invoice-wrapper--header-content {
  margin-bottom: 5px;
}
.invoice-wrapper--placements {
  font-size: 18px;
}
.invoice__subtotal {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.invoice__subtotal-item {
  display: flex;
  justify-content: space-between;
}
.invoice--subtotal-item {
  padding-right: 10px;
}
.invoice .table-row-col-item {
  color: #757575 !important;
  font-size: 14px;
}
.invoice__list-link {
  margin-top: 10px;
}
.invoice__button-confirm {
  display: flex;
  flex-wrap: wrap;
}
.invoice h3 {
  margin-bottom: 24px;
}
.invoice h4 {
  text-align: center;
  margin-bottom: 20px;
}
.invoice .m-bot-05 {
  margin-bottom: 5px !important;
}
.invoice__total {
  text-align: right;
}
.invoice__divider {
  margin: 20px 0 30px !important;
}
.invoice-message {
  margin-bottom: 40px;
}
.invoice-message > div {
  width: 100%;
  max-width: 325px;
}
.invoice .pad-top-3x {
  padding-top: 30px;
}
.invoice .m-bot-1x {
  margin-bottom: 14px;
}
.invoice .table-wrap {
  overflow: auto;
}
.invoice .table-wrap .table > div {
  overflow: visible !important;
}
.invoice .table-header__title {
  font-size: 16px;
}
.refari-invoice-breakdown-wrapper .setting-cont {
  margin-bottom: 20px;
}
.header-with-icon {
  position: relative;
}
.header-with-icon .comment-button {
  padding: 0 !important;
  margin: 0 !important;
  width: 23px !important;
  height: 23px !important;
  position: absolute !important;
  top: 0px !important;
  right: -30px !important;
}
.header-with-icon .comment-button svg {
  width: 20px !important;
  height: 20px !important;
  transform: rotate(180deg) !important;
}
.pad_left_5p {
  padding-left: 5px;
}
@media screen and (max-width: 560px) {
  .invoice__button-section {
    flex-direction: column-reverse;
  }
  .invoice__actions,
  .invoice .review-pdf-button {
    margin-top: 10px;
  }
}
.refari-pitch-me .custom-text-field hr {
  display: none !important;
}
.refari-pitch-me-top-block-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.refari-pitch-me-back-content {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  margin-top: 40px;
  justify-content: space-between;
}
.refari-pitch-me-back-content-share {
  display: flex;
}
.refari-pitch-me-head-title {
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 25px;
  letter-spacing: 1.2px;
  font-size: 36px;
  line-height: 54px;
  font-family: inherit;
  padding-left: 5px;
}
.refari-pitch-me-desc {
  font-weight: normal;
  font-size: 15px;
  font-family: inherit;
  padding-left: 5px;
  margin-bottom: 0;
  margin-top: 0;
}
.refari-pitch-me-divider {
  border: none;
  color: transparent;
  background-color: transparent;
  padding: 35px 0 25px 0;
}
.refari-pitch-me-divider:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  max-width: 100%;
  width: 100%;
  border: 1px solid #ccc;
}
.refari-pitch-me-button-block {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  top: 0;
}
.refari-pitch-me-button-block .refari-select-wrap {
  margin-top: 5px !important;
}
.refari-pitch-me-button-block-fixed {
  position: fixed;
}
.refari-pitch-me-preview-loader {
  margin-right: 15px;
  padding-top: 5px;
}
.refari-pitch-me-create-loader {
  margin-left: 15px;
  padding-top: 5px;
}
.refari-pitch-me-heading {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.refari-pitch-me-heading-title-desc {
  flex-grow: 1;
}
.refari-pitch-me-heading-avatar {
  margin-left: 15px;
}
.refari-pitch-me-heading-avatar .refari-avatar-wrap {
  max-width: 167px;
  max-height: 167px;
  border-radius: 0 !important;
}
.refari-pitch-me-heading-avatar .refari-avatar-wrap img {
  border-radius: 0 !important;
}
.refari-pitch-me-heading-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.refari-pitch-me-heading-profile .MuiFormControlLabel-root {
  margin-right: 0;
  margin-bottom: 0;
}
.refari-pitch-me-heading--title-input {
  font-size: 36px;
  color: #757575;
  background-color: transparent;
  width: 100% !important;
}
.refari-pitch-me-heading--desc-input {
  margin-bottom: 0 !important;
  color: #757575;
  font-size: 16px;
  line-height: 24px;
  font-family: inherit;
}
.refari-pitch-me-heading--header-input {
  padding: 10px 33px 10px 16px;
  font-size: 36px;
  color: #757575;
  background-color: transparent;
  border: 1px dashed #757575 !important;
  width: 100% !important;
}
.refari-pitch-me-heading-closing-input,
.refari-pitch-me-heading-regards-input,
.refari-pitch-me-heading-description-input {
  width: 100% !important;
  padding: 12px 16px !important;
  font-style: normal;
  font-size: 18px;
  color: #757575;
  border: 1px dashed #757575 !important;
  padding-right: 33px !important;
  margin-top: 0px !important;
}
.refari-pitch-me-heading-closing-input textarea,
.refari-pitch-me-heading-regards-input textarea,
.refari-pitch-me-heading-description-input textarea {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  color: #757575 !important;
}
.refari-pitch-me-heading .input-wrap {
  position: relative;
  width: 100%;
}
.refari-pitch-me-heading .input-wrap textarea {
  background: transparent;
}
.refari-pitch-me-heading .pencil-icon {
  position: absolute;
  right: 7px;
  top: 0;
}
.refari-pitch-me-heading .m-t-2 {
  margin-top: 20px;
}
.refari-pitch-me-block {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.refari-pitch-me-block-title {
  flex: 1;
  margin: 0;
  padding-left: 5px;
}
.refari-pitch-me-block-title-help-input,
.refari-pitch-me-block-title-expertise-input,
.refari-pitch-me-block-title-job-input,
.refari-pitch-me-block-title-client-input {
  color: #000;
  font-weight: 700;
  margin: 0;
  font-size: 23px;
  line-height: 36px;
  font-family: inherit;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
}
.refari-pitch-me-block-title-editor {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: center;
}
.refari-pitch-me-block-title svg {
  margin-right: 10px;
}
.refari-pitch-me-block-toggle {
  flex: 1;
  text-align: right;
}
.refari-pitch-me-block-toggle .MuiFormControlLabel-root {
  margin-right: 0;
  margin-bottom: 0;
}
.refari-pitch-me-help {
  margin-top: 50px;
}
.refari-pitch-me-video-wrapper {
  position: relative;
  width: 100%;
  height: 600px;
}
.refari-pitch-me-video-wrapper iframe,
.refari-pitch-me-video-wrapper .refari-video-dimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.refari-pitch-me-video-wrapper .refari-video-dimmer {
  background-position: center;
  background-size: cover;
}
.refari-pitch-me-video-wrapper .refari-video-dimmer-change-link {
  border: 1px dashed #757575;
}
.refari-pitch-me-video-wrapper .refari-play-button,
.refari-pitch-me-video-wrapper .refari-pitch-me-video-change-link-block {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.refari-pitch-me-video-wrapper .refari-play-button-icon,
.refari-pitch-me-video-wrapper .refari-pitch-me-video-change-link-block-icon {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 120px;
  border-color: transparent transparent transparent #fff;
  transition: 100ms all ease;
  cursor: default;
  border-style: solid;
  border-width: 90px 0 90px 120px;
}
.refari-pitch-me-video-wrapper .refari-pitch-me-video-change-link-block {
  max-width: 500px;
  width: 90%;
}
.refari-pitch-me-expertise {
  margin-top: 50px;
}
.refari-pitch-me-expertise .react-tagsinput {
  border: none !important;
}
.refari-pitch-me-expertise .refari-react-tagsinput-input {
  min-width: 100%;
}
.refari-pitch-me-expertise__search {
  max-width: 400px;
}
.refari-pitch-me-expertise-job-selected {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.refari-pitch-me-expertise-job-selected svg {
  color: #fff;
}
.refari-pitch-me-expertise-job-item {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}
.refari-pitch-me-expertise-item {
  border: 10px;
  font-family: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-decoration: none;
  margin: 0px;
  margin-right: 10px;
  outline: none;
  font-size: 18px;
  font-weight: inherit;
  background-color: #65707b;
  border-radius: 22px;
  color: #fff;
  width: fit-content;
  padding: 8px 20px !important;
}
.refari-pitch-me-placed {
  margin-top: 50px;
}
.refari-pitch-me-placed__search {
  max-width: 400px;
}
.refari-pitch-me-placed-section {
  margin-top: 25px;
}
.refari-pitch-me-placed-job-selected {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.refari-pitch-me-placed-job-item {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  width: fit-content;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 15px;
}
.refari-pitch-me-placed-job-item-header {
  margin: 0;
  line-height: 1.5;
  font-size: 15px;
  font-weight: bold;
}
.refari-pitch-me-placed-job-item-subtitle {
  margin: 0;
  line-height: 1.5;
  font-size: 13px;
  font-weight: normal;
}
.refari-pitch-me-placed-job-item-close {
  border: 0;
  background: 0;
  color: #9e9e9e;
  cursor: pointer;
  font-size: 25px;
  margin: 0;
  padding: 0;
  line-height: 0px;
  margin-left: 20px;
}
.refari-pitch-me-testimonial-section {
  margin-top: 25px;
}
.refari-pitch-me-testimonial__search {
  max-width: 400px;
}
.refari-pitch-me-testimonial-job-selected {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.refari-pitch-me-testimonial-job-item {
  position: relative;
  height: 396px;
  text-align: left;
  padding: 10px;
  width: 25%;
}
.refari-pitch-me-testimonial-job-item-inner {
  padding: 23px;
  height: 330px;
}
.refari-pitch-me-testimonial-job-item-quotes {
  position: absolute;
  top: 0;
  left: 0;
}
.refari-pitch-me-testimonial-job-item-content {
  overflow: hidden;
  height: 160px;
  margin-bottom: 18px;
  margin-top: 0px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}
.refari-pitch-me-testimonial-job-item-content-small {
  height: 120px;
}
.refari-pitch-me-testimonial-job-item-star {
  padding: 0;
  margin: 0;
  max-height: 30px;
}
.refari-pitch-me-testimonial-job-item__verified {
  display: inline-block;
}
.refari-pitch-me-testimonial-job-item--verified-show {
  display: inline-block;
}
.refari-pitch-me-testimonial-job-item--verified {
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #27ae60;
}
.refari-pitch-me-testimonial-job-item--verified a {
  color: #27ae60;
}
.refari-pitch-me-testimonial-job-item--verified-hide {
  display: none;
}
.refari-pitch-me-testimonial-job-item:hover .refari-pitch-me-testimonial-job-item--verified-show {
  display: none;
}
.refari-pitch-me-testimonial-job-item:hover .refari-pitch-me-testimonial-job-item--verified-hide {
  display: inline-block;
}
.refari-pitch-me-testimonial-job-item-bottom-section {
  position: absolute;
  bottom: 76px;
  width: calc(100% - 66px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.refari-pitch-me-testimonial-job-item-name {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}
.refari-pitch-me-testimonial-job-item-occupation {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}
.refari-pitch-me-testimonial-job-item-date {
  margin-bottom: 3px;
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #9e9e9e;
}
.refari-pitch-me-testimonial-job-item-close-section {
  width: 100%;
  text-align: center;
}
.refari-pitch-me-testimonial-job-item-close {
  border: 0;
  background: 0;
  color: #9e9e9e;
  cursor: pointer;
  font-size: 25px;
  margin: 0;
  padding: 0;
  line-height: 0px;
  text-align: center;
  margin-top: 10px;
}
.refari-pitch-me-regards {
  width: 300px;
  margin-right: 30px;
}
.refari-pitch-me-regards-section {
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
  flex-wrap: wrap;
  width: 100%;
}
.refari-pitch-me-call-to-action {
  display: flex;
  width: calc(100% - 330px);
}
.refari-pitch-me-call-to-action-button-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}
.refari-pitch-me-call-to-action-box {
  min-height: 134px;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;
}
.refari-pitch-me-call-to-action-box-inner {
  display: flex;
  align-items: center;
}
.refari-pitch-me-call-to-action .react-draggable {
  width: fit-content;
}
.refari-pitch-me .auto-complete-component-item {
  position: relative;
  padding: 18px 32px;
  border-bottom: 1px solid #eceff1;
  cursor: pointer;
}
.refari-pitch-me .auto-complete-component-item p {
  padding: 0;
  margin: 0;
}
.refari-pitch-me .auto-complete-component-item--content {
  font-size: 14px;
}
.refari-pitch-me .auto-complete-component-item--plus-icon {
  position: absolute;
  top: 27%;
  right: 30px;
  font-size: 22px;
}
.refari-pitch-me .auto-complete-component-item:last-child {
  border-bottom: none;
}
.refari-pitch-me .auto-complete-component-item:hover {
  background-color: #eceff1;
}
.refari-pitch-me-testimonial-job-item-content .refari-pitch-me-testimonial-job-item-content-read-more {
  display: inline;
  font-size: 14px;
  text-decoration: underline;
}
.refari-pitch-me-preview .refari-pitch-me-block {
  margin-bottom: 20px;
}
.refari-pitch-me-preview .refari-pitch-me-block-title {
  padding-left: 0px;
}
.refari-pitch-me-preview .refari-pitch-me-expertise-section {
  display: flex;
}
.refari-pitch-me-preview .refari-pitch-me-testimonial-job-item {
  height: 350px;
}
.refari-pitch-me-preview .refari-pitch-me-testimonial-job-selected .refari-pitch-me-testimonial-job-item:first-child {
  padding-left: 0;
}
.refari-pitch-me-preview .refari-pitch-me-testimonial-job-selected .refari-pitch-me-testimonial-job-item:last-child {
  padding-right: 0;
}
.refari-pitch-me-preview .refari-pitch-me-closing {
  margin-bottom: 0 !important;
  margin-top: 40px;
  color: #757575;
  font-size: 18px;
  line-height: 24px;
  font-family: inherit;
}
.refari-pitch-me-preview .refari-pitch-me-regards {
  color: #757575;
  font-size: 22px;
  line-height: 30px;
  font-family: inherit;
  margin-top: 40px;
}
.refari-pitch-me-preview .refari-pitch-me-call-to-action {
  margin-top: 35px;
}
.no-drag-area {
  position: absolute;
  user-select: none;
  width: 20px;
  height: 20px;
  right: -10px;
  bottom: -10px;
}
.refari-skeleton .refari-pitch-me-button-block {
  display: flex;
  justify-content: flex-end;
}
.refari-skeleton .refari-pitch-me-button-block > div {
  margin-left: 20px;
}
.refari-skeleton .refari-pitch-me-block-title {
  padding-left: 0px !important;
}
.refari-skeleton .refari-pitch-me-expertise-skeleton {
  display: flex;
  margin-top: 20px;
}
.refari-skeleton .refari-pitch-me-expertise-skeleton > div {
  margin-right: 15px;
}
.link.refari-detail-back-link {
  margin-bottom: 15px;
  display: inline-flex;
}
.refari-link.refari-detail-back-link {
  margin-bottom: 15px;
  display: inline-flex;
}
.refari-detail-back-button {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  color: #2b2b2b;
}
.refari-detail-back-button:hover .refari-detail-back-button--hover {
  text-decoration: underline;
  color: #2b2b2b;
}
.refari-detail-back-button--hover {
  margin-left: 5px;
}
.refari-detail-top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.refari-detail-top-header .refari-link.refari-detail-back-link {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
@media screen and (max-width: 600px) {
  .refari-detail-top-header-right button {
    margin-bottom: 10px;
  }
}
.refari-detail-top-header-right-pitch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.refari-avatar-wrap {
  width: 240px;
  height: 240px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
}
.refari-avatar-wrap-not-round {
  border-radius: 0% !important;
}
.refari-avatar-wrap-not-round > img {
  border-radius: 0% !important;
}
.refari-avatar-wrap > img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
@media screen and (max-width: 1140px) {
  .refari-pitch-me-testimonial-job-item {
    width: 33%;
  }
}
@media screen and (max-width: 850px) {
  .refari-pitch-me-testimonial-job-item {
    width: 50%;
  }
}
@media screen and (max-width: 570px) {
  .refari-pitch-me-testimonial-job-item {
    width: 100%;
  }
}
.refari-placements-wrapper .table {
  width: 100%;
}
.refari-placements-wrapper .modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
}
.refari-placements-wrapper .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.refari-placements-wrapper .modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.refari-placements-wrapper .modal-body.crm-status-body {
  padding: 0 1rem 1rem 1rem;
}
.refari-placements-wrapper .modal-body .crm-status-no-data-found {
  margin: 10px 0;
  text-align: center;
}
.refari-placements-wrapper .modal-body .crm-status-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 20px;
}
.refari-placements-wrapper .modal-body .crm-status-list-li {
  flex: 0 1 40%;
  box-sizing: border-box;
  width: 100%;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-name {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 15px;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-name-title {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.2px;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-name-help {
  display: flex;
  padding-left: 10px;
  align-items: center;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.refari-placements-wrapper .modal-body .crm-status-list-li-ul-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.refari-placements-wrapper .modal-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  color: #404144;
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 0;
}
.refari-placements-wrapper .modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  border: 0;
  background: transparent;
}
.refari-placements-wrapper button.close {
  position: absolute;
  display: block;
  right: 15px;
  top: 10px;
  z-index: 5;
  outline: none;
  font-size: 30px;
}
.refari-placements-wrapper .close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.refari-placements-wrapper .setting-tab .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.refari-placements-wrapper .setting-tab .nav-link {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
}
.refari-placements-wrapper .setting-tab ul li.nav-item {
  width: 25%;
  text-align: center;
  cursor: pointer;
}
.refari-placements-wrapper .setting-tab ul li.nav-item .nav-link {
  font-size: 14px;
  line-height: 30px;
  color: #404144;
  letter-spacing: 0.3px;
  border: 1px solid #ececec;
  border-radius: 0;
}
.refari-placements-wrapper .setting-tab ul li.nav-item .nav-link.active {
  background-color: transparent;
  color: #3886d1;
  border-bottom: none;
  border-top: 2px solid #3886d1;
}
.refari-placements-wrapper .setting-title h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #404144;
  margin: 0;
}
.refari-placements-wrapper .setting-title h5 span {
  color: #3886d1;
}
.refari-placements-wrapper .setting-title {
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.refari-placements-wrapper .table thead th {
  vertical-align: middle;
  border-bottom: 1px solid #ececec;
  border-top: none;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #9f9fa1;
  padding: 0 12px;
}
.refari-placements-wrapper .setting-cont .table input {
  float: left;
  margin-top: 4px;
  margin-right: 16px;
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
}
.refari-placements-wrapper .Donald {
  position: relative;
  display: inline-block;
  width: 30px;
  margin-right: 10px;
}
.refari-placements-wrapper .Donald img {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.refari-placements-wrapper .Donald .img-fluid-circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: #ccc;
  vertical-align: middle;
  border-style: none;
}
.refari-placements-wrapper .page-preload-spinner-table {
  border-bottom: 0 !important;
  text-align: center !important;
}
.refari-placements-wrapper .hnt-img {
  float: right;
  cursor: pointer;
}
.refari-placements-wrapper .pagination-wrap {
  margin-top: 15px;
}
.refari-placements-wrapper .setting-cont .table td {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #2e364b;
  padding: 10px 10px;
  max-width: 150px;
}
.refari-placements-wrapper .setting-cont .table td label {
  margin: 0;
}
.refari-placements-wrapper .setting-cont .table tr {
  border-bottom: 1px solid #ececec;
}
.refari-placements-wrapper .setting-cont .table-responsive {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}
.refari-placements-wrapper .setting-btn {
  margin: 20px 0;
  text-align: center;
}
.refari-placements-wrapper .setting-btn .align-items-center {
  padding-bottom: 20px;
}
.refari-placements-wrapper .popover-body {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.28px;
  color: #757575;
  padding: 15px;
}
.refari-placements-wrapper .popover {
  box-shadow: 0 0 15px rgba(0,0,0,0.14);
  border: none;
}
.refari-placements-wrapper button.close {
  position: absolute;
  display: block;
  right: 15px;
  top: 10px;
  z-index: 5;
  outline: none;
  font-size: 30px;
}
.refari-placements-wrapper .setting-cont .table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}
.refari-placements-wrapper .setting-cont .table-responsive::-webkit-scrollbar {
  width: 8px;
  background-color: #cfcfcf;
}
.refari-placements-wrapper .setting-cont .table-responsive::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #cfcfcf;
}
.refari-placements-wrapper .round {
  position: relative;
  display: flex;
  align-items: center;
}
.refari-placements-wrapper .round.disabledCheckbox svg {
  opacity: 0.5 !important;
}
.refari-placements-wrapper .round > div {
  height: 20px !important;
  width: 20px !important;
}
.modal-container-without-left .refari-modal > div {
  border-radius: 5px !important;
}
.modal-container-without-left .refari-modal > div > div {
  overflow-x: hidden;
}
.tooltip-container {
  background-color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 15px rgba(0,0,0,0.14);
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
  padding: 0.4rem;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  transition: opacity 0.3s;
  z-index: 2147483647;
}
.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
}
.tooltip-arrow::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}
.tooltip-arrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}
.tooltip-arrow[data-placement*='bottom'] {
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;
}
.tooltip-arrow[data-placement*='bottom']::before {
  border-color: transparent transparent #c0c0c0 transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}
.tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent #fff transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}
.tooltip-arrow[data-placement*='top'] {
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;
}
.tooltip-arrow[data-placement*='top']::before {
  border-color: #c0c0c0 transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}
.tooltip-arrow[data-placement*='top']::after {
  border-color: #fff transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}
.tooltip-arrow[data-placement*='right'] {
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;
}
.tooltip-arrow[data-placement*='right']::before {
  border-color: transparent #c0c0c0 transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}
.tooltip-arrow[data-placement*='right']::after {
  border-color: transparent #fff transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}
.tooltip-arrow[data-placement*='left'] {
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;
}
.tooltip-arrow[data-placement*='left']::before {
  border-color: transparent transparent transparent #c0c0c0;
  border-width: 0.5rem 0 0.5rem 0.4em;
}
.tooltip-arrow[data-placement*='left']::after {
  border-color: transparent transparent transparent #fff;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
.tooltip-body {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.28px;
  color: #757575;
  padding: 15px;
  max-width: 300px;
}
@media screen and (max-width: 480px) {
  .refari-placements-wrapper .setting-cont .table-responsive {
    overflow: auto;
  }
}
.refari-phone-code-body {
  padding: 0 4rem 2rem !important;
}
@media screen and (max-width: 480px) {
  .refari-phone-code-body {
    padding: 1rem 0 !important;
  }
}
.question-mapping-container {
  max-width: inherit !important;
}
.question-mapping-section__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0,0,0,0.122) 0px 1px 6px, rgba(0,0,0,0.122) 0px 1px 4px;
}
.question-mapping-section__tab_heading {
  box-shadow: none !important;
}
.question-mapping-section__tab_heading .Mui-selected {
  box-shadow: 0 1px 5px rgba(0,0,0,0.129), 0 1px 2px rgba(0,0,0,0.129);
}
.question-mapping-section__tab_heading .MuiTab-root {
  margin-right: 5px;
}
.question-mapping-section-add-question {
  padding: 0 15px;
}
.question-mapping-section-questions-td {
  font-weight: 500;
  line-height: 2;
  padding: 5px 0;
  font-size: 14px;
  position: relative;
}
.question-mapping-section-questions-td .anchor-txt {
  padding-left: 15px;
}
.question-mapping-section-questions-td .settings-icon-box {
  position: absolute;
  right: 0;
  top: 5px;
}
.question-mapping-section-questions-td .delete-icon-box {
  position: absolute;
  right: 30px;
  top: 5px;
}
.question-mapping-section-questions-item {
  text-indent: -1em;
  padding-left: 1em;
  padding-right: 30px;
}
.question-mapping-section-questions-item span {
  font-weight: 300;
  font-size: 13px;
  color: #7a7a7a;
}
.question-mapping-section-jobs-td {
  line-height: 2;
  padding: 5px 0;
  font-size: 14px;
  position: relative;
}
.question-mapping-section-jobs-td .anchor-txt {
  position: absolute;
  right: 0;
  bottom: 5px;
}
.question-mapping-section-jobs-data {
  width: calc(100% - 55px);
}
.question-mapping-section-jobs-item {
  hyphens: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.question-mapping-section-candidate-main-td {
  min-height: 80px;
}
.question-mapping-section-candidate-td {
  border: 1px solid #ccc;
  padding: 5px 10px 0 15px;
  margin: 5px 0px;
  border-radius: 5px;
}
.question-mapping-section-candidate-td hr {
  display: none !important;
}
.question-mapping-section-settings-td .MuiFormControl-root.MuiFormControl-fullWidth {
  margin-top: 0;
  margin-bottom: 8px;
}
.question-mapping-section-settings-update-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.question-mapping-section-settings-all-buttons {
  display: flex;
  justify-content: space-between;
}
.question-mapping-section .anchor-txt {
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
}
.question-mapping-section .clear-data-btn {
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  text-align: right;
}
.question-mapping-section .clear-data-btn span {
  cursor: pointer;
}
.question-mapping-section-table-body tr {
  border-bottom: 2px solid #ccc !important;
}
.question-mapping-section-table-body td {
  white-space: unset !important;
  vertical-align: top;
  background-color: inherit !important;
  border-right: 2px solid #ccc !important;
}
.question-mapping-section-table-body td:last-child {
  border-right: none !important;
}
.question-mapping-section-answer-modal-question {
  font-weight: 500;
  color: #404144;
  font-size: 16px;
  margin: 10px;
}
.question-mapping-section-answer-modal-answer {
  font-weight: normal;
  font-size: 15px;
  margin: 15px 10px;
  color: #757575;
}
.question-mapping-section-answer-modal-wrapper .refari-field-tags-wrap {
  margin-top: 10px;
}
.empty-value {
  color: #ccc !important;
}
.settings-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
}
.settings-section .refari-select-wrap {
  width: 40%;
}
.settings-section .MuiCheckbox-root {
  padding-bottom: 0;
  padding-top: 0;
}
.settings-section .MuiFormControlLabel-root {
  margin: 0;
}
.refari-add-questions-label {
  font-size: 12px;
  color: #515151;
}
.refari-add-questions-value {
  font-size: 16px;
  margin-top: 5px;
}
.refari-add-questions-question {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.refari-add-questions-question-value {
  border: 1px solid #ccc;
  padding: 5px 10px;
}
.refari-add-questions-ul {
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: scroll;
}
.refari-add-questions-ul-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 5px 7px 15px;
  border-bottom: 1px solid #ccc;
}
.reports {
  width: 100%;
  margin-bottom: 48px;
}
.reports .reports-container {
  margin-bottom: 5px;
}
.report-blur-image {
  background: url("../../assets/images/review-blur-image.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: calc(100% + 48px);
  min-height: 600px;
}
.rating-blur-image {
  background: url("../../assets/images/rating-blur-image.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 600px;
}
.ai-budget-blur-image {
  background: url("../../assets/images/ai-budget-blr-image.webp") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 600px;
}
.talent-alert-blur-image {
  background: url("../../assets/images/talent-alert-blur-image.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 600px;
}
.consultant-box {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #f8f8f8;
  height: 100%;
}
.consultant-boxes {
  max-height: 850px;
  overflow-y: scroll;
  padding-right: 20px;
}
.consultant-boxes .pagination-wrap {
  margin-top: 10px;
}
.consultant-boxes .pagination-wrap .MuiPaginationItem-root {
  width: 20px;
  min-width: 20px;
  height: 20px;
}
.recruiter-header h5 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #3886d1;
  margin: 0;
  line-height: 30px;
  text-transform: uppercase;
}
.recruiter-box {
  display: block;
  width: 255px;
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
  background-color: #fff;
  box-shadow: 14px 0px 15px rgba(234,234,234,0.2);
}
.select-recruiter-button {
  display: none;
  width: 8%;
}
.select-recruiter-button .MuiButton-startIcon {
  margin-right: 0;
}
.recruiter-input {
  position: relative;
  display: block;
  width: 100%;
  background-color: #f9f9f9;
  border: 0.5px solid #d9d9d9;
  font-size: 12px;
  font-weight: 300;
  line-height: 30px;
  color: #9f9fa1;
  letter-spacing: 0.3px;
  padding: 2px 15px;
  padding-left: 30px;
  border-radius: 2px;
  transition: 0.5s all ease;
}
.search-box {
  position: relative;
  display: block;
  width: 100%;
}
.search-icon {
  position: absolute;
  display: block;
  width: fit-content;
  top: 6px;
  left: 5px;
  color: #9f9fa1;
}
.found-box {
  position: relative;
  width: 100%;
  align-items: center;
  margin: 5px 0;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 20px;
}
.recruiter-header {
  padding-left: 15px;
  padding-right: 20px;
}
.found-title h5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #3886d1;
  margin: 0;
}
.found-title {
  display: block;
  width: 100%;
  min-height: 30px;
}
.found-link {
  display: block;
  width: 100%;
}
.found-link a {
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #757575;
  border: 0.2px solid #404144;
  padding: 4px 10px;
  text-decoration: none;
}
.found-link .dropdown-item {
  border: none;
  font-size: 14px;
}
.conslt-box {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 6px 0;
  border-left: 3px solid transparent;
  padding-left: 20px;
  border-bottom: 0.5px solid #d9d9d9;
  text-decoration: none;
  transition: 0.5s all ease;
}
.consultant-cont img {
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}
.consultant-cont .img-fluid-circle,
.graph-tab-header .img-fluid-circle {
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: inline-block;
  background-color: #ccc;
  vertical-align: middle;
  border-style: none;
}
.consultant-cont {
  display: block;
  width: 85%;
}
.consultant-arrow {
  display: block;
  width: 15%;
  text-align: right;
}
.consultant-cont {
  display: block;
  width: 85%;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #2e364b;
}
.conslt-box:hover,
.conslt-box.active {
  background: transparent linear-gradient(90deg, rgba(162,210,255,0.341) 0%, rgba(255,255,255,0) 71%, #fff 100%) 0% 0% no-repeat padding-box;
  border-left: 3px solid #3886d1;
  text-decoration: none;
  transition: 0.5s all ease;
}
.consultant-area {
  position: relative;
  display: block;
  width: calc(100% - 255px);
}
.dashboard-graph-tab {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eceff1;
  padding: 10px 30px;
  height: 61px;
}
.graph-tab-header {
  display: block;
  width: 25%;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #2e364b;
}
.graph-tab {
  display: block;
  width: 75%;
}
.dashboard-graph {
  position: relative;
  display: block;
  width: 85%;
  background-color: #fff;
  margin: 20px;
  box-shadow: 0 0 15px rgba(234,234,234,0.6);
}
.graph-tab-header img {
  border-radius: 50%;
  margin-right: 15px;
  width: 40px;
  height: 40px;
}
.graph-tab .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.graph-tab ul li span.nav-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.28px;
  color: #9f9fa1;
  padding: 0;
  margin: 0 16px;
}
.graph-tab ul li span.nav-link.active {
  background-color: transparent;
  color: #3886d1;
}
.graph-tab ul li span.nav-link.active:after {
  position: absolute;
  display: block;
  content: '';
  border-top: 1px solid #3886d1;
  width: 100%;
  bottom: 0;
  left: 0;
}
.graph-area {
  min-height: 450px;
}
.graph-tab ul li.nav-item {
  position: relative;
  padding: 15px 0px;
  cursor: pointer;
}
.chart-box {
  width: 75%;
  display: block;
  position: relative;
  margin: 10px auto 0 auto;
}
.avg-rating-star ul li {
  position: relative;
  display: inline-block;
  color: #ffbf00;
}
.avg-rating-star ul {
  list-style: none;
  margin: 0;
}
.rate-cont {
  display: block;
  width: 50%;
  align-self: flex-end;
}
.avg-rating-star {
  display: block;
}
.avg-rating-box {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}
.rating-link {
  display: flex;
  width: 30%;
  text-align: right;
  justify-content: flex-end;
}
.rating-link-left {
  margin-right: 40px;
  text-align: left;
}
.filter-date-picker-block {
  width: 100%;
  display: flex;
  align-items: center;
}
.custom-date-picker .filter-date-picker {
  width: 45%;
  overflow: hidden;
  margin-left: 5%;
}
.filter-date-button {
  margin-top: 5px;
}
.rating-cont {
  display: block;
  width: 70%;
  align-self: flex-start;
}
.rating-box {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 30px;
}
.avg-rating-star h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #949496;
  margin-bottom: 0;
}
.avg-rating-star .widget-ratings {
  margin-top: 3px;
}
.rate-cont p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  color: #35a28a;
  letter-spacing: 0.5px;
}
.rating-link a {
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #757575;
  border: 0.2px solid #404144;
  padding: 4px 10px;
  text-decoration: none;
}
.rating-link .dropdown {
  display: inline-block;
  min-width: 140px;
  text-align: left;
}
.avg-rat p {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  color: #9f9fa1;
  letter-spacing: 0.5px;
}
.avg-rat {
  position: absolute;
  display: block;
  width: fit-content;
  top: 50%;
  left: 3%;
  transform: rotateZ(-91deg);
}
.avg-rat.Months {
  position: relative;
  display: block;
  width: 100%;
  transform: rotateZ(0deg);
  text-align: center;
  padding-bottom: 30px;
  margin-top: 10px;
}
.rat-dot {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 4px solid #6bb129;
  margin: 0 auto;
  background: #fff;
}
.rating-dot {
  position: absolute;
  display: none;
  width: fit-content;
  text-align: center;
  top: -45px;
  left: 50%;
}
.progress:hover .rating-dot {
  display: block;
}
.rat-flag p {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin: 0;
  color: #404144;
}
.rat-flag p span {
  font-size: 12px;
  font-weight: 400;
  color: #9f9fa1;
}
.rat-flag {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(105,105,105,0.231);
  border-radius: 4px;
}
.rat-flag:after {
  position: absolute;
  display: block;
  content: '';
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-top: 15px solid #fff;
  left: 40px;
  bottom: -23px;
}
.dashboard-graph-tab h5 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0;
  color: #404144;
}
.overview-box {
  position: relative;
  display: flex;
  width: 85%;
  margin: 20px;
}
.avg-rating-star .star-cont {
  color: #404144;
  margin-left: 10px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}
.rating-overview {
  display: block;
  width: 50%;
  margin-right: 30px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(234,234,234,0.6);
}
.cloud-review {
  display: block;
  width: 50%;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(234,234,234,0.6);
}
.rating-overview .avg-rating-box {
  padding: 0 30px;
  margin-top: 10px;
}
.rating-overview .avg-rating-star {
  width: 50%;
}
.rating-overview .rate-cont p {
  text-align: right;
}
.toprec-feedback.consultant-box {
  background-color: #fff;
}
.toprec-feedback.consultant-box .dashboard-graph {
  box-shadow: none;
  width: calc(100% - 40px);
}
.toprec-feedback.consultant-box .recruiter-box {
  overflow: hidden;
}
.toprec-feedback.consultant-box .consultant-boxes {
  max-height: inherit;
  height: 100%;
  overflow-y: auto;
}
.toprec-feedback.consultant-box .select-recruiter-button {
  width: inherit;
}
.progress-title {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin: 0 0 20px;
}
.progress {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.75rem;
  height: 10px;
  background: #eeeff3;
  border-radius: 12px;
  box-shadow: none;
  margin-bottom: 0px;
  overflow: visible;
  width: 70%;
}
.progress .progress-bar {
  position: relative;
  -webkit-animation: animate-positive 2s;
  animation: animate-positive 2s;
  border-radius: 12px 0 0 12px;
}
.progress .progress-value {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  position: relative;
}
.progressbar {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 30px;
  align-items: center;
}
.progress-value {
  display: block;
  width: 15%;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #9f9fa1;
}
.progressbar-box {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 0 30px 0;
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
.anychart-credits {
  display: none !important;
}
.cloud-box {
  position: relative;
  display: block;
  width: 100%;
  height: 80%;
}
.toprec-box {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 60px;
  text-align: center;
}
.toprec-cont h5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #2e364b;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}
.toprec-cont p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  color: #9f9fa1;
  letter-spacing: 0.2px;
}
.toprec .modal-header {
  padding: 0;
  border: none;
}
.recruiter-header-close {
  display: none;
}
.recruiter-header-close .MuiButton-startIcon {
  margin-right: 0;
}
.toprec {
  background-color: rgba(56,134,209,0.2);
  backdrop-filter: blur(8px);
  width: 86%;
  margin-left: auto;
}
.nav-pills-dropdown {
  display: none;
}
@media screen and (max-width: 1330px) {
  .graph-tab-header {
    width: 30%;
  }
  .graph-tab {
    width: 70%;
  }
  .graph-tab ul li span.nav-link {
    margin: 0 12px;
  }
}
@media screen and (max-width: 1190px) {
  .graph-tab-header {
    width: 35%;
  }
  .graph-tab {
    width: 65%;
  }
}
@media screen and (max-width: 1295px) {
  .avg-rat {
    left: 0;
  }
  .overview-box {
    display: block;
  }
  .rating-overview {
    width: 100%;
  }
  .cloud-review {
    width: 100%;
    margin-top: 20px;
  }
  .graph-tab ul li span.nav-link {
    margin: 0 5px;
  }
}
@media screen and (max-width: 1200px) {
  .recruiter-box {
    display: none;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    padding-top: 10px;
  }
  .graph-tab-header {
    width: 30%;
  }
  .graph-tab ul li span.nav-link {
    margin: 0 8px;
  }
  .recruiter-header-close {
    display: block;
    text-align: right;
    margin-right: 10px;
  }
  .select-recruiter-button {
    display: block;
  }
  .graph-tab {
    width: 62%;
  }
  .consultant-area {
    width: 100%;
  }
  .dashboard-graph {
    width: calc(100% - 48px);
    margin: 24px;
  }
  .overview-box {
    width: calc(100% - 48px);
    margin: 24px;
  }
}
@media screen and (max-width: 602px) {
  .select-recruiter-button {
    width: 52px;
  }
  .graph-tab-header {
    width: calc(100% - 237px);
  }
  .graph-tab {
    width: 185px;
  }
  .graph-tab .nav {
    display: none;
  }
  .graph-tab .nav-pills-dropdown {
    display: flex;
  }
  .avg-rat {
    left: -15px;
  }
}
@media screen and (max-width: 530px) {
  .graph-tab-header {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .select-recruiter-button {
    width: 52px;
  }
  .graph-tab-header {
    width: calc(100% - 237px);
  }
  .graph-tab {
    width: 185px;
  }
  .graph-tab .nav {
    display: none;
  }
  .graph-tab .nav-pills-dropdown {
    display: flex;
  }
  .avg-rat {
    left: -15px;
  }
  .avg-rating-box,
  .avg-rating-star,
  .rate-cont,
  .rating-overview .avg-rating-star {
    display: block;
    width: 100%;
  }
  .rating-overview .rate-cont p {
    text-align: left;
  }
}
@media screen and (max-width: 600px) {
  .graph-tab-header {
    display: none;
  }
  .rating-box,
  .rating-cont,
  .avg-rating-box,
  .avg-rating-star,
  .rate-cont,
  .rating-overview .avg-rating-star,
  .rating-link .dropdown,
  .rating-link {
    display: block;
    width: 100%;
  }
  .avg-rat {
    left: -40px;
    top: 65%;
  }
  .rating-overview .rate-cont p {
    text-align: left;
  }
  .progress-value {
    width: 25%;
  }
  .dashboard-graph-tab h5 {
    font-size: 15px;
  }
  .graph-area {
    min-height: 352px;
  }
  .chart-box {
    margin: 35px auto 0 auto;
  }
  .dashboard-graph-tab {
    padding: 10px 15px;
  }
}
@-moz-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@-o-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.sales {
  width: 100%;
  color: #24253d;
}
.sales .sales__section {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .sales .sales__section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.sales .h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 54px;
}
.sales .h2 {
  font-size: 28px;
  font-weight: 300;
  line-height: 48px;
  margin-bottom: 30px;
}
.sales .section__subtitle {
  color: #24253d;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}
.sales .section__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
.sales__gamification {
  padding-top: 0;
  padding-bottom: 120px;
  background-color: #f9fbfe;
}
@media screen and (max-width: 1024px) {
  .sales__gamification {
    padding-top: 0;
    padding-bottom: 60px;
  }
}
.sales--gamification-header {
  margin-top: 100px;
}
@media screen and (max-width: 1024px) {
  .sales--gamification-header {
    margin-top: 30px;
  }
}
.sales__gamification-image {
  max-width: 100%;
  margin-left: auto;
}
@media screen and (max-width: 1024px) {
  .sales__gamification-section {
    display: flex;
    flex-direction: column-reverse;
  }
}
.sales .section-hero {
  background: #f9fbfe;
  min-height: 90vh;
  position: relative;
  overflow: hidden;
}
.sales .section-hero .h1 {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .sales .section-hero .h1 {
    margin-bottom: 40px;
    padding-top: 15%;
  }
}
.sales .section-hero .section__subtitle {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .sales .section-hero .section__subtitle {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 1024px) {
  .sales .section-hero .section-hero__banner {
    display: none;
  }
}
.sales .section-hero .section-hero__banner img {
  display: block;
  width: 100%;
}
.sales .section-hero .a-left-enter,
.sales .section-hero .a-right-enter {
  will-change: transform;
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.sales .section-hero .a-left-enter {
  transform: translateX(-120%);
}
.sales .section-hero .a-right-enter {
  transform: translateX(120%);
}
.sales .section-hero.a-on-enter .a-right-enter,
.sales .section-hero.a-on-enter .a-left-enter {
  transform: translateX(0%);
}
.sales .section-benefits {
  background-image: url("../../assets/images/hero_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 70vh;
  overflow: hidden;
}
.sales .section-benefits .section__description {
  font-size: 15px;
}
.sales .section-benefits .slick-list {
  overflow: initial;
}
.sales .section-benefits .slick-track {
  padding-bottom: 5px;
}
.sales .section-benefits .section-benefits__card {
  padding: 48px 35px;
  box-shadow: 0 1px 15px 1px rgba(0,0,0,0.05);
  border-radius: 6px;
  background-color: #fff;
  max-width: 370px;
  min-height: 355px;
  margin: 0 10px;
}
@media screen and (min-width: 425px) {
  .sales .section-benefits .section-benefits__card {
    margin: 0 auto;
  }
}
.sales .section-benefits .section-benefits__card h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 10px;
}
.sales .section-benefits .section-benefits__icon {
  background-image: url("../../assets/sprites/icons.svg");
  background-repeat: no-repeat;
  margin-bottom: 30px;
}
.sales .section-benefits .icon-flexibility {
  width: 30px;
  height: 30px;
  background-position: 0 -48px;
}
.sales .section-benefits .icon-reliability {
  width: 28px;
  height: 34px;
  background-position: 0 -78px;
}
.sales .section-benefits .icon-transparency {
  width: 30px;
  height: 30px;
  background-position: 0 -112px;
}
.sales .section-benefits .section-benefits__card {
  opacity: 0;
  transform: translate(0, 50%);
  transition: transform 1s cubic-bezier(0.11, 0.29, 0, 0.97), opacity 0.4s cubic-bezier(0.3, 0.02, 0.44, 0.26);
  will-change: transform, opacity;
}
.sales .section-benefits.a-on-enter .section-benefits__card {
  opacity: 1;
  transform: translate(0, 0%);
}
.sales .section-conversion {
  position: relative;
}
@media screen and (min-width: 768px) {
  .sales .section-conversion {
    padding: 70px 0;
  }
}
.sales .section-conversion .section-conversion__bg {
  display: none;
}
@media screen and (min-width: 768px) {
  .sales .section-conversion .section-conversion__bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/images/sales-page_users-illustration.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    transform: translate(0, 60%);
    transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
}
.sales .section-conversion h2 {
  margin-bottom: 30px;
}
.sales .section-conversion .section-conversion__list {
  list-style-position: inside;
  padding-left: 0px;
}
.sales .section-conversion .section-conversion__list li {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}
.sales .section-conversion .a-bottom-enter {
  transform: translate(0, 30%);
  transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  will-change: transform;
}
@media screen and (min-width: 768px) {
  .sales .section-conversion.a-on-enter .section-conversion__bg {
    transform: translate(0, 0%);
  }
}
.sales .section-conversion.a-on-enter .a-bottom-enter {
  transform: translate(0, 0%);
}
.sales .section-features {
  position: relative;
}
.sales .section-features:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f9fbfe;
  z-index: -1;
  border-radius: 0;
}
@media screen and (min-width: 768px) {
  .sales .section-features:before {
    border-radius: 58% 58% 0 0;
  }
}
.sales .section-features h2 {
  margin-bottom: 40px;
}
.sales .section-features .section-features__icon {
  background-image: url("../../assets/sprites/icons.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 48px;
  height: 48px;
  margin: 0 auto 65px;
}
.sales .section-features .a-bottom-enter,
.sales .section-features .a-scale-in {
  opacity: 0;
}
.sales .section-features .a-scale-in {
  transform: scale(0);
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform, opacity;
}
.sales .section-features .a-bottom-enter {
  transform: translate(0, 100px);
  transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform, opacity;
}
.sales .section-features.a-on-enter .a-bottom-enter,
.sales .section-features.a-on-enter .a-scale-in {
  opacity: 1;
}
.sales .section-features.a-on-enter .a-scale-in {
  transform: scale(1);
}
.sales .section-features.a-on-enter .a-bottom-enter {
  transform: translate(0, 0%);
}
.sales .section-widgets {
  background-color: #f9fbfe;
}
.sales .section-widgets .container:first-child {
  margin-bottom: 60px;
}
.sales .section-widgets .section-widgets__previews {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto 130px;
}
.sales .section-widgets .section-widgets__previews img {
  display: block;
}
.sales .section-widgets .section-widgets__previews .widget-desktop-preview {
  max-width: 90%;
}
.sales .section-widgets .section-widgets__previews .widget-tablet-preview,
.sales .section-widgets .section-widgets__previews .widget-mobile-preview {
  position: absolute;
}
.sales .section-widgets .section-widgets__previews .widget-tablet-preview {
  max-width: 43%;
  right: 0;
  bottom: -30px;
}
.sales .section-widgets .section-widgets__previews .widget-mobile-preview {
  max-width: 26%;
  right: 25%;
  bottom: -30%;
}
.sales .section-widgets .widgets-slider {
  padding: 40px 0 0;
}
.sales .section-widgets .widgets-slider .widgets-slider__preview {
  display: block;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
@media screen and (min-width: 1024px) {
  .sales .section-widgets .widgets-slider .widgets-slider__item {
    max-width: 400px;
  }
}
@media screen and (min-width: 1024px) {
  .sales .section-widgets .widgets-slider .slick-list {
    margin-bottom: 50px;
  }
}
.sales .section-widgets .widgets-slider .slick-track {
  padding: 10px 0px;
}
@media screen and (min-width: 1024px) {
  .sales .section-widgets .widgets-slider .slick-track {
    padding: 30px 0px;
  }
}
.sales .section-widgets .widgets-slider .slick-slide img {
  margin: 0 auto;
  max-width: 80%;
}
@media screen and (min-width: 1024px) {
  .sales .section-widgets .widgets-slider .slick-slide img {
    max-width: initial;
    position: relative;
    transform: scale(1);
    transition: transform 0.1s ease;
  }
}
@media screen and (min-width: 1024px) {
  .sales .section-widgets .widgets-slider .slick-slide.slick-active img {
    transition: transform 0.6s ease 0.5s;
    transform: scale(1.3);
    z-index: 2;
  }
}
.sales .section-widgets .a-bottom-enter {
  opacity: 0;
  transform: translate(0, 50px);
  transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform, opacity;
}
.sales .section-widgets.a-on-enter .a-bottom-enter {
  opacity: 1;
  transform: translate(0, 0%);
}
.sales .section-socialization .section-socialization__previews {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .sales .section-socialization .section-socialization__previews {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .sales .section-socialization .section-socialization__previews {
    max-width: 100%;
  }
}
.sales .section-socialization .section-socialization__previews img {
  display: block;
}
.sales .section-socialization .section-socialization__previews .socialize-preview-bg {
  max-width: 51%;
  position: relative;
  transform: translateX(60%);
}
.sales .section-socialization .section-socialization__previews .socialize-preview-1,
.sales .section-socialization .section-socialization__previews .socialize-preview-2 {
  max-width: 55%;
  position: absolute;
}
.sales .section-socialization .section-socialization__previews .socialize-preview-1 {
  top: 0;
  left: 0;
}
.sales .section-socialization .section-socialization__previews .socialize-preview-2 {
  right: 5%;
  bottom: 5%;
}
.sales .section-socialization .a-bottom-enter {
  opacity: 0;
  transform: translate(0, 50px);
  transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform, opacity;
}
.sales .section-socialization.a-on-enter .a-bottom-enter {
  opacity: 1;
  transform: translate(0, 0%);
}
.sales .section-positioning {
  background-color: #f9fbfe;
}
.sales .section-positioning .section-positioning__previews {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding-top: 80%;
}
@media screen and (max-width: 1024px) {
  .sales .section-positioning .section-positioning__previews {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .sales .section-positioning .section-positioning__previews {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .sales .section-positioning .section-positioning__previews {
    padding-top: 50%;
  }
}
.sales .section-positioning .section-positioning__previews img {
  display: block;
}
.sales .section-positioning .section-positioning__previews .socialize-preview-bg {
  position: relative;
  transform: translateX(60%);
}
.sales .section-positioning .section-positioning__previews .profile-preview,
.sales .section-positioning .section-positioning__previews .meet-the-team-preview {
  position: absolute;
  max-width: 80%;
}
.sales .section-positioning .section-positioning__previews .profile-preview {
  top: 0;
  left: 0;
}
.sales .section-positioning .section-positioning__previews .meet-the-team-preview {
  right: 0;
  bottom: 0;
}
.sales .section-positioning .a-bottom-enter {
  opacity: 0;
  transform: translate(0, 50px);
  transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform, opacity;
}
.sales .section-positioning.a-on-enter .a-bottom-enter {
  opacity: 1;
  transform: translate(0, 0%);
}
.sales .section-alerts .section-alerts__previews {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20%;
}
@media screen and (max-width: 1024px) {
  .sales .section-alerts .section-alerts__previews {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .sales .section-alerts .section-alerts__previews {
    max-width: 100%;
  }
}
.sales .section-alerts .section-alerts__previews img {
  display: block;
}
.sales .section-alerts .section-alerts__previews .alerts-preview-bg {
  max-width: 60%;
  position: relative;
  transform: translateX(40%);
}
.sales .section-alerts .section-alerts__previews .alerts-preview-1,
.sales .section-alerts .section-alerts__previews .alerts-preview-2 {
  position: absolute;
}
.sales .section-alerts .section-alerts__previews .alerts-preview-1 {
  max-width: 55%;
  top: 0;
  left: 0;
}
.sales .section-alerts .section-alerts__previews .alerts-preview-2 {
  max-width: 45%;
  right: 10%;
  bottom: 5%;
}
.sales .section-alerts .a-bottom-enter {
  opacity: 0;
  transform: translate(0, 50px);
  transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: transform, opacity;
}
.sales .section-alerts.a-on-enter .a-bottom-enter {
  opacity: 1;
  transform: translate(0, 0%);
}
.sales .section-request {
  color: #fff;
  background-color: #0074bc;
  padding: 90px 0;
}
.sales .section-request form > div {
  margin-bottom: 30px;
}
.sales .section-request.a-bottom-enter {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  will-change: opacity;
}
.sales .section-request.a-on-enter.a-bottom-enter {
  opacity: 1;
}
.settings {
  width: 100%;
}
.settings .settings-container {
  max-width: 780px;
  margin: 25px auto;
}
.settings .settings-item {
  margin: 0 10px 25px 10px;
  padding: 20px 30px 50px 30px;
}
.settings .settings-item:last-child {
  margin-bottom: 0;
}
.settings .settings-item h3 {
  margin-bottom: 15px;
}
.settings .settings-item .settings-field {
  margin-bottom: 5px;
}
.settings .settings-item .settings-field .MuiFormControl-root.MuiFormControl-fullWidth {
  margin-bottom: 0px;
  margin-top: 0px;
}
.settings .settings-item .settings-label {
  padding-left: 0 !important;
  margin-top: 15px;
}
.settings .settings-item .additional-email-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
}
.settings .settings-item .additional-email-section__info {
  flex-grow: 0;
}
.settings .settings-item .additional-email-section .settings-field {
  flex-grow: 1;
  margin-top: 12px;
  margin-bottom: 0;
}
.settings .settings-item .additional-email-section--img {
  min-height: 300px;
}
.settings .settings-item .additional-email-section__icon {
  margin-left: 5px;
  margin-top: 10px;
}
.settings .settings-item .profile-testimonials-section--header {
  margin-bottom: 24px;
  line-height: 24px;
}
.settings .settings-item .profile-testimonials-section__author-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.settings .settings-item .profile-testimonials-section--author-info {
  width: 47%;
}
.settings .settings-item .profile-testimonials-section--verified {
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #27ae60;
}
.settings .settings-item .profile-testimonials-section--verified a {
  color: #27ae60;
}
.settings .settings-item .profile-testimonials-section--author-info-date {
  width: 47% !important;
  min-width: 47% !important;
}
.settings .settings-item .profile-testimonials-section--author-info-date div {
  width: 100% !important;
  min-width: 100% !important;
}
.settings .settings-item .profile-testimonials-section__container {
  display: flex;
  max-width: 700px;
  width: 100%;
  overflow-y: auto;
  padding-top: 18px;
}
.settings .settings-item .profile-testimonials-section__container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.settings .settings-item .profile-testimonials-section__container::-webkit-scrollbar {
  width: 12px;
  height: 6px;
  background-color: #f5f5f5;
}
.settings .settings-item .profile-testimonials-section__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #797979;
}
.settings .settings-item .profile-testimonials-section__item-section {
  min-width: 275px;
  width: 275px;
  min-height: 320px;
  margin-right: 14px;
}
.settings .settings-item .profile-testimonials-section__item {
  position: relative;
  height: 340px;
  padding: 24px;
  background: #f9fbfe;
}
.settings .settings-item .profile-testimonials-section__item .profile-testimonials-section--verified-show {
  display: inline-block;
}
.settings .settings-item .profile-testimonials-section__item .profile-testimonials-section--verified-hide {
  display: none;
}
.settings .settings-item .profile-testimonials-section__item:hover .profile-testimonials-section--verified-show {
  display: none;
}
.settings .settings-item .profile-testimonials-section__item:hover .profile-testimonials-section--verified-hide {
  display: inline-block;
}
.settings .settings-item .profile-testimonials-section--edit {
  margin-top: 8px;
  text-align: center;
  color: #3886d1;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
}
.settings .settings-item .profile-testimonials-section--item-content {
  height: 196px;
  margin-bottom: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}
.settings .settings-item .profile-testimonials-section--item-content-small {
  height: 156px;
}
.settings .settings-item .profile-testimonials-section--item-name {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #757575;
}
.settings .settings-item .profile-testimonials-section--item-occupation {
  margin: 5px 0;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}
.settings .settings-item .profile-testimonials-section--item-date {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #9e9e9e;
}
.settings .field-group {
  margin-bottom: 30px;
}
.settings .field-group.field-group-hidden {
  display: none;
}
.settings .field-group:last-child {
  margin-bottom: 0;
}
.agency-default-fonts {
  width: 100%;
}
.reward-section--header {
  margin-bottom: 14px;
}
.reward-section--toggle {
  margin-top: 14px;
}
.reward-section--input-field {
  width: 100% !important;
}
.google-key-settings--toggle {
  margin-top: 14px;
}
.google-key-settings__button-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.google-key-settings--remove {
  margin-left: 10px !important;
  background-color: #fff !important;
}
.m-bot-1x {
  margin-bottom: 14px;
}
.cropper-img-section__wrap {
  display: flex;
}
.cropper-img-section__wrap-vertical-layout {
  flex-direction: column;
}
.cropper-img-section__button-wrap {
  display: flex;
  align-content: center;
  flex-direction: column;
  padding-left: 24px;
}
.cropper-img-section__button-wrap-vertical-layout {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.cropper-img-section__button-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.cropper-img-section__button-section .warning-message {
  width: 100%;
  color: #ffa500;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cropper-img-section__button-section .warning-message svg {
  color: #ffa500;
  margin-right: 6px;
  width: 20px;
}
.cropper-img-section__save-button {
  display: flex;
}
.cropper-img-section__save-button .submit-btn {
  width: 170px;
}
.cropper-img-section__button-section {
  margin-top: 25px;
}
.reactEasyCrop_Container {
  border: 1px solid #808080;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}
@media screen and (max-width: 991px) {
  .settings .settings-item .additional-email-section {
    align-items: flex-start;
  }
}
@media screen and (max-width: 768px) {
  .settings .settings-item .profile-testimonials-section--author-info {
    width: 100%;
  }
  .settings .settings-item .profile-testimonials-section--author-info-date {
    width: 100% !important;
    min-width: 100% !important;
  }
}
@media screen and (max-width: 560px) {
  .profile-form__tabs button {
    font-size: 12px !important;
  }
  .cropper-img-section__wrap {
    flex-direction: column;
  }
  .cropper-img-section__img-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .cropper-img-section__button-wrap {
    padding-left: 0;
  }
  .cropper-img-section__save-button .submit-btn {
    width: 100%;
  }
}
.additional-email-section__img-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.additional-email-section--img {
  height: 600px;
}
@media screen and (max-width: 991px) {
  .additional-email-section__img-section {
    display: block;
    margin-top: -24px;
    margin-left: -24px;
    margin-right: -24px;
  }
}
.testimonials-section {
  margin-bottom: 0;
  margin-top: 36px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9e9e9e;
}
.profile-testimonials-section--quotes {
  position: absolute;
  top: -17px;
  left: -5px;
}
.margin-auto {
  margin: auto;
}
.padding-2 {
  padding: 2rem !important;
}
.margin-left-1 {
  margin-left: 1rem !important;
}
.subscription-remove {
  width: 100%;
  min-height: 470px;
}
.subscription-remove h1 {
  margin-bottom: 35px;
}
@media screen and (max-width: 1023px) {
  .subscription-remove h1 {
    font-size: 36px;
  }
}
.testimonials-feedback {
  max-width: 557px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
}
.testimonials-feedback--field-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.testimonials-feedback--field-section-full {
  width: 100%;
}
.testimonials-feedback--field-section-left {
  width: 48%;
}
@media screen and (max-width: 400px) {
  .testimonials-feedback--field-section-left {
    width: 100%;
  }
}
.testimonials-feedback--field-section-right {
  width: 48%;
}
@media screen and (max-width: 400px) {
  .testimonials-feedback--field-section-right {
    width: 100%;
  }
}
.testimonials-feedback--field-section-right-select {
  width: 48%;
  margin-bottom: 2px;
  text-align: left;
}
.testimonials-feedback--field-section-right-select .MuiInputLabel-shrink {
  white-space: nowrap;
}
@media screen and (max-width: 400px) {
  .testimonials-feedback--field-section-right-select {
    width: 100%;
    margin-top: 15px;
  }
}
@media screen and (max-width: 500px) {
  .testimonials-feedback--field-section-right-select .MuiInputLabel-shrink {
    white-space: initial;
  }
}
.testimonials-feedback__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  padding-bottom: 36px;
}
.testimonials-feedback--header-content {
  margin-bottom: 0;
  text-align: center;
  color: #3886d1;
  font-size: 24px;
}
.testimonials-feedback--content-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #9e9e9e;
}
.testimonials-feedback__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
  padding: 36px 45px 60px;
  background: #eef7ff;
  text-align: center;
}
.testimonials-feedback__review {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 36px 35px;
  text-align: center;
}
.testimonials-feedback--comments-description {
  margin-top: 24px;
  margin-bottom: 0;
  color: #9e9e9e;
  line-height: 24px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.testimonials-feedback--review-input {
  width: 70% !important;
  margin-right: 14px;
  text-align: left;
}
.testimonials-feedback--social {
  min-width: 126px !important;
  margin-left: 14px;
  margin-right: 14px;
  word-break: break-all;
}
.testimonials-feedback--content {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3886d1;
}
.testimonials-feedback--content span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonials-feedback--content-lamp {
  margin-bottom: 17px;
}
.testimonials-feedback--content-small {
  width: 290px;
}
.testimonials-feedback--content-text-add {
  max-width: 347px;
}
.testimonials-feedback--stars {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}
.testimonials-feedback--user-name {
  margin-top: 14px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}
.testimonials-feedback--content-header {
  padding-top: 20px;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #3886d1;
}
.testimonials-feedback__thank-modal {
  max-width: 557px;
  width: 100%;
  height: 324px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonials-feedback_approve__thank-modal {
  max-width: 757px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonials-feedback_approve__thank-modal .feedback-page-comment {
  padding: 0 !important;
  width: 100%;
}
.testimonials-feedback__button {
  padding-top: 30px !important;
}
.testimonials-feedback__share-modal {
  padding-top: 40px !important;
}
.testimonials-feedback__share-modal .testimonials-feedback--content-text-add {
  max-width: 370px;
  font-size: 15px;
}
.testimonials-feedback__share-modal .testimonials-feedback--user-name {
  font-size: 1rem;
}
.testimonials-feedback__share-modal .testimonials-feedback__stars {
  margin-top: 6px;
}
.testimonials-feedback__share-modal .testimonials-feedback--stars {
  width: 20px;
  height: 20px;
}
.testimonials-feedback__share-modal .testimonials-feedback__share-review {
  padding: 0px 30px;
  text-align: center;
  margin-bottom: 20px;
}
.testimonials-feedback__share-modal .testimonials-feedback__share-review .title {
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: left;
  color: #9e9e9e;
}
.testimonials-feedback__share-modal .testimonials-feedback__share-review .testimonials-feedback--review-input-block {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 8px;
  padding: 0 20px;
  height: 140px !important;
  position: relative !important;
}
.testimonials-feedback__share-modal .testimonials-feedback__share-review .testimonials-feedback--review-input-block .testimonials-feedback--review-input {
  width: 100% !important;
  margin-right: 0;
  text-align: left;
}
.testimonials-feedback__share-modal .testimonials-feedback__share-review .testimonials-feedback--review-input-block .testimonials-feedback--review-input hr {
  display: none !important;
}
.testimonials-feedback__share-modal .testimonials-feedback__share-review .testimonials-feedback--review-input-block .testimonials-feedback--review-button {
  position: absolute !important;
  right: 10px;
  bottom: 10px;
}
.testimonials-feedback__share-modal .testimonials-feedback__share-review .testimonials-feedback--review-input-block .testimonials-feedback--review-button svg {
  height: 20px !important;
  width: 20px !important;
}
.testimonials-feedback__share-modal .testimonials-feedback__add-modal-submit {
  padding: 0px 30px;
  text-align: center;
}
.testimonials-feedback__share-modal .testimonials-feedback__add-modal-submit .social-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.testimonials-feedback__share-modal .testimonials-feedback__add-modal-submit .social-title span {
  margin: 0 15px;
  font-weight: normal;
  font-size: 15px;
  color: #9e9e9e;
}
.testimonials-feedback__share-modal .testimonials-feedback__add-modal-submit .social-title:before,
.testimonials-feedback__share-modal .testimonials-feedback__add-modal-submit .social-title:after {
  background: #ccc;
  height: 2px;
  flex: 1;
  content: '';
}
.testimonials-feedback__share-modal .testimonials-feedback__add-modal-submit .testimonials-feedback--social {
  margin-left: 0;
  margin-right: 0;
  word-break: break-all;
  width: 100%;
  margin-top: 0;
  margin-bottom: 12px;
}
.testimonials-feedback__add-modal {
  max-width: 557px;
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonials-feedback__add-modal .testimonials-feedback--user-name {
  margin-bottom: 5px;
}
.testimonials-feedback__add-modal .testimonials-feedback__content {
  min-height: unset;
  width: 100%;
  padding: 36px 45px;
}
.testimonials-feedback__add-modal-submit {
  flex-wrap: wrap;
  padding: 14px 36px 0;
}
.testimonials-feedback__stars {
  display: flex;
  justify-content: center;
  margin-top: 14px;
}
@media screen and (max-width: 768px) {
  .testimonials-feedback {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .testimonials-feedback__content {
    padding: 30px 24px 40px;
  }
  .testimonials-feedback__review {
    padding: 24px 16px;
  }
  .testimonials-feedback__add-modal-submit {
    padding: 14px 15px 0;
  }
}
.align-left {
  text-align: left;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.testimonials-feedback__add-modal-submit form .MuiOutlinedInput-multiline {
  padding: 0 8px;
}
.welcome {
  width: 100%;
  min-height: 570px;
  transition: background-color 0.3s ease-in-out;
}
.welcome .welcome-successful-img {
  margin-right: 15px;
}
.welcome .welcome-block {
  display: flex;
  flex-direction: column;
}
.welcome .welcome-block h1 {
  width: 100%;
  margin-bottom: 35px;
  opacity: 0;
  transform: translate(0, 30px);
  transition: all 0.2s ease-in-out 0.2s;
}
.welcome .welcome-block h1.show {
  transform: translate(0, 0px);
  opacity: 1;
}
.welcome .welcome-block h3 {
  font-weight: 300;
  margin-bottom: 35px;
}
.welcome .welcome-block a {
  display: inline-block;
}
.welcome .welcome-block h1,
.welcome .welcome-block h3,
.welcome .welcome-block a {
  position: relative;
}
.welcome .welcome-background {
  transition: background-color 0.3s ease-in-out;
}
.welcome .welcome-background > div {
  transition: background-color 0.3s ease-in-out;
}
@media screen and (max-width: 860px) {
  .welcome__buttons {
    flex-direction: column;
  }
  .welcome__buttons > div {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1023px) {
  .welcome-block h1 {
    font-size: 36px;
  }
  .welcome-block h3 {
    font-size: 16px;
    line-height: 24px;
  }
}
.widgets {
  width: 100%;
  position: relative;
}
.widgets__container {
  padding-top: 25px;
  padding-bottom: 25px;
}
.widgets-wrapper {
  padding: 20px 30px 50px 30px;
}
.widgets-wrapper-title-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.widgets__snippet-preview {
  margin: 0 -30px 20px;
}
.widgets__action-wrapper {
  margin-bottom: 20px;
}
.widgets__image-preview {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.widgets__image-preview-register {
  display: block;
  width: 300px;
  margin: 0 auto 14px;
}
.widgets h3 {
  margin-bottom: 20px;
}
.widgets h4 {
  margin-bottom: 15px;
}
.floating-link.your-rating-widget .ui-placeholder {
  margin-left: 40px;
}
.floating-link .widget__live-preview {
  text-align: center;
  padding: 1em 1em 0;
  border: 1px solid rgba(0,0,0,0.12);
  margin-bottom: 1em;
  position: relative;
}
.floating-link .widget__live-preview .ref-floating-link {
  position: absolute;
}
.floating-link .widget__live-preview .ref-average-rating {
  position: absolute;
  bottom: 90%;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  -webkit-box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  -moz-box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
}
.floating-link .widget__live-preview .ref-average-rating > span {
  text-decoration: none;
}
.floating-link .widget__live-preview .ref-average-rating > span .ref-average-rating-box {
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.floating-link .widget__live-preview .ref-average-rating > span .ref-average-rating-box span.ref-widget-text {
  transform: rotate(180deg);
  font-size: 14px;
}
.floating-link .widget__live-preview .ref-average-rating > span .ref-average-rating-box span.ref-widget-stars {
  transform: rotate(180deg);
}
.floating-link .widget__live-preview .ref-average-rating > span .ref-average-rating-box span.ref-widget-stars svg {
  margin: 4px 0px !important;
}
.xml-feeds-section__title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
  flex-direction: column;
}
.xml-feeds-section--title {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #757575;
}
.xml-feeds-section--content {
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9e9e9e;
}
.xml-feeds-section__item {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 720px;
  height: 48px;
  border-bottom: 1px solid #eceff1;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}
.xml-feeds-section__item:last-child {
  border-bottom: none;
}
.xml-feeds-section--item-name {
  width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.xml-feeds-section--item-link {
  width: 60%;
}
.xml-feeds-section--item-action {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.xml-feeds-section__add-form {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.xml-feeds-section--add-xml-input {
  width: 100%;
  max-width: 190px;
  margin-right: 24px;
}
.xml-feeds-section__content {
  overflow-y: auto;
  width: 100%;
}
.xml-feeds-section__button-wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 3px;
}
.refari-copied-wrap {
  position: relative;
}
.refari-copied-wrap .refari-copied-msg {
  position: absolute;
  right: -50px;
  top: 12px;
}
/*# sourceMappingURL=src/styles/index.css.map */